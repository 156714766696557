import { Anchor, Card, Image, Text, Flex, Container } from "@mantine/core";

export function MediumCard({
  url,
  coverImageUrl,
  favicon,
  title,
  description,
  artistName,
}) {
  return (
    <div className="card-xl">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <Card.Section>
          <Anchor target="_blank" href={url}>
            <Image
              // width={80}
              height={240}
              radius="md"
              src={coverImageUrl}
              alt={title}
              withPlaceholder
              imageProps={{
                loading: "lazy",
              }}
            />
          </Anchor>
        </Card.Section>
        <Container p={4}>
          <Anchor
            color="dark"
            href={url}
            target="_blank"
            weight={500}
            size="lg"
            mt={6}
            lineClamp={1}
          >
            {title}
          </Anchor>
          {description && (
            <Text
              color="dimmed"
              size="sm"
              lineClamp={2}
              maw={450}
              mb="xs"
              mih="43.375px"
            >
              {description}
            </Text>
          )}
          <Flex align="center">
            {favicon && (
              <Image
                src={favicon}
                width={14}
                height={14}
                alt={title}
                withPlaceholder
                pr={4}
                mt={2}
                mr={6}
                imageProps={{
                  loading: "lazy",
                }}
              />
            )}
            {artistName ? (
              <Text color="dimmed" size="xs" lineClamp={1} maw={350}>
                {artistName}
              </Text>
            ) : (
              <Anchor
                // color="dimmed"
                href={url}
                target="_blank"
                lineClamp={1}
                maw={350}
                size="xs"
              >
                {url}
              </Anchor>
            )}
          </Flex>
        </Container>
      </Card>
    </div>
  );
}
