import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "../components/Strip";
import { MovieCard } from "../components/MovieCard";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";

export function MoviesStrip() {
  const STRIP = "Movies";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="movies"
        nav="scroll"
        gradientValues={["#F5C518"]}
        title="Movies"
        icon={{ type: "emoji", icon: "📺" }}
        iconColor="white"
      >
        {linksDataByStrip.map((item, index) => {
          return (
            <MovieCard
              key={index}
              link={item.link}
              image={item.image}
              title={item.title}
              rating={item.rating}
            />
          );
        })}
      </Strip>
    );
  }
}
