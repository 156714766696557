import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "../components/Strip";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";
import { NewsletterEmbed } from "./NewsletterEmbed";

export function NewsletterStrip() {
  const STRIP = "Newsletter";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <>
        <Strip
          id="newsletter"
          nav="no-scroll"
          gradientValues={["#119FF3", "#1074DB"]}
          title="Newsletter"
          icon={{ type: "emoji", icon: "📧" }}
          iconColor="white"
        >
          {linksDataByStrip?.map((item, index) => {
            return <NewsletterEmbed key={index} url={item.url} slim={false} />;
          })}
        </Strip>
      </>
    );
  }
}
