import "../App.css";
import { Image, ScrollArea } from "@mantine/core";
import { StripDouble } from "../components/StripDouble";
import { useLinksQuery } from "../queries/queries";
import { LinkedInCard } from "../components/LinkedInCard";
import { LinkedInAddConnectionButton } from "../components/CTAButtons";
import { LinkedInEmbed } from "react-social-media-embed";
import { LinkedInCardSkeleton } from "./LinkedInCardSkeleton";
import { ErrorBoundary } from "@sentry/react";

export function LinkedInStrip() {
  const STRIP = "LinkedIn";

  const {
    data: linksData,
    isLoading: linksIsLoading,
    // isError: linksIsError,
  } = useLinksQuery();

  const linksDataFilteredByStrip = linksData?.filter(
    (item) => item.strip === STRIP
  );

  const experienceItems = linksDataFilteredByStrip?.filter(
    (item) => item.experienceType === "Experience"
  );
  const educationItems = linksDataFilteredByStrip?.filter(
    (item) => item.experienceType === "Education"
  );
  const postItems = linksDataFilteredByStrip?.filter(
    (item) => item.experienceType === "Posts"
  );

  const profileUrl = linksDataFilteredByStrip?.find(
    (link) => link.isProfileUrl
  )?.url;

  const convertToEmbeddableURL = (url) => {
    // Check if the URL is already in the embeddable format
    if (url.includes("linkedin.com/embed")) {
      return url;
    }

    // Extract the unique identifier from the URL for activity
    const match = url.match(/activity[-:](\d+)/);
    const id = match && match[1];

    // Construct the embed URL using the extracted ID
    return id
      ? `https://www.linkedin.com/embed/feed/update/urn:li:activity:${id}`
      : null;
  };

  return (
    <StripDouble
      id="linkedin"
      nav="scroll"
      gradientValues={["#F5F2ED"]}
      followButton={<LinkedInAddConnectionButton link={profileUrl} />}
      // titleColor="#1A1A1A"
      titleColor="#494949"
      title={experienceItems?.length > 0 && "Experience"}
      title2={educationItems?.length > 0 && "Education"}
      title3={postItems?.length > 0 && "Posts"}
      // icon={{ type: "fa", icon: faLinkedin }}
      icon={{
        type: "image",
        icon: <Image src="/LinkedIn.png" height={31} width={31} />,
      }}
      iconLink={profileUrl}
      iconColor="#0A66C2"
    >
      {linksIsLoading ? (
        <div type="Experience">
          <ScrollArea type="auto" offsetScrollbars>
            <div className="card-container-scroll">
              <LinkedInCardSkeleton />
              <LinkedInCardSkeleton />
              <LinkedInCardSkeleton />
              <LinkedInCardSkeleton />
            </div>
          </ScrollArea>
        </div>
      ) : (
        experienceItems?.map((item, index) => {
          return (
            <LinkedInCard
              key={`${index}${item.title}`}
              type="Experience"
              image={item.image}
              coverImageUrl={item.coverImageUrl}
              company={item.company}
              title={item.jobTitle}
              location={item.location}
              description={item.jobDescription}
              link={item.link}
              url={item.url}
              startMonth={item.startMonth}
              startYear={item.startYear}
              endMonth={item.endMonth}
              endYear={item.endYear}
            />
          );
        })
      )}
      {educationItems?.map((item, index) => {
        const fieldOfStudy = item?.fieldOfStudy ? `, ${item.fieldOfStudy}` : "";
        const degreeAndMajor = item?.degree ? item.degree + fieldOfStudy : "";

        return (
          <LinkedInCard
            key={index}
            type="Education"
            image={item.image}
            coverImageUrl={item.coverImageUrl}
            company={degreeAndMajor} //The degree goes where the Company is
            title={item.school} //The school name goes where the Title is
            location={item.location}
            description={item.jobDescription}
            link={item.link}
            url={item.url}
            startMonth={item.startMonth}
            startYear={item.startYear}
            endMonth={item.endMonth}
            endYear={item.endYear}
          />
        );
      })}
      {postItems?.map((item, index) => {
        const finalUrl = convertToEmbeddableURL(item.url);

        return (
          <ErrorBoundary key={item.id} type="Posts">
            <LinkedInEmbed
              key={index}
              url={finalUrl}
              type="Posts"
              width={340}
            />
          </ErrorBoundary>
        );
      })}
    </StripDouble>
  );
}
