export const ANNUAL_DISCOUNT = 0.25;

export const priceMap = {
  Starter: {
    monthly: "price_1Nw6CtLwhPIA6qukDdfeTBWn",
    annual: "price_1OHZawLwhPIA6qukaUdgHeVm",
    monthlyValue: 500,
    annualValue: 500 * 12 * (1 - ANNUAL_DISCOUNT),
  },
  Premium: {
    monthly: "price_1OGpamLwhPIA6qukquLVN17R",
    annual: "price_1OHZbrLwhPIA6qukad16XesB",
    monthlyValue: 800,
    annualValue: 800 * 12 * (1 - ANNUAL_DISCOUNT),
  },
  // Add more products as needed
};

// Function to determine if a given price ID is for a premium plan
export const isPremium = (stripePriceId) => {
  return (
    stripePriceId === priceMap.Premium.monthly ||
    stripePriceId === priceMap.Premium.annual
  );
};

// Function to determine if a given price ID is for a starter plan
export const isStarter = (stripePriceId) => {
  return (
    stripePriceId === priceMap.Starter.monthly ||
    stripePriceId === priceMap.Starter.annual
  );
};

// Function to determine if a given plan is billed annually
export const isAnnual = (stripePriceId) => {
  return (
    stripePriceId === priceMap.Starter.annual ||
    stripePriceId === priceMap.Premium.annual
  );
};
