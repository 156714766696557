import { Flex, Text, UnstyledButton } from "@mantine/core";
import { useProfileQuery } from "../queries/queries";
import { useParams } from "react-router-dom";

export function FooterLogo() {
  const { username } = useParams();
  const { data: profileData } = useProfileQuery(username);
  const destinationUsername = profileData?.username;

  return (
    <Flex mih={60} align="center" justify="center" pb={20} mt={10}>
      <UnstyledButton
        component="a"
        href={`https://tapistr.ee?utm_source=tapistree&utm_medium=profile&utm_content=${destinationUsername}`}
      >
        <Text size="lg">
          Built with{" "}
          <span className="gradient-text">
            <b>Tapistree</b> 🎨
          </span>
        </Text>
      </UnstyledButton>
    </Flex>
  );
}
