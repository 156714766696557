import { Text } from "@mantine/core";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";
import { BeehiivEmbed } from "./BeehiivEmbed";
import { ErrorBoundary } from "@sentry/react";

export function BeehiivStrip() {
  const STRIP = "Beehiiv";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return linksDataByStrip?.map((link) => (
      <ErrorBoundary key={link.id}>
        <BeehiivEmbed url={link.url} />
      </ErrorBoundary>
    ));
  }
}

export function BeehiivStrip2() {
  const STRIP = "Beehiiv2";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return linksDataByStrip?.map((link) => (
      <ErrorBoundary key={link.id}>
        <BeehiivEmbed url={link.url} />
      </ErrorBoundary>
    ));
  }
}
