import { Strip } from "../Strip";
import { faGoodreads } from "@fortawesome/free-brands-svg-icons";
import { GoodreadsCard } from "../GoodreadsCard";

export function DummyBooksStrip() {
  const booksArray = [
    {
      id: "3a23377d-cb35-499e-a420-8432c838d2d9",
      createdAt: "2023-11-19T17:56:55.036Z",
      updatedAt: "2023-11-19T17:56:55.036Z",
      url: "https://openlibrary.org/works/OL21911019W",
      urlPosition: 22,
      isProfileUrl: null,
      strip: "Books",
      linkType: null,
      userId: "20d7b7ae-e5b9-4ac3-b577-c072223e9e71",
      artistName: null,
      albumName: null,
      coverImageUrl: "https://covers.openlibrary.org/b/id/10512439-M.jpg",
      imageFit: null,
      favicon: null,
      description: null,
      title: "Greenlights",
      ISBN: null,
      episodeTitle: null,
      authorName: "Matthew McConaughey",
      ratingsAverage: null,
      ratingsCount: null,
      userRating: 5,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: null,
      jobDescription: null,
    },
    {
      id: "23bdc909-e210-4557-a743-ae4f8fe820da",
      url: "https://openlibrary.org/works/OL1317211W",
      urlPosition: 1,
      coverImageUrl: "https://covers.openlibrary.org/b/id/211529-L.jpg",
      title: "Meditations",
      authorName: "Marcus Aurelius",
      userRating: 5,
    },
    {
      id: "e2871e62-e5fb-4e6e-aa11-09b7211f8b9a",
      url: "https://openlibrary.org/works/OL5727686W",
      urlPosition: 2,
      coverImageUrl: "https://covers.openlibrary.org/b/id/551262-L.jpg",
      title: "The Power of Now",
      authorName: "Eckhart Tolle",
      userRating: 5,
    },
    {
      id: "3ee27237-cecc-4ac3-89b1-3263b79309b7",
      createdAt: "2023-10-01T21:50:21.000Z",
      updatedAt: "2023-12-31T21:03:34.056Z",
      url: "https://openlibrary.org/works/OL81626W",
      urlPosition: 16,
      isProfileUrl: false,
      strip: "Books",
      linkType: null,
      userId: "54ac9943-b132-42eb-8054-19ac3e8b81b4",
      artistName: null,
      albumName: null,
      coverImageUrl: "https://covers.openlibrary.org/b/id/8576476-L.jpg",
      imageFit: null,
      favicon: null,
      description: null,
      title: "Carrie",
      ISBN: null,
      episodeTitle: null,
      authorName: "Stephen King",
      ratingsAverage: null,
      ratingsCount: null,
      userRating: 4.5,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: false,
      jobDescription: null,
      youTubeChannelId: null,
      substackStripColor: null,
      substackTitleColor: null,
      buttonColor: null,
      buttonText: null,
    },
    {
      id: "c6b37739-af4b-4125-a56e-146ffa0ecd5c",
      createdAt: "2023-09-30T17:53:40.000Z",
      updatedAt: "2023-12-31T21:03:34.056Z",
      url: "https://openlibrary.org/works/OL21745884W",
      urlPosition: 32,
      isProfileUrl: false,
      strip: "Books",
      linkType: null,
      userId: "54ac9943-b132-42eb-8054-19ac3e8b81b4",
      artistName: null,
      albumName: null,
      coverImageUrl: "https://covers.openlibrary.org/b/id/11200092-L.jpg",
      imageFit: null,
      favicon: null,
      description: null,
      title: "Project Hail Mary",
      ISBN: null,
      episodeTitle: null,
      authorName: "Andy Weir",
      ratingsAverage: null,
      ratingsCount: null,
      userRating: 5,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: false,
      jobDescription: null,
      youTubeChannelId: null,
      substackStripColor: null,
      substackTitleColor: null,
      buttonColor: null,
      buttonText: null,
    },
    {
      id: "9e6b2a58-f017-4e38-96ad-74091eb908d2",
      createdAt: "2023-09-30T17:53:33.000Z",
      updatedAt: "2023-12-31T21:03:34.056Z",
      url: "https://openlibrary.org/works/OL17091839W",
      urlPosition: 31,
      isProfileUrl: false,
      strip: "Books",
      linkType: null,
      userId: "54ac9943-b132-42eb-8054-19ac3e8b81b4",
      artistName: null,
      albumName: null,
      coverImageUrl: "https://covers.openlibrary.org/b/id/11447888-L.jpg",
      imageFit: null,
      favicon: null,
      description: null,
      title: "The Martian",
      ISBN: null,
      episodeTitle: null,
      authorName: "Andy Weir",
      ratingsAverage: null,
      ratingsCount: null,
      userRating: 5,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: false,
      jobDescription: null,
      youTubeChannelId: null,
      substackStripColor: null,
      substackTitleColor: null,
      buttonColor: null,
      buttonText: null,
    },
    {
      id: "7256169a-42fd-4c49-96ff-5eec72008f5e",
      createdAt: "2023-10-01T21:39:39.000Z",
      updatedAt: "2023-12-31T21:03:34.056Z",
      url: "https://openlibrary.org/works/OL514629W",
      urlPosition: 30,
      isProfileUrl: false,
      strip: "Books",
      linkType: null,
      userId: "54ac9943-b132-42eb-8054-19ac3e8b81b4",
      artistName: null,
      albumName: null,
      coverImageUrl: "https://covers.openlibrary.org/b/id/9274698-L.jpg",
      imageFit: null,
      favicon: null,
      description: null,
      title: "Surely You're Joking, Mr. Feynman",
      ISBN: null,
      episodeTitle: null,
      authorName: "Richard Phillips Feynman, Ralph Leighton",
      ratingsAverage: null,
      ratingsCount: null,
      userRating: 4.5,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: false,
      jobDescription: null,
      youTubeChannelId: null,
      substackStripColor: null,
      substackTitleColor: null,
      buttonColor: null,
      buttonText: null,
    },
    {
      id: "53956403-8245-4b3b-8ab0-7678687b45c4",
      createdAt: "2023-10-01T21:44:34.000Z",
      updatedAt: "2023-12-31T21:03:34.056Z",
      url: "https://openlibrary.org/works/OL497483W",
      urlPosition: 28,
      isProfileUrl: false,
      strip: "Books",
      linkType: null,
      userId: "54ac9943-b132-42eb-8054-19ac3e8b81b4",
      artistName: null,
      albumName: null,
      coverImageUrl: "https://covers.openlibrary.org/b/id/288439-L.jpg",
      imageFit: null,
      favicon: null,
      description: null,
      title: "The War of Art",
      ISBN: null,
      episodeTitle: null,
      authorName: "Steven Pressfield",
      ratingsAverage: null,
      ratingsCount: null,
      userRating: 5,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: false,
      jobDescription: null,
      youTubeChannelId: null,
      substackStripColor: null,
      substackTitleColor: null,
      buttonColor: null,
      buttonText: null,
    },
    {
      id: "660a08d9-a9bc-4f1f-9798-df0bac5098b2",
      createdAt: "2023-10-25T04:59:01.756Z",
      updatedAt: "2023-12-31T21:03:34.056Z",
      url: "https://openlibrary.org/works/OL24583599W",
      urlPosition: 27,
      isProfileUrl: null,
      strip: "Books",
      linkType: null,
      userId: "54ac9943-b132-42eb-8054-19ac3e8b81b4",
      artistName: null,
      albumName: null,
      coverImageUrl: "https://covers.openlibrary.org/b/id/13268433-M.jpg",
      imageFit: null,
      favicon: null,
      description: null,
      title: "Can’t Hurt Me",
      ISBN: null,
      episodeTitle: null,
      authorName: "David Goggins",
      ratingsAverage: null,
      ratingsCount: null,
      userRating: 5,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: null,
      jobDescription: null,
      youTubeChannelId: null,
      substackStripColor: null,
      substackTitleColor: null,
      buttonColor: null,
      buttonText: null,
    },
  ];

  const navCheck = booksArray?.length > 4 ? "scroll-tall" : "scroll";

  return (
    <Strip
      id="books"
      nav={navCheck}
      titleColor="#372213"
      gradientValues={["#D6D0C4"]}
      title="Bookshelf"
      icon={{ type: "fa", icon: faGoodreads }}
      iconColor="#372213"
    >
      {booksArray?.map((link, index) => {
        return (
          <GoodreadsCard
            key={index}
            image={link.coverImageUrl}
            title={link.title}
            authorName={link.authorName}
            userRating={link.userRating}
          />
        );
      })}
    </Strip>
  );
}
