import React, { useState, useEffect } from "react";
import ActivityCalendar from "react-activity-calendar";
import ReactTooltip from "react-tooltip";
import { ScrollArea } from "@mantine/core";
import axios from "axios";
import { baseUrl } from "../api/links";

export function CommitCalendar({ username }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  async function getContributions(username) {
    setLoading(true);
    try {
      const response = await axios.get(`${baseUrl}/commits/${username}`);
      const daysToShow = 365;
      const slicedResponse = response.data.slice(-daysToShow);
      setData(slicedResponse);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    // Check if username exists before making the API call
    if (username) {
      getContributions(username);
    }
  }, [username]);

  // Render nothing if username is not provided
  if (!username) {
    return null;
  }

  return (
    <ScrollArea>
      <div style={{ minWidth: "700px" }}>
        <ActivityCalendar
          loading={loading}
          data={data}
          theme={{
            level0: "#373e47",
            level1: "#9be9a8",
            level2: "#40c463",
            level3: "#30a14e",
            level4: "#216e39",
          }}
          labels={{
            months: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            totalCount: `{{count}} contributions in the last 12 months`,
            tooltip: "{{count}} contributions on {{date}}",
            style: {
              "font-family": "Roboto",
            },
          }}
          showWeekdayLabels
        >
          <ReactTooltip html />
        </ActivityCalendar>
      </div>
    </ScrollArea>
  );
}
