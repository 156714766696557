import { useRef, useState } from "react";
import {
  Text,
  Group,
  Button,
  createStyles,
  rem,
  Paper,
  ThemeIcon,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import {
  IconCloudUpload,
  IconX,
  IconDownload,
  IconFileText,
  IconCheck,
  IconTrash,
  IconGripVertical,
} from "@tabler/icons-react";
import {
  // useLinksQuery,
  useDeleteLinkMutation,
} from "../queries/queries";

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: "relative",
    marginTop: rem(10),
    marginBottom: rem(30),
  },

  dropzone: {
    borderWidth: rem(1),
    paddingBottom: rem(50),
  },

  icon: {
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[3]
        : theme.colors.gray[4],
  },

  control: {
    position: "absolute",
    width: rem(250),
    left: `calc(50% - ${rem(125)})`,
    bottom: rem(-20),
  },
}));

export function DropzoneButton() {
  const { classes, theme } = useStyles();
  const openRef = useRef(null);
  // const STRIP = "Resume";

  // Add state for file and file details
  // const [file, setFile] = useState(null);
  const [fileDetails, setFileDetails] = useState(null);

  // const createLinkMutation = useCreateLinkMutation();

  // function handleSave(inputValue) {
  //   createLinkMutation.mutate({
  //     // id: id,
  //     url: inputValue,
  //     userId: userId,
  //     strip: STRIP,
  //   });
  // }

  // const {
  //   data: linksData,
  //   isLoading: linksIsLoading,
  //   isError: linksIsError,
  // } = useLinksQuery();

  const deleteLinkMutation = useDeleteLinkMutation();

  // async function handleDelete(id) {
  //   deleteLinkMutation.mutate({
  //     id: id,
  //   });
  // }

  const handleDrop = (acceptedFiles) => {
    const [uploadedFile] = acceptedFiles;
    if (uploadedFile) {
      console.log(uploadedFile);
      // setFile(URL.createObjectURL(uploadedFile));
      setFileDetails({
        name: uploadedFile.name,
        type: uploadedFile.type,
        size: uploadedFile.size,
      });
    }
  };

  return (
    <div>
      {fileDetails && (
        //  || linksData?.length > 0)

        // linksData
        //   .filter((url) => url.strip === "Resume")
        //   .map((url) => {
        // return (
        <Paper
          // shadow="xs"
          radius="md"
          px="sm"
          py="xs"
          mb={8}
          withBorder
          style={{
            backgroundColor: "#F7F8F9",
          }}
        >
          <Group position="apart">
            <Group>
              <IconFileText size={24} />
              <Text size="sm">{fileDetails.name}</Text>
              <ThemeIcon color="green" size="xs" radius="xl">
                <IconCheck />
              </ThemeIcon>
            </Group>
            <Group>
              <Button
                color="red"
                // onClick={() => handleDelete(url.id)}
                disabled={deleteLinkMutation.isLoading}
                style={{
                  padding: "0px 4px",
                }}
              >
                <IconTrash size={16} />
              </Button>
              <IconGripVertical
                size="1.05rem"
                stroke={1.5}
                color="gray"
                style={{
                  cursor: "grab",
                }}
              />
            </Group>
          </Group>
        </Paper>
      )}
      {/* {file && (
        <div className={classes.pdfPreview}>
          <Document file={file} onLoadError={console.error}>
            <Page pageNumber={1} width={300} />
          </Document>
        </div>
      )} */}
      <div className={classes.wrapper}>
        <Dropzone
          openRef={openRef}
          onDrop={handleDrop}
          className={classes.dropzone}
          radius="md"
          accept={[MIME_TYPES.pdf]}
          maxSize={30 * 1024 ** 2}
        >
          <div style={{ pointerEvents: "none" }}>
            <Group position="center">
              <Dropzone.Accept>
                <IconDownload
                  size={rem(50)}
                  color={theme.colors[theme.primaryColor][6]}
                  stroke={1.5}
                />
              </Dropzone.Accept>
              <Dropzone.Reject>
                <IconX
                  size={rem(50)}
                  color={theme.colors.red[6]}
                  stroke={1.5}
                />
              </Dropzone.Reject>
              <Dropzone.Idle>
                <IconCloudUpload
                  size={rem(50)}
                  color={
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[0]
                      : theme.black
                  }
                  stroke={1.5}
                />
              </Dropzone.Idle>
            </Group>

            <Text ta="center" fw={700} fz="lg" mt="xl">
              <Dropzone.Accept>Drop files here</Dropzone.Accept>
              <Dropzone.Reject>Pdf file less than 30mb</Dropzone.Reject>
              <Dropzone.Idle>Upload resume</Dropzone.Idle>
            </Text>
            <Text ta="center" fz="sm" mt="xs" c="dimmed">
              Drag&apos;n&apos;drop files here to upload.
            </Text>
          </div>
        </Dropzone>

        <Button
          className={classes.control}
          size="md"
          radius="xl"
          onClick={() => openRef.current?.()}
        >
          Select file
        </Button>
      </div>
    </div>
  );
}
