import React, { useEffect } from "react";
import { useUserContext } from "../contexts/UserContext";
import {
  useAnalyticsQuery,
  useControlledProfileQuery,
} from "../queries/queries";
import {
  Button,
  Center,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { isPremium } from "../utils/productCatalog";
import { CheckoutPricing } from "../components/CheckoutPricing";

export function Analytics() {
  const { loggedInUserId, loggedInUsername, loggedInEmail } = useUserContext();

  const {
    data: plausibleData,
    isLoading: plausibleIsLoading,
    isError: plausibleIsError,
  } = useAnalyticsQuery(loggedInUserId);

  const { data: profileData } = useControlledProfileQuery(loggedInUsername);

  const userIsActive = profileData?.stripeSubscriptionStatus === "active";

  const userIsPremium = isPremium(profileData?.stripePriceId);

  const isAuthorized = userIsActive && userIsPremium;

  const customerEmailEncoded = encodeURIComponent(loggedInEmail);

  useEffect(() => {
    // Create script element
    const script = document.createElement("script");
    script.src = "https://plausible.io/js/embed.host.js";
    script.async = true;

    // Append script to the body
    document.body.appendChild(script);

    // Clean up
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array to run only once

  if (!isAuthorized) {
    return (
      <>
        {userIsActive ? (
          <>
            <Paper
              p="xs"
              mb={2}
              withBorder
              mr="sm"
              radius="md"
              sx={{ backgroundColor: "#DBE4FF" }}
            >
              <Group position="apart" noWrap>
                <Text size="sm" lineClamp={2}>
                  This is a preview. Upgrade to "Premium" in order to start
                  tracking your analytics.
                </Text>
                <Button
                  size="sm"
                  compact
                  variant="gradient"
                  component="a"
                  href={`https://billing.stripe.com/p/login/cN20202jQ1M1ayYeUU?prefilled_email=${customerEmailEncoded}`}
                >
                  Upgrade
                </Button>
              </Group>
            </Paper>
          </>
        ) : (
          <CheckoutPricing
            explainer={`This is a preview. Subscribe to "Premium" in order to start
                  tracking your analytics.`}
            buttonText="Subscribe"
          />
        )}
        <iframe
          title="Plausible Analytics"
          src="https://plausible.io/share/tapistr.ee%2Faubrey?auth=g4LCtiEwI3c8qGgpkehdW&embed=true&theme=light"
          scrolling="yes"
          frameBorder="0"
          loading="lazy"
          style={{ width: "1px", minWidth: "100%", height: "93vh" }}
        ></iframe>
      </>
    );
  } else if (plausibleIsLoading || plausibleIsError) {
    return (
      <Center mt="lg">
        <Stack miw={300} maw={400} align="center" justify="center">
          <Title color="#444444" order={3}>
            You may be seeing this because your are not logged in our on the
            paid plan.
          </Title>
          <Button
            variant="outline"
            size="md"
            fullWidth
            component="a"
            href="https://tapistr.ee"
          >
            Go back to the home page
          </Button>
          <Button
            size="md"
            fullWidth
            component="a"
            href="https://tapistr.ee/sign-in"
          >
            Login
          </Button>
        </Stack>
      </Center>
    );
  } else {
    return (
      <>
        {plausibleData?.plausibleLink ? (
          <iframe
            title="Plausible Analytics"
            src={plausibleData?.plausibleLink}
            scrolling="yes"
            frameBorder="0"
            loading="lazy"
            style={{ width: "1px", minWidth: "100%", height: "99vh" }}
          ></iframe>
        ) : (
          <>
            Please allow some time for your analytics dashboard to be
            provisioned.
          </>
        )}
      </>
    );
  }
}
