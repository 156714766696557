import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "./Strip";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";
import { faMedium } from "@fortawesome/free-brands-svg-icons";
import { MediumCard } from "./MediumCard";

export function MediumStrip() {
  const STRIP = "Medium";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="medium"
        nav="scroll-tall"
        gradientValues={["#fafbfc"]}
        title="Medium"
        titleColor="#000000"
        icon={{ type: "fa", icon: faMedium }}
        iconColor="black"
      >
        {linksDataByStrip?.map((item, index) => {
          return (
            <MediumCard
              key={index}
              url={item.url}
              coverImageUrl={item.coverImageUrl}
              favicon={item.favicon}
              title={item.title}
              description={item.description}
              artistName={item.artistName}
            />
          );
        })}
      </Strip>
    );
  }
}
