import React, { Suspense } from "react";
import { Card, Group, ScrollArea, Text } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useAnimationOffsetEffect } from "@mantine/carousel";
import { useStripsQuery } from "../queries/queries";

export function StripDouble({
  id,
  backgroundImageUrl,
  gradientValues,
  title,
  title2,
  title3,
  titleColor,
  followButton,
  explainer,
  icon,
  iconColor,
  iconLink,
  children,
}) {
  // const TRANSITION_DURATION = 200;

  // const [embla, setEmbla] = useState(null);
  // useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  // Get Experience and Education children
  const getChildrenByType = (title) =>
    React.Children.toArray(children).filter(
      (child) => child.props.type === title
    );

  const firstTitle = title || title2 || title3;

  // Create an array of all titles
  const allTitles = [title, title2, title3].filter(Boolean);

  // Assign the first title to `firstTitle` and the rest to `secondaryTitles`
  const secondaryTitles = allTitles.slice(1);

  const {
    data: stripsData,
    // isLoading: stripsIsLoading,
    // isError: stripsIsError,
  } = useStripsQuery();

  const filteredStrip = stripsData?.find(
    (strip) => strip.strip.toLowerCase() === id
  );
  const showFollowButton = filteredStrip?.showFollowButton;

  return (
    <div id={id}>
      <Card
        p="xs"
        radius="md"
        // withBorder
        mx="xs"
        mt="xs"
        mb="lg"
        sx={(theme) => ({
          backgroundImage: backgroundImageUrl
            ? `url(${backgroundImageUrl})`
            : theme.fn.linearGradient(45, ...gradientValues), //Singular values will intentionally render solid
          backgroundRepeat: backgroundImageUrl ? "no-repeat" : undefined, // If backgroundImageUrl is present, no-repeat, else undefined
          backgroundSize: backgroundImageUrl ? "cover" : undefined, // If backgroundImageUrl is present, cover, else undefined
          color: theme.white,
          padding: "15px",
          display: "flex",
          flexDirection: "column",
          minHeight: "60px",
          overflowX: "auto", // Making horizontal overflow scrollable
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        })}
      >
        <Group position="apart">
          <Text
            size={28}
            color={titleColor ? titleColor : "white"}
            weight={500}
            p={0}
          >
            {firstTitle || "LinkedIn"}
          </Text>
          {showFollowButton && followButton}
          {iconLink ? (
            <a href={iconLink} target="_blank" rel="noreferrer">
              {icon?.type === "fa" && (
                <FontAwesomeIcon icon={icon.icon} color={iconColor} size="2x" />
              )}
              {icon?.type === "emoji" && (
                <span className="emoji-icon">{icon.icon}</span>
              )}
              {icon?.type === "image" && icon.icon}
            </a>
          ) : (
            <>
              {icon?.type === "fa" && (
                <FontAwesomeIcon icon={icon.icon} color={iconColor} size="2x" />
              )}
              {icon?.type === "emoji" && (
                <span className="emoji-icon">{icon.icon}</span>
              )}
              {icon?.type === "image" && icon.icon}
            </>
          )}
        </Group>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            color: titleColor,
          }}
        >
          {explainer}
        </div>
        {allTitles?.length > 0 && (
          <Suspense fallback={<div>Loading...</div>}>
            <ScrollArea type="auto" offsetScrollbars>
              <div className="card-container-scroll">
                {getChildrenByType(firstTitle)}
              </div>
            </ScrollArea>
          </Suspense>
        )}
        {secondaryTitles[0] && (
          <>
            <Text
              size={28}
              color={titleColor ? titleColor : "white"}
              weight={500}
              p={0}
            >
              {secondaryTitles[0]}
            </Text>
            <Suspense fallback={<div>Loading...</div>}>
              <ScrollArea type="auto" offsetScrollbars>
                <div className="card-container-scroll">
                  {getChildrenByType(secondaryTitles[0])}
                </div>
              </ScrollArea>
            </Suspense>
          </>
        )}
        {secondaryTitles[1] && (
          <>
            <Text
              size={28}
              color={titleColor ? titleColor : "white"}
              weight={500}
              p={0}
            >
              {secondaryTitles[1]}
            </Text>
            <Suspense fallback={<div>Loading...</div>}>
              <ScrollArea type="auto" offsetScrollbars>
                <div className="card-container-scroll">
                  {getChildrenByType(secondaryTitles[1])}
                </div>
              </ScrollArea>
            </Suspense>
          </>
        )}
      </Card>
    </div>
  );
}
