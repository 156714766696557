import React from "react";
import makeAsyncScriptLoader from "react-async-script";

const AirbnbCardComponent = ({ isScriptLoaded, isScriptLoadSucceed, url }) => {
  const onScriptLoad = () => {
    if (window.AirbnbAPI) {
      window.AirbnbAPI.bootstrap();
    }
  };

  const roomId = (url) => {
    const roomIdRegex = /(?:\/rooms\/|)(\d+)/;
    const matches = url?.match(roomIdRegex);

    if (matches && matches[1]) {
      return matches[1];
    } else {
      throw new Error("Room ID not found");
    }
  };

  if (isScriptLoaded && isScriptLoadSucceed) {
    onScriptLoad();
  }

  return (
    <div
      className="airbnb-embed-frame"
      data-id={roomId(url)}
      data-view="home"
      style={{ width: 450, height: 300, margin: "auto" }}
    >
      <a href={`https://www.airbnb.com/rooms/${roomId}`}>
        <span>View On Airbnb</span>
      </a>
    </div>
  );
};

export const AirbnbCard = makeAsyncScriptLoader(
  "https://www.airbnb.com/embeddable/airbnb_jssdk"
)(AirbnbCardComponent);

// import scriptLoader from "react-async-script-loader";

// export const AirbnbCard = scriptLoader([
//   "https://www.airbnb.com/embeddable/airbnb_jssdk",
// ])(({ isScriptLoaded, isScriptLoadSucceed, link }) => {
//   if (isScriptLoaded && isScriptLoadSucceed) {
//     window.AirbnbAPI.bootstrap();
//   }

//   const roomId = (link) => {
//     const roomIdRegex = /(?:\/rooms\/|)(\d+)/;
//     const matches = link.match(roomIdRegex);

//     if (matches && matches[1]) {
//       return matches[1];
//     } else {
//       throw new Error("Room ID not found");
//     }
//   };

//   return (
//     <div
//       className="airbnb-embed-frame"
//       data-id={roomId}
//       data-view="home"
//       style={{ width: 450, height: 300, margin: "auto" }}
//     >
//       <a href={`https://www.airbnb.com/rooms/${roomId}`}>
//         <span>View On Airbnb</span>
//       </a>
//     </div>
//   );
// });
