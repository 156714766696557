import { useEffect } from "react";
import { Card, Button, Image, Text, Stack } from "@mantine/core";

export function BuyTicketsButton({ eventId }) {
  const buttonId = `buy-tickets-${eventId}`;

  useEffect(() => {
    // Function to dynamically load the Eventbrite widget script
    const loadEventbriteWidgetScript = (callback) => {
      const existingScript = document.getElementById(
        "eventbrite-widget-script"
      );

      if (!existingScript) {
        const script = document.createElement("script");
        script.src = "https://www.eventbrite.com/static/widgets/eb_widgets.js";
        script.id = "eventbrite-widget-script";
        document.body.appendChild(script);

        script.onload = () => {
          if (callback) callback();
        };
      } else if (callback) {
        callback();
      }
    };

    // Initialize the Eventbrite widget after the script is loaded
    const initializeEventbriteWidget = () => {
      if (window.EBWidgets && !window.initializedEventIds) {
        window.initializedEventIds = {};
      }
      if (window.EBWidgets && !window.initializedEventIds[eventId]) {
        window.EBWidgets.createWidget({
          widgetType: "checkout",
          eventId: eventId,
          modal: true,
          modalTriggerElementId: buttonId,
          onOrderComplete: () => console.log("Order complete!"),
        });
        window.initializedEventIds[eventId] = true; // Mark this eventId as initialized
      }
    };

    // Load the Eventbrite widget script and initialize the widget
    loadEventbriteWidgetScript(initializeEventbriteWidget);
  }, [eventId, buttonId]); // Dependencies array to control effect re-execution

  const buttonColor = "#EC5437";

  return (
    <Button
      id={buttonId}
      fullWidth
      styles={(theme) => ({
        root: {
          backgroundColor: buttonColor,
          "&:not([data-disabled])": buttonColor
            ? theme.fn.hover({
                backgroundColor: theme.fn.darken(buttonColor, 0.05),
              })
            : {},
        },
      })}
    >
      Get tickets
    </Button>
  );
}

export function EventbriteCard({
  title,
  description,
  date,
  price,
  location,
  status,
  startUtc,
  coverImageUrl,
  eventId,
}) {
  // Function to format start date
  const formatStartDate = (utcDateString) => {
    const date = new Date(utcDateString);
    const optionsDate = { month: "long", day: "numeric", year: "numeric" };
    const optionsTime = { hour: "numeric", minute: "2-digit", hour12: true };
    const formattedDate = date.toLocaleDateString("en-US", optionsDate);
    const formattedTime = date.toLocaleTimeString("en-US", optionsTime);
    return `Starts ${formattedDate} at ${formattedTime}`;
  };

  return (
    <div className="card-xl">
      <Card
        p={0}
        radius="md"
        mx="xs"
        mt="xs"
        mb="lg"
        shadow="md"
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#FFFFFF",
        }}
      >
        <Card.Section>
          <Image
            p={0}
            height={217}
            src={coverImageUrl}
            alt={title}
            withPlaceholder
            imageProps={{
              loading: "lazy",
            }}
          />
        </Card.Section>

        <Stack
          p="md"
          spacing="xs"
          justify="space-between"
          style={{ width: "100%" }}
        >
          <div>
            <Text weight={500} size="lg" lineClamp={1}>
              {title}
            </Text>
            <Text color="dimmed" size="sm" lineClamp={3} mih="43.375px" mb="xs">
              {description}
            </Text>
            <Text color="dimmed" size="sm" lineClamp={3}>
              {status === "live"
                ? formatStartDate(startUtc)
                : `This event is completed.`}
            </Text>
          </div>
          <BuyTicketsButton eventId={eventId} />
        </Stack>
      </Card>
    </div>
  );
}
