import { Button, rem } from "@mantine/core";
import { IconBrandSpotify, IconBrandTwitter } from "@tabler/icons-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faStrava } from "@fortawesome/free-brands-svg-icons";
import { cleanUsername } from "../utils/cleanUsername";
import { useEffect } from "react";

//Need to figure out how to universal style things, keep hover effect for buttons.

export function FollowButton({
  icon,
  color,
  backgroundColor,
  text,
  link,
  radius,
  variant,
  size,
  border,
}) {
  return (
    <Button
      size={size}
      component="a"
      variant={variant}
      target="_blank"
      rel="noopener noreferrer"
      href={link}
      leftIcon={icon}
      radius={radius}
      styles={(theme) => ({
        root: {
          backgroundColor: backgroundColor || "#FFFFFF",
          color: color,
          fontSize: "11px",
          border: border || 0,
          height: rem(20),
          paddingLeft: rem(10),
          paddingRight: rem(10),
          "&:not([data-disabled])": theme.fn.hover({
            backgroundColor: theme.fn.darken(
              backgroundColor || "#FFFFFF",
              0.05
            ),
          }),
        },
        leftIcon: {
          marginRight: "4px",
        },
        label: {
          fontWeight: 500,
        },
      })}
    >
      {text}
    </Button>
  );
}

export function LinkedInFollowButton({
  icon,
  color,
  backgroundColor,
  text,
  link,
  radius,
  variant,
}) {
  return (
    <Button
      component="a"
      variant={variant}
      target="_blank"
      rel="noopener noreferrer"
      py={0}
      mt={2}
      href={link}
      leftIcon={icon}
      radius={radius}
      styles={(theme) => ({
        root: {
          backgroundColor: backgroundColor || "#FFFFFF",
          color: color,
          height: rem(30),
          paddingLeft: rem(20),
          paddingRight: rem(20),
          "&:not([data-disabled])": theme.fn.hover({
            backgroundColor: "#E4F0FE",
          }),
        },
        leftIcon: {
          marginRight: "4px",
        },
      })}
    >
      {text}
    </Button>
  );
}

export function SuperFanButton() {
  return (
    <Button
      radius="xl"
      leftIcon="🥳"
      variant="gradient"
      gradient={{ from: "teal", to: "blue", deg: 60 }}
    >
      Follow me EVERYWHERE!
    </Button>
  );
}
export function TwitterFollowButton() {
  const COLOR = "#1D9Bf0";
  return (
    <FollowButton
      icon={<IconBrandTwitter size={rem(18)} color={COLOR} />}
      color={COLOR}
      text="Follow me on Twitter!"
    />
  );
}

export function YouTubeSubscribeButton({
  channelId,
  dataLayout,
  subscriberCount,
  darkTheme,
}) {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://apis.google.com/js/platform.js";
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="g-ytsubscribe"
      data-channelid={channelId}
      data-layout={dataLayout ? "full" : "default"}
      data-theme={darkTheme ? "dark" : "default"}
      data-count={subscriberCount ? "hideen" : "default"}
    ></div>
  );
}

export function GitHubFollowButton({ link }) {
  const COLOR = "#24292F";

  const username = cleanUsername(link);

  return (
    <FollowButton
      icon={<FontAwesomeIcon icon={faGithub} size="lg" color={COLOR} />}
      color={COLOR}
      backgroundColor="#F2F5F8"
      text={`Follow @${username}`}
      link={link}
      radius="xs"
    />
  );
}

export function StravaFollowButton({ link }) {
  const COLOR = "#FC4C02";

  return (
    <FollowButton
      icon={<FontAwesomeIcon icon={faStrava} size="lg" color={COLOR} />}
      color={COLOR}
      backgroundColor="#F2F5F8"
      text={`View on Strava`}
      link={link}
      radius="xs"
      border="1px solid #e2e9ef"
    />
  );
}

export function SpotifyFollowButton() {
  const COLOR = "#45DB74";

  return (
    <FollowButton
      size="sm"
      icon={<IconBrandSpotify size={rem(18)} color={COLOR} />}
      color={COLOR}
      text="Follow on Spotify!"
    />
  );
}

export function LinkedInAddConnectionButton({ link }) {
  const COLOR = "#0B66C2";

  return (
    <LinkedInFollowButton
      icon={
        <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 4a3 3 0 11-3-3 3 3 0 013 3zM6.75 8h-1.5A2.25 2.25 0 003 10.25V15h6v-4.75A2.25 2.25 0 006.75 8zM13 8V6h-1v2h-2v1h2v2h1V9h2V8z"
            fill={COLOR}
          ></path>
        </svg>
      }
      color={COLOR}
      backgroundColor="transparent"
      text="Follow"
      variant="outline"
      link={link}
      radius="xl"
    />
  );
}

export function ApplePodcastsSubscribeButton() {
  return <></>;
}
