import "../App.css";
import { useLinksQuery } from "../queries/queries";
import { faItunesNote } from "@fortawesome/free-brands-svg-icons";
import { Text } from "@mantine/core";
import { Strip } from "./Strip";
import { AppleMusicEmbed } from "./AppleMusicEmbed";
import { StripSkeleton } from "./StripSkeleton";
import { ErrorBoundary } from "@sentry/react";

export function AppleMusicStrip() {
  const STRIP = "Apple-Music";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="apple-music"
        nav="scroll"
        gradientValues={["#FAFAFA"]}
        title="Apple Music"
        titleColor="#212121"
        icon={{ type: "fa", icon: faItunesNote }}
        iconColor="#F5334D"
      >
        {linksDataByStrip?.map((link, index) => {
          return (
            <ErrorBoundary key={`${index}-${link}`}>
              <AppleMusicEmbed url={link.url} key={index} />
            </ErrorBoundary>
          );
        })}
      </Strip>
    );
  }
}
