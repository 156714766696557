import {
  Anchor,
  Center,
  Divider,
  Flex,
  Image,
  List,
  Modal,
  SegmentedControl,
  Text,
} from "@mantine/core";
import { useState } from "react";
import { UrlInput } from "./UrlInput";

export function SubstackEmbedInput() {
  // This regular expression matches the pattern of a Substack domain
  const substackRegex =
    /^https:\/\/[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.substack\.com\/?$/i;

  return (
    <>
      <Text size="sm" weight={500}>
        Enter your Substack domain
      </Text>
      <UrlInput
        placeholder="https://pmarca.substack.com"
        isNotDraggable={true}
        defaultUrl=""
        isProfileUrl={false}
        defaultSaved={false}
        defaultVerified={false}
        strip="Substack"
        regexCheck={substackRegex}
        errorMessage="Your embed ID should look like: https://pmarca.substack.com"
      />
    </>
  );
}

export function BeehiivEmbedInput() {
  const [opened, setOpened] = useState(false);
  const beehiivRegex =
    /^https:\/\/embeds\.beehiiv\.com\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/?$/;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        closeOnClickOutside
        title={<b>Create a Beehiiv sign-up embed</b>}
      >
        <List type="ordered" size="sm">
          <List.Item>
            Go to{" "}
            <Anchor
              href="https://app.beehiiv.com/settings/publication/subscribe_forms"
              target="_blank"
            >
              https://app.beehiiv.com/settings/publication/subscribe_forms
            </Anchor>
            .
          </List.Item>
          <List.Item>
            Click <b>"Create new form"</b> and save.
          </List.Item>
          <List.Item>
            Click <b>"View"</b> and you will find the publication id here:
            <Image src="/beehiiv-embed.png" width={400} mb="sm" laz />
          </List.Item>
          <List.Item>
            Finally, paste this into the Tapistree input and save.
          </List.Item>
        </List>
      </Modal>

      <Text size="sm" weight={500}>
        <Flex align="center">
          Enter your Beehiiv embed URL
          <Anchor size="xs" ml="xs" onClick={() => setOpened(true)}>
            {"  "}
            (What's this?)
          </Anchor>
        </Flex>
      </Text>
      <UrlInput
        placeholder="https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
        isNotDraggable={true}
        defaultUrl=""
        isProfileUrl={false}
        defaultSaved={false}
        defaultVerified={false}
        strip="Newsletter"
        regexCheck={beehiivRegex}
        errorMessage="Your embed URL should look like: https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
      />
    </>
  );
}

export function NewsletterEmbedInput() {
  const [newsletterValue, setNewsletterValue] = useState("Beehiiv");

  return (
    <>
      <Divider label="Add a newsletter sign-up embed" my="sm" />
      <SegmentedControl
        fullWidth
        mb="sm"
        value={newsletterValue}
        onChange={setNewsletterValue}
        data={[
          {
            label: (
              <Center>
                <Image src="/beehiiv.png" height={20} width={20} mr={4} />
                Beehiiv
              </Center>
            ),
            value: "Beehiiv",
          },
          //   {
          //     label: (
          //       <Center>
          //         <Image
          //           src="/convert-kit-icon.webp"
          //           height={20}
          //           width={20}
          //           mr={4}
          //         />
          //         ConvertKit
          //       </Center>
          //     ),
          //     value: "ConvertKit",
          //   },
          {
            label: (
              <Center>
                <Image src="/substack-icon.png" height={20} width={20} mr={4} />
                Substack
              </Center>
            ),
            value: "Substack",
            disabled: false,
          },
        ]}
      />
      {newsletterValue === "Beehiiv" ? (
        <BeehiivEmbedInput />
      ) : newsletterValue === "Substack" ? (
        <SubstackEmbedInput />
      ) : null}
    </>
  );
}
