export function cleanUsername(url) {
  if (typeof url !== "string") {
    throw new Error("Invalid input: URL must be a string");
  }

  // Define regular expression patterns for both GitHub and LinkedIn profile URLs
  const githubPattern = /^(?:https?:\/\/)?(?:www\.)?github\.com\/([^/]+)\/?/i;
  const linkedinPattern =
    /^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/([^/]+)\/?/i;

  // Use the regular expressions to extract the username from the URL
  const githubMatch = url.match(githubPattern);
  const linkedinMatch = url.match(linkedinPattern);

  if (githubMatch && githubMatch[1]) {
    // If it's a GitHub URL, return the GitHub username
    return githubMatch[1];
  } else if (linkedinMatch && linkedinMatch[1]) {
    // If it's a LinkedIn URL, return the LinkedIn username
    return linkedinMatch[1];
  } else {
    // If the URL doesn't match either pattern, return the original input
    return url;
  }
}
