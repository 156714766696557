import React from "react";
import { Card, Image, Text, Rating } from "@mantine/core";

export function MovieCard({ image, title, rating }) {
  return (
    <div className="card-sm">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <div style={{ display: "flex", alignItems: "center", height: "100px" }}>
          <Image
            src={image}
            width={60}
            height={100}
            fit="contain"
            alt={title}
            imageProps={{
              loading: "lazy",
            }}
          />
          <div style={{ paddingLeft: "10px" }}>
            <Text weight={500}>{title}</Text>
            {rating && (
              <div style={{ paddingTop: "5px" }}>
                <Rating color="orange" value={rating} readOnly />
              </div>
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
