import { ScrollArea } from "@mantine/core";
import ActivityCalendar from "react-activity-calendar";

export function StravaCalendar({
  data,
  loading,
  roundedTotalDistance,
  hideColorLegend,
  minWidth,
  distanceUnit,
}) {
  //If distance unit is null, set it to "kilometers"
  if (!distanceUnit) {
    distanceUnit = "kilometers";
  }
  return (
    <ScrollArea>
      <div style={{ minWidth: minWidth }}>
        <ActivityCalendar
          loading={loading}
          data={data}
          color="red"
          theme={{
            level0: "#EBEDF0",
            level1: "#ffb895",
            level2: "#ff854a",
            level3: "#FC4C02",
            level4: "#CC4200",
          }}
          labels={{
            months: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            weekdays: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            totalCount: `${roundedTotalDistance} total ${distanceUnit}`,
            tooltip: `{{count}} ${distanceUnit} on {{date}}`,
          }}
          hideColorLegend={hideColorLegend}
          style={{
            color: "#444444",
          }}
          showWeekdayLabels
        ></ActivityCalendar>
      </div>
    </ScrollArea>
  );
}
