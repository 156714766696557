import { Image, Paper } from "@mantine/core";
import { Strip } from "../Strip";

export function DummySubstackEmbed({ url }) {
  const style = {
    margin: 0,
    width: "100%",
    height: "300px",
    borderRadius: "4px",
    backgroundColor: undefined,
    border: undefined,
  };

  // Remove trailing slash if it exists
  const formattedUrl = url.endsWith("/") ? url.slice(0, -1) : url;

  const finalEmbedUrl = `${formattedUrl}/embed`;

  return (
    <>
      <div className="card-full">
        <Paper p={0} radius="md">
          <iframe
            title="substack"
            onClick={() => console.log("clicked")}
            src={finalEmbedUrl}
            // datatestid="beehiiv-embed"
            frameBorder="0"
            scrolling="no"
            style={style}
          ></iframe>
        </Paper>
      </div>
    </>
  );
}

export function DummySubstackStrip() {
  return (
    <>
      <Strip
        id="substack"
        nav="no-scroll"
        gradientValues={["#FEFFFF"]}
        title="Substack"
        titleColor="#404040"
        icon={{
          type: "image",
          icon: (
            <Image
              src="/substack-icon.png"
              height={44}
              width={44}
              mr={-4}
              mt={-2}
            />
          ),
        }}
      >
        <DummySubstackEmbed url="https://lenny.substack.com" slim={false} />;
      </Strip>
    </>
  );
}
