import "../App.css";
import { Card, ScrollArea, Text } from "@mantine/core";
import { useLinksQuery } from "../queries/queries";
import { InstagramEmbed } from "react-social-media-embed";
import { StripSkeleton } from "./StripSkeleton";
import { ErrorBoundary } from "@sentry/react";

export function InstagramStrip() {
  const STRIP = "Instagram";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return linksDataByStrip?.length === 1 ? (
      <ErrorBoundary
        key={linksDataByStrip[0]?.url + linksDataByStrip[0]?.index}
      >
        <div id="instagram">
          <Card
            p="xs"
            radius="md"
            // withBorder
            mx="xs"
            mt="xs"
            mb="lg"
            sx={{
              display: "flex",
              flexDirection: "column",
              minHeight: "200px",
              overflowX: "auto", // Making horizontal overflow scrollable
              boxShadow:
                "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
            }}
          >
            <InstagramEmbed
              placeholderProps={{
                style: {
                  // height: 550,
                  width: "100%",
                },
              }}
              url={linksDataByStrip[0].url}
              frame={window}
            />
          </Card>
        </div>
      </ErrorBoundary>
    ) : (
      <Card
        // shadow="sm"
        p="xs"
        radius="md"
        // withBorder
        mx="xs"
        mt="xs"
        mb="lg"
        sx={(theme) => ({
          color: theme.white,
          // padding: "15px",
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
          overflowX: "auto", // Making horizontal overflow scrollable
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        })}
      >
        <ScrollArea type="auto" offsetScrollbars>
          <div className="card-container-scroll">
            {linksDataByStrip.map((link, index) => {
              return (
                <ErrorBoundary key={link.url + index}>
                  <InstagramEmbed
                    placeholderProps={{
                      style: {
                        height: 550,
                        width: 324,
                      },
                    }}
                    width="100%"
                    url={link.url}
                    frame={window}
                  />
                </ErrorBoundary>
              );
            })}
          </div>
        </ScrollArea>
      </Card>
    );
  }
}
