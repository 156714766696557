import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { Strip } from "../Strip";
import { FacebookEmbed } from "react-social-media-embed";
import { ErrorBoundary } from "@sentry/react";

export function DummyFacebookStrip() {
  return (
    <Strip
      id="facebook" //Lowercase
      nav="scroll-tall"
      gradientValues={["#0A66FF"]}
      title="Facebook"
      icon={{ type: "fa", icon: faFacebook }}
      iconColor="white"
      className="facebook"
      // iconLink="YOUR Instagram PROFILE...."TBU
    >
      <ErrorBoundary>
        <FacebookEmbed
          placeholderProps={{
            style: {
              height: 550,
              width: 400,
            },
          }}
          width={400}
          url="https://fb.watch/oq11Ngqezj/"
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <FacebookEmbed
          placeholderProps={{
            style: {
              height: 550,
              width: 400,
            },
          }}
          width={400}
          url="https://www.facebook.com/VANS/posts/pfbid0ugC6ge1X7eAW7LjJHYdWj2hNHR6BuLM2SSFg27T85q8U7oh9bcgSicsRpBDscEUYl"
        />
      </ErrorBoundary>
    </Strip>
  );
}
