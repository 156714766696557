import {
  Group,
  ActionIcon,
  Avatar,
  createStyles,
  Flex,
  Menu,
  Text,
  Paper,
} from "@mantine/core";
import { useDeleteLinkMutation } from "../queries/queries";
import { IconTrash, IconGripVertical, IconDots } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  item: {},

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  dragHandle: {
    display: "flex",
    alignItems: "center",
    // paddingRight: theme.spacing.xs,
  },
  hidden: {
    visibility: "hidden",
  },
}));

export const SpotifySelectionCard = ({
  id,
  image,
  itemName,
  description,
  episodeTitle,
  provided,
  snapshot,
}) => {
  const deleteLinkMutation = useDeleteLinkMutation();

  async function handleDelete(id) {
    deleteLinkMutation.mutate({
      id: id,
    });
  }

  const { classes } = useStyles();

  return (
    <div
      className={snapshot?.isDragging ? classes.itemDragging : ""}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      <Paper
        // shadow="xs"
        radius="md"
        pl={6}
        pr="xs"
        py="xs"
        mb={8}
        withBorder
        style={{
          backgroundColor: "#F7F8F9",
        }}
      >
        <Group position="apart" spacing="xs">
          <Flex align="center">
            <div {...provided?.dragHandleProps} className={classes.dragHandle}>
              <IconGripVertical
                size="1.05rem"
                stroke={1.5}
                color="gray"
                style={{
                  cursor: "grab",
                }}
              />
            </div>
            <Group spacing="xs">
              <Avatar src={image} ml="xs" />
              <div>
                <Group noWrap spacing="xs">
                  <Text maw={220} lineClamp={1}>
                    {itemName}
                  </Text>
                </Group>
                <Text maw={160} lineClamp={1} size="xs" color="dimmed">
                  {episodeTitle || description}
                </Text>
              </div>
            </Group>
          </Flex>
          <Group spacing={0} position="right">
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <ActionIcon>
                  <IconDots size="1rem" stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconTrash size="1rem" stroke={1.5} />}
                  color="red"
                  onClick={() => handleDelete(id)}
                  disabled={deleteLinkMutation.isLoading}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </Paper>
    </div>
  );
};
