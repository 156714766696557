import React, { useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Group, Avatar, Text, Autocomplete, Loader } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import {
  useCreateLinkMutation,
  useLinksQuery,
  useProfileQuery,
} from "../queries/queries";
import { baseUrl } from "../api/links";

export function SpotifySearchBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200, {
    leading: true,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  const STRIP = "Spotify";
  const LIMIT = 4;
  const MARKET = "US";
  const LOCALE = "en-US";
  const TYPE = "album,show,episode,playlist";

  const {
    data: linksData,
    // isLoading: linksIsLoading,
    // isError: linksIsError,
  } = useLinksQuery();

  // Sort the links by urlPosition in descending order
  const sortedLinks = linksData?.sort((a, b) => b.urlPosition - a.urlPosition);

  // Get the highest existing urlPosition number and add 1
  const nextUrlPosition =
    sortedLinks?.length > 0 ? sortedLinks[0].urlPosition + 1 : 0;

  useEffect(() => {
    const fetchResults = async () => {
      setIsSearching(true);
      try {
        const response = await axios.get(
          `${baseUrl}/spotify-search?query=${debouncedSearchTerm}&type=${TYPE}&market=${MARKET}&locale=${LOCALE}&limit=${LIMIT}`
        );
        setSearchResults([
          ...response.data.albums.items.map((item) => ({
            ...item,
            type: "album",
          })),
          ...response.data.shows.items.map((item) => ({
            ...item,
            type: "show",
          })),
          ...response.data.episodes.items.map((item) => ({
            ...item,
            type: "episode",
          })),
          ...response.data.playlists.items.map((item) => ({
            ...item,
            type: "playlist",
          })),
        ]);
        setIsSearching(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (debouncedSearchTerm) {
      fetchResults();
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  const data = searchResults?.map((item) => {
    let image, description, itemName, value, url, group;

    switch (item.type) {
      case "track":
        image = item.album.images[0].url;
        description = item.artists[0].name;
        itemName = item.name;
        value = item.external_urls.spotify;
        url = item.external_urls.spotify;
        group = "Music";
        break;
      case "album":
        image = item.images[0].url;
        description = item.artists[0].name;
        itemName = item.name;
        value = item.external_urls.spotify;
        url = item.external_urls.spotify;
        group = "Music";
        break;
      case "artist":
        image = item.images[0]?.url || "";
        description = "";
        itemName = item.name;
        value = item.external_urls.spotify;
        url = item.external_urls.spotify;
        group = "Music";
        break;
      case "show":
        image = item.images[0]?.url || "";
        description = item.publisher;
        itemName = item.name;
        value = item.external_urls.spotify;
        url = item.external_urls.spotify;
        group = "Podcasts (Shows) – Link to latest episode";
        break;
      case "episode":
        image = item.images[0]?.url || "";
        description = item.publisher;
        itemName = item.name;
        value = item.external_urls.spotify;
        url = item.external_urls.spotify;
        group = "Podcasts (Episodes)";
        break;
      case "playlist":
        image = item.images[0]?.url || "";
        description = item.owner.display_name;
        itemName = item.name;
        value = item.external_urls.spotify;
        url = item.external_urls.spotify;
        group = "Playlists";
        break;
      default:
        break;
    }

    return {
      image,
      description,
      itemName,
      value,
      url,
      group,
    };
  });

  const handleItemSubmit = (item) => {
    setIsSaving(true);
    handleSave(item.url);
  };

  const AutoCompleteItem = forwardRef(
    ({ description, itemName, image, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group spacing="xs">
          <Avatar src={image} />
          <div>
            <Text maw={280} lineClamp={1}>
              {itemName}
            </Text>
            <Text maw={280} lineClamp={1} size="xs" color="dimmed">
              {description}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  const newInputSuccess = () => {
    setSearchTerm("");
    setIsSaving(false);
  };

  const createLinkMutation = useCreateLinkMutation(newInputSuccess);

  function handleSave(inputValue) {
    createLinkMutation.mutate({
      url: inputValue,
      userId: destinationUserId,
      strip: STRIP,
      urlPosition: nextUrlPosition,
    });
  }

  return (
    <>
      <Autocomplete
        label={`Search for Albums and Podcasts`}
        placeholder="Type to search"
        disabled={isSaving}
        initiallyOpened={false}
        itemComponent={AutoCompleteItem}
        data={data}
        value={searchTerm}
        limit={LIMIT * 4}
        rightSection={isSearching && <Loader size="xs" />}
        filter={() => true}
        onChange={setSearchTerm}
        onItemSubmit={handleItemSubmit}
        mt={10}
        maxDropdownHeight={400}
        styles={{
          input: {
            fontSize: "16px",
            height: "20px",
            lineHeight: "20px",
          },
        }}
      />
    </>
  );
}
