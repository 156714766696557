import { YouTubeCard } from "../YouTubeCard";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { YouTubeSubscribeButton } from "../CTAButtons";
import { Strip } from "../Strip";

export function DummyYouTubeStrip() {
  const linksDataByStrip = ["https://www.youtube.com/watch?v=dQw4w9WgXcQ"];

  return (
    <Strip
      id="youtube"
      nav="scroll-tall"
      gradientValues={["#FFFFFF"]}
      followButton={
        <div>
          <YouTubeSubscribeButton
            channelId="UC38IQsAvIsxxjztdMZQtwHA"
            dataLayout //Full
            subscriberCount
          />
        </div>
      }
      title="Videos"
      icon={{ type: "fa", icon: faYoutube }}
      iconColor="#FF0000"
      // iconColor="white"
      titleColor="#000000"
    >
      {linksDataByStrip?.map((link, index) => {
        return (
          <YouTubeCard
            key={index}
            youTubeUrl="https://www.youtube.com/watch?v=dQw4w9WgXcQ"
          />
        );
      })}
    </Strip>
  );
}
