import "../App.css";
import { useLinksQuery } from "../queries/queries";
import { faAmazon } from "@fortawesome/free-brands-svg-icons";
import { Text } from "@mantine/core";
import { Strip } from "./Strip";
import { AmazonCard } from "./AmazonCard";
import { StripSkeleton } from "./StripSkeleton";

export function AmazonStrip() {
  const STRIP = "Amazon";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="Amazon"
        nav="scroll"
        gradientValues={["#000000"]}
        title="Products"
        icon={{ type: "fa", icon: faAmazon }}
        iconColor="white"
      >
        {linksDataByStrip?.map((link, index) => {
          return <AmazonCard key={index} url={link.url} />;
        })}
      </Strip>
    );
  }
}
