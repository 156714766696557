import { useEffect } from "react";
import {
  Modal,
  Group,
  Select,
  TextInput,
  Text,
  Textarea,
  Button,
  Checkbox,
  LoadingOverlay,
  Image,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  useCreateLinkMutation,
  useLinksQuery,
  useProfileQuery,
} from "../queries/queries";
import { CompaniesSearchBar } from "./CompaniesSearchBar";
import { yearsArray } from "../utils/yearsArray";

export function AddExperience({
  opened,
  setOpened,
  editing,
  setEditing,
  experienceId,
}) {
  const STRIP = "LinkedIn";
  const createLinkMutation = useCreateLinkMutation(successSubmit); //TBU for errors
  const { data: linksData } = useLinksQuery();
  const experienceData = linksData?.find((item) => item.id === experienceId);

  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  const experienceForm = useForm({
    initialValues: {
      jobTitle: editing ? experienceData?.jobTitle : "",
      company: editing ? experienceData?.company : "",
      coverImageUrl: editing ? experienceData?.coverImageUrl : "",
      url: editing ? experienceData?.url : "",
      location: editing ? experienceData?.location : "",
      startMonth: editing ? experienceData?.startMonth : "",
      startYear: editing ? experienceData?.startYear : "",
      endMonth: editing ? experienceData?.endMonth : "",
      endYear: editing ? experienceData?.endYear : "",
      description: editing ? experienceData?.jobDescription : "",
      currentPosition: editing ? experienceData?.currentPosition : false,
    },
  });

  const editFormValues = {
    jobTitle: experienceData?.jobTitle,
    company: experienceData?.company,
    coverImageUrl:
      experienceData?.coverImageUrl ||
      `https://logo.clearbit.com/${experienceData?.url}`,
    url: experienceData?.url,
    location: experienceData?.location,
    startMonth: experienceData?.startMonth,
    startYear: experienceData?.startYear,
    endMonth: experienceData?.endMonth,
    endYear: experienceData?.endYear,
    description: experienceData?.jobDescription,
    currentPosition: experienceData?.currentPosition,
  };

  useEffect(() => {
    if (editing) {
      experienceForm.setValues(editFormValues);
    }
    // eslint-disable-next-line
  }, [experienceId]);

  const linksDataFilteredByStripAndExperience = linksData
    ?.filter((item) => item.strip === STRIP)
    ?.filter((item) => item.experienceType === "Experience")
    .sort((a, b) => a.urlPosition - b.urlPosition);

  const highestUrlPosition = linksDataFilteredByStripAndExperience?.reduce(
    (max, linkData) => {
      return Math.max(max, linkData.urlPosition);
    },
    0
  );

  const isFirstEntry = linksDataFilteredByStripAndExperience?.length === 0;
  const nextUrlPosition = isFirstEntry ? 0 : highestUrlPosition + 1;

  const handleSubmit = async (e) => {
    e.preventDefault();
    createLinkMutation.mutate({
      userId: destinationUserId,
      id: editing ? experienceId : null,
      strip: "LinkedIn",
      experienceType: "Experience",
      jobTitle: experienceForm.values.jobTitle,
      company: experienceForm.values.company,
      coverImageUrl: experienceForm.values.coverImageUrl,
      url: experienceForm.values.url,
      urlPosition: editing ? experienceData?.urlPosition : nextUrlPosition, //If it's an edit, urlPosition should not change.,
      location: experienceForm.values.location,
      jobDescription: experienceForm.values.description,
      startMonth: experienceForm.values.startMonth,
      startYear: experienceForm.values.startYear,
      endMonth: experienceForm.values.endMonth,
      endYear: experienceForm.values.endYear,
      currentPosition: experienceForm.values.currentPosition,
    });
  };

  function successSubmit() {
    setOpened(false);
    // experienceForm.reset(); //Now, any NEW adds will always start blank. BUT but but...we want the values to appear if editing....
    setEditing(false);
  }

  if (!linksData) {
    return <LoadingOverlay />;
  } else {
    return (
      <>
        <Button
          fullWidth
          onClick={() => {
            experienceForm.reset();
            setOpened((o) => !o);
          }}
        >
          Add Experience
        </Button>
        <Modal
          onClose={() => {
            setOpened(false);
            setEditing(false);
            experienceForm.setValues(editFormValues);
          }}
          title={<Text>Add experience</Text>}
          opened={opened}
        >
          <LoadingOverlay visible={createLinkMutation.isLoading} />
          <form onSubmit={handleSubmit}>
            <TextInput
              data-autofocus
              label="Title"
              placeholder="E.g. Product Manager"
              mb="sm"
              {...experienceForm.getInputProps("jobTitle")}
            />
            <CompaniesSearchBar
              form={experienceForm}
              experienceType="Experience"
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexGrow: 1,
                marginBottom: "12px",
                gap: "12px",
              }}
            >
              <Image
                width={55}
                height={55}
                radius="sm"
                withPlaceholder
                placeholder={<Image src="/linkedin-not-found.svg" />}
                src={experienceForm.values.coverImageUrl}
              />
              <TextInput
                label="Image"
                placeholder="https://hooli.com/logo.png"
                form={experienceForm}
                styles={{
                  root: {
                    fontSize: "16px",
                    flexGrow: "1 !important",
                  },
                }}
                {...experienceForm.getInputProps("coverImageUrl")}
              />
            </div>
            <TextInput
              label="URL"
              placeholder="https://hooli.com"
              mb="sm"
              {...experienceForm.getInputProps("url")}
            />
            <TextInput
              label="Location"
              placeholder="Philadelphia, PA"
              mb="sm"
              {...experienceForm.getInputProps("location")}
            />
            <Checkbox
              label="I am currently working in this role"
              mb="sm"
              {...experienceForm.getInputProps("currentPosition", {
                type: "checkbox",
              })}
            />
            <Group noWrap>
              <Select
                label="Start date"
                placeholder="Month"
                data={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                mb="sm"
                {...experienceForm.getInputProps("startMonth")}
              />
              <Select
                label=" "
                placeholder="Year"
                data={yearsArray}
                mb="sm"
                {...experienceForm.getInputProps("startYear")}
              />
            </Group>
            {!experienceForm.values.currentPosition && (
              <Group noWrap>
                <Select
                  label="End date"
                  placeholder="Month"
                  data={[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ]}
                  mb="sm"
                  {...experienceForm.getInputProps("endMonth")}
                />
                <Select
                  label=" "
                  placeholder="Year"
                  data={yearsArray}
                  mb="sm"
                  {...experienceForm.getInputProps("endYear")}
                />
              </Group>
            )}
            <Textarea
              autosize
              minRows={2}
              label="Description"
              // placeholder="AI Generated description based on Title & Company"
              mb="sm"
              {...experienceForm.getInputProps("description")}
            />
            <Button
              type="submit"
              fullWidth
              disabled={createLinkMutation.isLoading}
            >
              Save
            </Button>
          </form>
        </Modal>
      </>
    );
  }
}
