import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "./Strip";
import { useLinksQuery, useProfileQuery } from "../queries/queries";
import { faGoodreads } from "@fortawesome/free-brands-svg-icons";
import { GoodreadsCard } from "./GoodreadsCard";
import { StripSkeleton } from "./StripSkeleton";
import { useParams } from "react-router-dom";

export function BooksStrip() {
  const STRIP = "Books";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();
  const { username } = useParams();

  const { data: profileData } = useProfileQuery(username);

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  const navCheck = linksDataByStrip?.length > 4 ? "scroll-tall" : "scroll";

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="books"
        nav={navCheck}
        titleColor="#372213"
        gradientValues={["#D6D0C4"]}
        title="Bookshelf"
        icon={{ type: "fa", icon: faGoodreads }}
        iconColor="#372213"
      >
        {linksDataByStrip?.map((link, index) => {
          return (
            <GoodreadsCard
              key={index}
              image={link.coverImageUrl}
              title={link.title}
              authorName={link.authorName}
              userRating={link.userRating}
              amazonAffiliateTag={profileData?.amazonAffiliateTag}
            />
          );
        })}
      </Strip>
    );
  }
}
