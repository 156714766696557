import {
  Container,
  Flex,
  Group,
  Paper,
  ScrollArea,
  TextInput,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import {
  IconChevronLeft,
  IconChevronRight,
  IconReload,
} from "@tabler/icons-react";

export function ComputerScreen({ children, url }) {
  const isLargerThanSm = useMediaQuery("(min-width: 48em)");

  return (
    <div>
      <Container p={0} maw={1200}>
        <Paper
          withBorder
          pb="sm"
          radius="lg"
          maw={1200}
          mah={700}
          sx={{ backgroundColor: "#F8FAFC" }}
        >
          <Group
            align="center"
            position="apart"
            spacing="xs"
            noWrap
            sx={{
              backgroundColor: "#EDEFF6",
              borderRadius: "16px 16px 0px 0px",
              borderBottom: "1px solid #DBDDE3",
            }}
            py="sm"
            px="md"
            mb="xs"
          >
            <Flex align="center">
              <div className="base-circle circle-red"></div>
              <div className="base-circle circle-yellow"></div>
              <div className="base-circle circle-green"></div>

              {isLargerThanSm && (
                <Group align="center" spacing={4} ml="sm">
                  <IconChevronLeft size={20} stroke={1.5} color="gray" />
                  <IconChevronRight size={20} stroke={1.5} color="gray" />
                  <IconReload size={20} stroke={1.5} color="gray" />
                </Group>
              )}
            </Flex>
            <TextInput
              value={url}
              radius="md"
              disabled
              styles={{
                root: {
                  fontSize: "16px",
                  flexGrow: "1 !important",
                },
              }}
            />
            {/* This is needed */}
            <Group></Group>
          </Group>
          <ScrollArea mah={600}>{children}</ScrollArea>
        </Paper>
      </Container>
    </div>
  );
}
