import { useEffect } from "react";
import { useListState } from "@mantine/hooks";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import {
  useLinksQuery,
  // useCreateLinkMutation,
  useUpdateUrlPositionsMutation,
} from "../queries/queries";

export function DndList({
  strip,
  experienceType,
  children,
  reverseOrder,
  hideProfileLink,
}) {
  const { data: linksData } = useLinksQuery();

  const linksDataFilteredByStrip = linksData
    ?.filter(
      (item) =>
        item.strip === strip &&
        (!experienceType || item.experienceType === experienceType) &&
        (!hideProfileLink || !item.isProfileUrl) // Add this line to filter out isProfileUrl links
    )
    .sort((a, b) =>
      reverseOrder
        ? b.urlPosition - a.urlPosition
        : a.urlPosition - b.urlPosition
    );

  const linksDataFilteredByStripLength =
    linksDataFilteredByStrip?.length.toString();

  const linkValues = linksDataFilteredByStrip
    ? linksDataFilteredByStrip.flatMap((item) =>
        Object.values(item).map((value) => JSON.stringify(value))
      )
    : [];

  const hashLinkValues = JSON.stringify(linkValues);

  const [state, handlers] = useListState(linksDataFilteredByStrip);

  const updateUrlPositionMutation = useUpdateUrlPositionsMutation();

  useEffect(() => {
    handlers.setState((prevState) => {
      // Perform a shallow comparison for arrays of objects
      const isDifferent = prevState?.length !== linksDataFilteredByStripLength;

      // console.log("Added Url detected");
      return isDifferent ? linksDataFilteredByStrip : prevState;
    });
    // eslint-disable-next-line
  }, [linksDataFilteredByStripLength, hashLinkValues]);

  function handleDragEnd(destination, source, callback) {
    if (!destination) return;

    const newState = Array.from(state);
    const [removed] = newState.splice(source.index, 1);
    newState.splice(destination.index, 0, removed);
    handlers.setState(newState);

    const updatedUrlPositions = newState?.map((item, index) => {
      return {
        id: item.id,
        url: item.url,
        urlPosition: index,
      };
    });

    if (callback) {
      callback(updatedUrlPositions);
    }
  }

  function updateUrl(newState) {
    updateUrlPositionMutation.mutate(newState);
  }

  const items = state?.map((item, index) => {
    return (
      <Draggable
        key={`${item.url}-${item.id}`}
        index={index}
        draggableId={`${item.url}-${item.id}`}
      >
        {(provided, snapshot) =>
          children({
            item,
            provided,
            snapshot,
          })
        }
      </Draggable>
    );
  });

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        handleDragEnd(destination, source, updateUrl);
      }}
    >
      <StrictModeDroppable droppableId="dnd-list" direction="vertical">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {items}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
}
