import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "../components/Strip";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";
import { WebsiteCard } from "./WebsiteCard";

export function FavoritesStrip() {
  const STRIP = "Favorites";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="favorites"
        nav="scroll-tall"
        gradientValues={["#516BFA", "#009BFA"]}
        title="Favorites"
        icon={{ type: "emoji", icon: "⭐" }}
        iconColor="white"
      >
        {linksDataByStrip?.map((item, index) => {
          return (
            <WebsiteCard
              key={index}
              url={item.url}
              coverImageUrl={item.coverImageUrl}
              imageFit={item.imageFit}
              favicon={item.favicon}
              title={item.title}
              description={item.description}
              artistName={item.artistName}
              buttonColor={item.buttonColor}
              buttonText={item.buttonText}
            />
          );
        })}
      </Strip>
    );
  }
}
