import { MultiSelect } from "@mantine/core";
import { useProfileQuery, useProfileMutation } from "../queries/queries";
import { useState } from "react";
import { languageColors } from "./GitHubLanguageIcon";
import { useParams } from "react-router-dom";

export function LanguagesMultiSelect() {
  const { username } = useParams();
  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useProfileQuery(username);
  const defaultProgrammingLanguages = profileData?.programmingLanguages;

  const [languageValues, setLanguageValues] = useState(
    defaultProgrammingLanguages
  );

  const profileMutation = useProfileMutation();

  function handleLanugages(values) {
    profileMutation.mutate({
      username: username,
      updates: {
        programmingLanguages: values,
      },
    });
    setLanguageValues(values);
  }

  if (profileIsLoading) {
    return <></>;
  } else if (profileIsError) {
    return <p>Error loading links</p>;
  } else {
    return (
      <MultiSelect
        data={Object.keys(languageColors)}
        value={languageValues}
        onChange={handleLanugages}
        label="Your favorite frameworks/libraries"
        placeholder="Pick all that you like"
        searchable
        nothingFound="Nothing found"
      />
    );
  }
}
