import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { Image } from "@mantine/core";

export function NavIconStatic({ icon, iconColor }) {
  return (
    <div>
      {icon.type === "fa" ? (
        icon.iconBackground ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "28px",
            }}
          >
            <FontAwesomeIcon
              icon={faSquare}
              color="white"
              style={{ fontSize: "22px" }}
            />
            <FontAwesomeIcon
              icon={icon.icon}
              color={iconColor}
              // size={iconSize}
              style={{
                position: "absolute",
                fontSize: "28px",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "28px",
              // height: "60px",
            }}
          >
            <FontAwesomeIcon
              icon={icon.icon}
              color={iconColor}
              style={{ fontSize: "28px" }}
            />
          </div>
        )
      ) : icon.type === "emoji" ? (
        <span className="emoji-icon-sm">{icon.icon}</span>
      ) : (
        icon.type === "image" && (
          <Image
            src={icon.icon}
            width={icon.width || 28}
            height={icon.height || 28}
            style={{
              backgroundColor: icon.iconBackground && "#FFFFFF",
              borderRadius: "500px",
            }}
          />
        )
      )}
    </div>
  );
}
