import "../App.css";
import React from "react";
import { useProfileQuery, useStripsQuery } from "../queries/queries";
import { AboutStrip } from "./AboutStrip";
import { AirbnbStrip } from "./AirbnbStrip";
import { AmazonStrip } from "./AmazonStrip";
import { GitHubStrip } from "./GitHubStrip";
import { LinkedInStrip } from "./LinkedInStrip";
import { BooksStrip } from "./BooksStrip";
import { SpotifyStrip } from "./SpotifyStrip";
import { TwitterStrip } from "./TwitterStrip";
import { YouTubeStrip } from "./YouTubeStrip";
import { LoadingOverlay, Text } from "@mantine/core";
import { WritingStrip } from "./WritingStrip";
import { MoviesStrip } from "./MoviesStrip";
import { EventsStrip } from "./EventsStrip";
import { PhotosStrip } from "./PhotosStrip";
import { ProjectsStrip } from "./ProjectsStrip";
import { SlidesStrip } from "./SlidesStrip";
import { ResumeStrip } from "./ResumeStrip";
import { InstagramStrip } from "./InstagramStrip";
import { MediumStrip } from "./MediumStrip";
import { TikTokStrip } from "./TikTokStrip";
import { FacebookStrip } from "./FacebookStrip";
import { AppleMusicStrip } from "./AppleMusicStrip";
import { ApplePodcastsStrip } from "./ApplePodcastsStrip";
import { FavoritesStrip } from "./FavoritesStrip";
import { NewsletterStrip } from "./NewsletterStrip";
import { SubstackStrip, SubstackStrip2 } from "./SubstackStrip";
import { BeehiivStrip, BeehiivStrip2 } from "./BeehiivStrip";
import { WebsiteStrip } from "./WebsiteStrip";
import { useUser } from "@clerk/clerk-react";
import { clerkSuperAdminId } from "../App";
import { StravaStrip } from "./StravaStrip";
import { EventbriteStrip } from "./EventbriteStrip";
// import { WebsiteCollectionStrip } from "./WebsiteCollectionStrip";

const stripComponentsMap = {
  About: <AboutStrip />,
  "Apple-Music": <AppleMusicStrip />,
  "Apple-Podcasts": <ApplePodcastsStrip />,
  Books: <BooksStrip />,
  Favorites: <FavoritesStrip />,
  GitHub: <GitHubStrip />,
  LinkedIn: <LinkedInStrip />,
  Spotify: <SpotifyStrip />,
  Twitter: <TwitterStrip />,
  Instagram: <InstagramStrip />,
  Facebook: <FacebookStrip />,
  TikTok: <TikTokStrip />,
  Strava: <StravaStrip />,
  Newsletter: <NewsletterStrip />,
  Substack: <SubstackStrip />,
  Substack2: <SubstackStrip2 />,
  Beehiiv: <BeehiivStrip />,
  Beehiiv2: <BeehiivStrip2 />,
  YouTube: <YouTubeStrip />,
  Writing: <WritingStrip />,
  Movies: <MoviesStrip />,
  Events: <EventsStrip />,
  // Contact: <ContactStrip />,
  Photos: <PhotosStrip />,
  Medium: <MediumStrip />,
  Projects: <ProjectsStrip />,
  Amazon: <AmazonStrip />,
  Airbnb: <AirbnbStrip />,
  Slides: <SlidesStrip />,
  Resume: <ResumeStrip />,
  Eventbrite: <EventbriteStrip />,
  // "Website-Collection": <WebsiteCollectionStrip />,
};

// Dynamic component rendering function
const renderStripComponent = (stripName) => {
  const stripComponent = stripComponentsMap[stripName];

  // Render the corresponding component or a fallback if not found
  return stripComponent || <WebsiteStrip strip={stripName} />;
};

export const FREE_STRIP_LIMIT = 4;

export function StripRenderer() {
  const {
    data: stripsData,
    isLoading: stripsIsLoading,
    isError: stripsIsError,
  } = useStripsQuery();

  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useProfileQuery();

  const visibleStrips = stripsData?.filter((stripData) => stripData.visible);

  const freeVisibleStrips = visibleStrips?.slice(0, FREE_STRIP_LIMIT);

  const { user } = useUser();
  const isAdmin = user?.id === profileData?.clerkUserId;

  const isSuperAdmin = user?.id === clerkSuperAdminId;

  const stripsToShow =
    profileData?.stripeSubscriptionStatus === "active" ||
    isAdmin ||
    isSuperAdmin
      ? visibleStrips
      : freeVisibleStrips;

  //If they're not set to "active", then just return the first X visible strips!!

  if (stripsIsLoading || profileIsLoading) {
    return <LoadingOverlay />;
  } else if (stripsIsError || profileIsError) {
    console.log("Error loading strips");
    return <Text>Error loading strips</Text>;
  } else {
    return stripsToShow.map((stripData) => {
      return (
        <React.Fragment key={stripData.id}>
          <div style={{ maxWidth: "850px", margin: "auto" }}>
            {renderStripComponent(stripData.strip)}
          </div>
        </React.Fragment>
      );
    });
  }
}
