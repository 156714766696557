import React, { lazy, Suspense } from "react";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import "../App.css";
import { useLinksQuery } from "../queries/queries";
import { Skeleton, Text } from "@mantine/core";
import { SpotifyFollowButton } from "./CTAButtons";
import { Strip } from "./Strip";
import { ErrorBoundary } from "@sentry/react";

const SpotifyCard = lazy(() => import("../components/SpotifyCard"));

export function SpotifyStrip() {
  const STRIP = "Spotify";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  const navCheck = linksDataByStrip?.length > 4 ? "scroll-tall" : "scroll";
  const largeCheck = linksDataByStrip?.length > 4 ? false : true;

  return (
    <Strip
      id="spotify"
      nav={navCheck}
      gradientValues={["#121212"]}
      // followButton={<SpotifyFollowButton />}
      title="Spotify"
      icon={{ type: "fa", icon: faSpotify }}
      iconColor="#45DB74"
    >
      {linksIsLoading ? (
        Array(4)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              key={index}
              visible={linksIsLoading}
              width={300}
              height={152}
            />
          ))
      ) : linksIsError ? (
        <Text>Error loading link</Text>
      ) : (
        <>
          {linksDataByStrip?.map((link, index) => (
            <ErrorBoundary key={link.id}>
              <Suspense fallback={<Skeleton width={300} height={152} />}>
                <SpotifyCard
                  key={index}
                  link={link.url}
                  image={link.coverImageUrl}
                  title={link.albumName}
                  episodeTitle={link.episodeTitle}
                  author={link.artistName}
                  large={largeCheck}
                />
              </Suspense>
            </ErrorBoundary>
          ))}
        </>
      )}
    </Strip>
  );
}
// }
