import React from "react";
import { Card, Flex, Image, Text } from "@mantine/core";

export function LinkedInCard({
  title,
  url,
  coverImageUrl,
  company,
  location,
  startMonth,
  startYear,
  endMonth,
  endYear,
}) {
  const formattedDate = (month, year) => {
    if (month && year) {
      return `${month.substring(0, 3)} ${year}`;
    } else if (year) {
      return `${year}`;
    } else {
      return "Present";
    }
  };

  const monthStringToNumber = (month) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.indexOf(month) + 1;
  };

  const tenure = (startMonth, startYear, endMonth, endYear) => {
    if (!endMonth && !endYear) {
      const currentDate = new Date();
      endMonth = currentDate.getMonth() + 1;
      endYear = currentDate.getFullYear();
    } else {
      endMonth = monthStringToNumber(endMonth);
    }

    const startInMonths =
      startYear * 12 + (startMonth ? monthStringToNumber(startMonth) - 1 : 0); // Only subtract 1 if startMonth is provided.
    const endInMonths =
      endYear * 12 + (endMonth ? endMonth - (startMonth ? 0 : 1) : 0); // Subtract 1 only if startMonth is not provided.

    const diffInMonths = endInMonths - startInMonths;
    const diffInYears = Math.floor(diffInMonths / 12);

    const remainingMonths = diffInMonths % 12;
    const yearsString = diffInYears === 1 ? "1 yr" : `${diffInYears} yrs`;
    const monthsString =
      remainingMonths === 0
        ? ""
        : remainingMonths === 1
        ? "1 mo"
        : `${remainingMonths} mos`;

    const result =
      diffInYears > 0 ? `${yearsString} ${monthsString}` : `${monthsString}`;
    return result;
  };

  return (
    <div className="card-md">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ paddingRight: "8px" }}>
            <Image
              src={
                coverImageUrl
                  ? coverImageUrl
                  : `https://logo.clearbit.com/${url}`
              }
              height={72}
              width={72}
              alt={company}
              radius="md"
              component="a"
              href={url === "https://tapistr.ee" ? null : `https://${url}`}
              rel="noopener noreferrer"
              target="_blank"
              withPlaceholder
              placeholder={<Image src="/linkedin-not-found.svg" />}
              imageProps={{
                loading: "lazy",
              }}
            />
          </div>
          <Flex mih="76px" direction="column" justify="center">
            <Text
              size="sm"
              lineClamp={1}
              weight={500}
              style={{ lineHeight: "1.2em" }}
            >
              {title}
            </Text>
            <Text size="sm" color="dimmed" lineClamp={1}>
              {company}
            </Text>
            {startYear && (
              <Text size="xs" color="dimmed" lineClamp={1}>
                {formattedDate(startMonth, startYear) +
                  " - " +
                  formattedDate(endMonth, endYear) +
                  " · " +
                  tenure(startMonth, startYear, endMonth, endYear)}
              </Text>
            )}
            <Text size="xs" color="dimmed" lineClamp={1}>
              {location}
            </Text>
          </Flex>
        </div>
      </Card>
    </div>
  );
}
