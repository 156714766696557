import {
  Center,
  Container,
  Flex,
  Group,
  Image,
  Indicator,
  SimpleGrid,
  Stack,
  Title,
  Tooltip,
} from "@mantine/core";

export function MyUserButton({ username, profPic, height, width }) {
  return (
    <div>
      <a href={`/${username}`}>
        <div
          style={{
            width: height,
            height: width,
            borderRadius: "50%",
            border: "2px solid white",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0px", // Adjusted to 2px
            boxSizing: "border-box", // Added this
            margin: "5px",
          }}
        >
          <div
            style={{
              width: width - 2, // Adjusted to 64px
              height: height - 2,
              borderRadius: "50%",
              backgroundImage: `url(${profPic})`,
              backgroundSize: "cover",
              backgroundPosition: "50% 50%",
              boxSizing: "border-box", // Added this
            }}
          ></div>
        </div>
      </a>
    </div>
  );
}

export const FEATURED_USERS_ARRAY = [
  {
    username: "aubrey",
    profPic:
      "https://images.clerk.dev/uploaded/img_2WBcCb62CluF4e4pC6wUWMs0Nuz",
  },

  {
    username: "heydannymiranda",
    profPic:
      "https://images.clerk.dev/uploaded/img_2Yn1sz0B05xdv0NfqajVUiqK395",
  },
  {
    username: "businessbarista",
    profPic:
      "https://images.clerk.dev/uploaded/img_2XGY4nelZAZAuNdTUEt3S85Kk4V",
  },
  {
    username: "jamesbrackiniv",
    profPic:
      "https://images.clerk.dev/oauth_google/img_2a2oSz0fKA3khZcY04mxNRT3qBi",
  },
  {
    username: "watson",
    profPic:
      "https://images.clerk.dev/uploaded/img_2XXjWxLayAVAWaSHGtIG2lR4lfp",
  },

  {
    username: "heatherparady",
    profPic:
      "https://images.clerk.dev/uploaded/img_2bwfijNarmRwehWzzNVZdIWlyTZ",
  },
  {
    username: "lars",
    profPic:
      "https://images.clerk.dev/uploaded/img_2WGQHAzC7oIDWZezMrxVhLjnvao  ",
  },
  {
    username: "LeeBThreadgill",
    profPic:
      "https://images.clerk.dev/uploaded/img_2W2oTEiRhf9XDPxcATrvJjaYI3u",
  },
  {
    username: "tdesfrancs",
    profPic:
      "https://images.clerk.dev/oauth_google/img_2XUaK7o6XGzd3VyRZfTcYZxr3DP",
  },
  {
    username: "tylerfuller",
    profPic:
      "https://images.clerk.dev/uploaded/img_2XUZp8lDwYYV2O7I2FXe6C6i5Wy",
  },
];

export function FeaturedProfiles() {
  return (
    <Stack
      p="sm"
      sx={{ backgroundColor: "#F6F9FC", scrollMarginTop: "50px" }}
      py={44}
    >
      <Container mt={16} p={0} mb={20}>
        <Title order={1} color="#444" ta="center" mb="xl">
          <span className="gradient-text">Explore&nbsp;</span>
          more profiles
        </Title>
        <SimpleGrid
          cols={5}
          breakpoints={[
            { maxWidth: "md", cols: 5, spacing: 2 },
            { maxWidth: "sm", cols: 4, spacing: 2 },
            { maxWidth: "xs", cols: 4, spacing: 2 },
          ]}
        >
          {FEATURED_USERS_ARRAY.map((user) => (
            <Tooltip label={user.username} offset={-3} key={user.username}>
              <div>
                <Indicator
                  position="bottom-end"
                  color="transparent"
                  size={12}
                  offset={17}
                  label={<Image height={24} width={24} src="/verified.png" />}
                >
                  <MyUserButton
                    username={user.username}
                    profPic={user.profPic}
                    height={72}
                    width={72}
                  />
                </Indicator>
              </div>
            </Tooltip>
          ))}
        </SimpleGrid>
      </Container>
      {/* <Center>
        <UsernameInputHome />
      </Center> */}
    </Stack>
  );
}
