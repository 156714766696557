import "../App.css";
import { Image, Text } from "@mantine/core";
import { useLinksQuery } from "../queries/queries";
import { Strip } from "./Strip";
import { FacebookEmbed } from "react-social-media-embed";
import { StripSkeleton } from "./StripSkeleton";
import { ErrorBoundary } from "@sentry/react";
import { useEffect } from "react";

export function FacebookPageEmbed({ url }) {
  useEffect(() => {
    // Function to load the Facebook SDK
    const loadFacebookSDK = () => {
      if (window.FB) {
        // SDK already loaded, reinitialize
        window.FB.XFBML.parse();
      } else {
        window.fbAsyncInit = function () {
          window.FB.init({
            xfbml: true,
            version: "v18.0",
          });
        };

        // Load the SDK
        (function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return;
          js = d.createElement(s);
          js.id = id;
          js.src = "https://connect.facebook.net/en_US/sdk.js";
          fjs.parentNode.insertBefore(js, fjs);
        })(document, "script", "facebook-jssdk");
      }
    };

    loadFacebookSDK();
  }, []);

  return (
    <div style={{ width: "500px" }}>
      <div id="fb-root"></div>
      <div
        className="fb-page"
        data-href={url}
        data-tabs="timeline"
        data-width=""
        data-height=""
        data-small-header="false"
        data-adapt-container-width="true"
        data-hide-cover="false"
        data-show-facepile="true"
      >
        <blockquote cite={url} className="fb-xfbml-parse-ignore">
          <a href={url}>Facebook Page</a>
        </blockquote>
      </div>
    </div>
  );
}

export function FacebookStrip() {
  const STRIP = "Facebook";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="facebook" //Lowercase
        nav="scroll-tall"
        gradientValues={["#FFFFFF"]}
        title="Facebook"
        icon={{
          type: "image",
          icon: (
            <Image src="/Facebook-small.png" height={36} width={36} mt={-2} />
          ),
        }}
        titleColor="#0A66FF"
      >
        {linksDataByStrip.map((link) => {
          const isPostFormat = /facebook\.com\/.+\/posts\/.+/.test(link.url);

          return (
            <ErrorBoundary key={link.id}>
              <div style={{ backgroundColor: "#FFFFFF" }}>
                {isPostFormat ? (
                  <FacebookEmbed
                    placeholderProps={{
                      style: {
                        height: 550,
                        width: 400,
                      },
                    }}
                    width={400}
                    url={link.url}
                  />
                ) : (
                  <FacebookPageEmbed url={link.url} />
                )}
              </div>
            </ErrorBoundary>
          );
        })}
      </Strip>
    );
  }
}
