import { Button, Flex, TextInput, Modal } from "@mantine/core";
import { useState } from "react";
import { useProfileMutation } from "../queries/queries";
import { useParams } from "react-router-dom";

export function AmazonAffiliateModal({ opened, setOpened }) {
  const { username } = useParams();
  const profileMutation = useProfileMutation();
  const [saved, setSaved] = useState(false);
  const [affiliateTag, setAffiliateTag] = useState("");

  function editInput() {
    setSaved(false);
  }
  //TBU. Needs to be a form.
  const handleSave = (affiliateTag) => {
    profileMutation.mutate({
      username: username,
      updates: {
        amazonAffiliateTag: affiliateTag,
      },
    });
    setOpened(false);
  };

  return (
    <Modal
      opened={opened}
      onClose={() => setOpened(false)}
      closeOnClickOutside
      padding="xs"
      title="Collect commissions on book sales"
    >
      <Flex align="end" py={5}>
        <TextInput
          label="Amazon Affiliate/Associates ID"
          placeholder="yourtag-20"
          disabled={saved}
          mr={3}
          value={affiliateTag}
          onChange={(event) => setAffiliateTag(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSave(affiliateTag);
            }
          }}
          styles={{
            root: {
              fontSize: "16px",
              flexGrow: "1 !important",
            },
            input: {
              fontSize: "16px",
              height: "20px",
              lineHeight: "20px",
            },
          }}
        />
        <>
          {saved ? (
            <Button
              onClick={() => {
                editInput();
              }}
              style={{ marginRight: "8px" }}
            >
              Edit
            </Button>
          ) : (
            <Button
              color="blue"
              onClick={() => handleSave(affiliateTag)}
              style={{ padding: "0px 16px", marginRight: "8px" }}
            >
              Save
            </Button>
          )}
        </>
      </Flex>
    </Modal>
  );
}
