import "../App.css";
import { Text } from "@mantine/core";
import { useLinksQuery } from "../queries/queries";
import { Image } from "@mantine/core";
import { Strip } from "./Strip";
import { TikTokEmbed } from "react-social-media-embed";
import { StripSkeleton } from "./StripSkeleton";
import { ErrorBoundary } from "@sentry/react";
import { TikTokProfileEmbed } from "./TikTokProfileEmbed";

export function TikTokStrip() {
  const STRIP = "TikTok";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  // Regular expression for matching a TikTok video URL including query parameters
  const videoUrlRegex = /^https:\/\/www\.tiktok\.com\/@\w+\/video\/\d+(\?.*)?$/;
  const isVideoUrl = (url) => videoUrlRegex.test(url);

  // Regular expression for matching a TikTok profile URL
  // const profileUrlRegex = /^https:\/\/www\.tiktok\.com\/@\w+$/;
  // const isProfileUrl = (url) => profileUrlRegex.test(url);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="tiktok" //Lowercase
        nav={linksDataByStrip?.length > 1 ? "scroll" : "full"}
        gradientValues={["#FFFFFF"]}
        title="TikTok"
        titleColor="#000000"
        icon={{
          type: "image",
          icon: <Image src="/TikTok.png" height={36} width={36} />,
        }}
        iconColor="white"
        // iconLink="YOUR TikTok PROFILE...."TBU
      >
        {linksDataByStrip.map((link) => {
          return (
            <ErrorBoundary key={link.id}>
              {isVideoUrl(link.url) ? (
                <TikTokEmbed
                  placeholderProps={{
                    style: {
                      height: 550,
                      width: 324,
                    },
                  }}
                  url={link.url}
                  // width="100%"
                  style={{ width: 328, marginRight: "16px" }}
                />
              ) : (
                <TikTokProfileEmbed url={link.url} />
              )}
            </ErrorBoundary>
          );
        })}
      </Strip>
    );
  }
}
