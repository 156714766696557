import React, { lazy, Suspense } from "react";
import { faSpotify } from "@fortawesome/free-brands-svg-icons";
import { Skeleton } from "@mantine/core";
import { SpotifyFollowButton } from "../CTAButtons";
import { Strip } from "../Strip";
import { ErrorBoundary } from "@sentry/react";

const SpotifyCard = lazy(() => import("../SpotifyCard"));

export function DummySpotifyStrip() {
  const linksDataByStrip = [
    { url: "https://open.spotify.com/playlist/5s8443tfYUq3LLARJwGeYP" },
    { url: "https://open.spotify.com/show/2MAi0BvDc6GTFvKFPXnkCL" },
  ];

  const navCheck = linksDataByStrip?.length > 4 ? "scroll-tall" : "scroll";
  const largeCheck = linksDataByStrip?.length > 4 ? false : true;

  return (
    <Strip
      id="spotify"
      nav={navCheck}
      gradientValues={["#121212"]}
      followButton={<SpotifyFollowButton />}
      title="Spotify"
      icon={{ type: "fa", icon: faSpotify }}
      iconColor="#45DB74"
    >
      {linksDataByStrip?.map((link, index) => (
        <ErrorBoundary key={index}>
          <Suspense fallback={<Skeleton width={300} height={152} />}>
            <SpotifyCard link={link.url} large={largeCheck} />
          </Suspense>
        </ErrorBoundary>
      ))}
    </Strip>
  );
}
