import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "../components/Strip";
import { useLinksQuery } from "../queries/queries";
import { ProjectCard } from "./ProjectCard";
import { StripSkeleton } from "./StripSkeleton";

export function ProjectsStrip() {
  const STRIP = "Projects";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="Projects"
        nav="scroll"
        gradientValues={["#228BE6"]}
        title="Projects"
        icon={{ type: "emoji", icon: "🛠️" }}
        // iconColor="#24292F"
        iconColor="white"
        // iconLink="https://github.com/aubreythreadgill/"
      >
        {linksDataByStrip?.map((item, index) => {
          return (
            <ProjectCard
              key={index}
              images={item.images}
              backgroundColor={item.backgroundColor}
              title={item.title}
              description={item.description}
              status={item.status}
              link={item.link}
              callToAction={item.callToAction}
            />
          );
        })}
      </Strip>
    );
  }
}
