import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { Image } from "@mantine/core";

export function NavIcon({ icon, iconColor, hashRoute, isActive, username }) {
  return (
    <div
      className={isActive ? "active-icon" : undefined}
      style={{ border: "none" }}
    >
      <Link
        to={`/${username}/#${hashRoute}`}
        style={{
          textDecoration: "none",
          color: "inherit",
          padding: "0px",
          margin: "0px",
          border: "none",
        }}
      >
        {icon.type === "fa" ? (
          icon.iconBackground ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "28px",
              }}
            >
              <FontAwesomeIcon
                icon={faSquare}
                color="white"
                style={{ fontSize: "22px" }}
              />
              <FontAwesomeIcon
                icon={icon.icon}
                color={iconColor}
                // size={iconSize}
                style={{
                  position: "absolute",
                  fontSize: "28px",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
                width: "28px",
              }}
            >
              <FontAwesomeIcon
                icon={icon.icon}
                color={iconColor}
                style={{ fontSize: "28px" }}
              />
            </div>
          )
        ) : icon.type === "emoji" ? (
          <span className="emoji-icon-sm">{icon.icon}</span>
        ) : icon.type === "image" && icon.icon ? (
          icon.iconBackgroundColor ? (
            <div
              style={{
                backgroundColor: icon.iconBackgroundColor,
                padding: icon.iconBackgroundColor && "2px", // Adjust the padding to control the size of the white background
                borderRadius: icon.iconBackgroundColor && "6px", // To maintain a circular shape if the icon is circular
              }}
            >
              <Image
                src={icon.icon}
                radius="sm"
                width={icon.width || 22}
                height={icon.height || 22}
                withPlaceholder
                placeholder={<></>}
                style={{
                  backgroundColor: icon.iconBackground && "#FFFFFF",
                  borderRadius: "50%",
                }}
              />
            </div>
          ) : (
            <Image
              src={icon.icon}
              radius="sm"
              width={icon.width || 28}
              height={icon.height || 28}
              withPlaceholder
              placeholder={<></>}
              style={{
                backgroundColor: icon.iconBackground && "#FFFFFF",
                borderRadius: "50%",
              }}
            />
          )
        ) : (
          <></>
        )}
      </Link>
    </div>
  );
}
