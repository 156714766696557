import { Container, Stack, Title } from "@mantine/core";
import { Tweet } from "react-tweet";

export function SectionInTheWild() {
  return (
    <Stack
      p="sm"
      sx={{
        // backgroundColor: "#F6F9FC",
        backgroundColor: "#EFF6FE",
        scrollMarginTop: "50px",
      }}
      py={44}
    >
      <Container mt={16} p={0} mb={20}>
        <Title order={1} color="#444" ta="center" mb="xl">
          <span className="gradient-text">🎨 in the wild</span>
        </Title>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexWrap: "wrap",
            gap: "15px",
          }}
        >
          <div style={{ maxWidth: "320px" }}>
            <Tweet id="1742948958849089730" />
          </div>

          <div style={{ maxWidth: "320px" }}>
            <Tweet id="1724312630930850005" />
          </div>
        </div>
      </Container>
    </Stack>
  );
}
