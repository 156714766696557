import {
  ActionIcon,
  Affix,
  Button,
  LoadingOverlay,
  Navbar,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { AddStrips } from "../components/AddStrips";
import { UserCard } from "../components/UserCard";
import { useProfileQuery } from "../queries/queries";
import { DndListStrip } from "../components/DndListStrip";
import { StripInputRenderer } from "../components/StripInputRenderer";
import { ErrorBoundary } from "@sentry/react";
import { IconPencil, IconCheck, IconChartBar } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { CheckoutPricing } from "../components/CheckoutPricing";
import { FREE_STRIP_LIMIT } from "../components/StripRenderer";
import { LinktreeConvert } from "../utils/linktreeConvert";

export function Edit() {
  const [collapsed, handler] = useDisclosure(false);

  const { username } = useParams();
  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useProfileQuery(username);

  function EditButton() {
    return (
      <Affix position={{ bottom: "20px", left: "20px" }}>
        <ActionIcon
          color="blue"
          variant="filled"
          radius="xl"
          size="xl"
          shadow="lg"
          onClick={() => handler.toggle()}
          style={{ boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)" }}
        >
          {collapsed ? (
            <IconPencil size="1.5rem" stroke={1.5} />
          ) : (
            <IconCheck size="1.5rem" stroke={2} />
          )}
        </ActionIcon>
      </Affix>
    );
  }

  if (profileIsLoading) {
    return <LoadingOverlay visible={true} />;
  } else if (profileIsError) {
    return <h1>Error loading profile</h1>;
  } else {
    return (
      <>
        <EditButton />
        {collapsed ? (
          <></>
        ) : (
          <Navbar
            p="xs"
            hidden={collapsed}
            hiddenBreakpoint="lg"
            width={{ sm: 500, lg: 500 }}
            sx={{ backgroundColor: "#FAFAFA" }}
          >
            {profileData?.stripeSubscriptionStatus !== "active" && (
              <>
                <CheckoutPricing
                  explainer={`Only your first ${FREE_STRIP_LIMIT} strips are visible. Subscribe to activate more.`}
                  buttonText="Activate"
                />
              </>
            )}
            <ScrollArea offsetScrollbars>
              <Stack spacing="xs" pb="48px">
                <UserCard
                  avatar={profileData?.profPic}
                  firstName={profileData?.firstName}
                  lastName={profileData?.lastName}
                  username={profileData?.username}
                  title={profileData?.title}
                  emoji={profileData?.emoji}
                />

                <Button
                  component="a"
                  href="/i/analytics"
                  fullWidth
                  color="green"
                  radius="md"
                  sx={{ boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)" }}
                >
                  <IconChartBar stroke={1.5} />
                  &nbsp;
                  <Text size="lg">View Analytics</Text>
                </Button>

                <AddStrips />
                <LinktreeConvert />
                <ErrorBoundary>
                  <DndListStrip>
                    {({ item, provided, snapshot }) => {
                      return (
                        <StripInputRenderer
                          key={item.strip}
                          id={item.id}
                          strip={item.strip}
                          provided={provided}
                          snapshot={snapshot}
                        />
                      );
                    }}
                  </DndListStrip>
                </ErrorBoundary>
              </Stack>
            </ScrollArea>
          </Navbar>
        )}
      </>
    );
  }
}
