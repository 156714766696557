import {
  Group,
  ActionIcon,
  Button,
  createStyles,
  Flex,
  Image,
  Menu,
  LoadingOverlay,
  Modal,
  Text,
  Textarea,
  TextInput,
  Paper,
  SegmentedControl,
  ColorInput,
  ColorPicker,
} from "@mantine/core";
import {
  useDeleteLinkMutation,
  useCreateLinkMutation,
  useLinksQuery,
  useUpdateStripColorMutation,
  useProfileQuery,
} from "../queries/queries";
import {
  IconTrash,
  IconGripVertical,
  IconDots,
  IconPencil,
} from "@tabler/icons-react";
import { useState } from "react";
import { useForm } from "@mantine/form";

const useStyles = createStyles((theme) => ({
  item: {},

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  dragHandle: {
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    visibility: "hidden",
  },
}));

export const WebsiteSelectionCard = ({
  websiteId,
  strip,
  stripId,
  url,
  title,
  description,
  buttonText,
  favicon,
  iconBackgroundColor,
  coverImageUrl,
  isNotDraggable,
  provided,
  snapshot,
}) => {
  function successSubmit() {
    setOpened(false);
    // educationForm.reset(); //Now, any NEW adds will always start blank.
  }
  const createLinkMutation = useCreateLinkMutation(successSubmit); //TBU for error handling

  const deleteLinkMutation = useDeleteLinkMutation();

  async function handleDelete(id) {
    deleteLinkMutation.mutate({
      id: id,
    });
  }

  const { classes } = useStyles();

  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  const [opened, setOpened] = useState(false);
  const [color, setColor] = useState("");
  const [buttonColor, setButtonColor] = useState("");

  const { data: linksData } = useLinksQuery();
  const favoritesData = linksData?.find((item) => item.id === websiteId);

  const websiteForm = useForm({
    initialValues: {
      url: favoritesData?.url,
      title: favoritesData?.title,
      coverImageUrl: favoritesData?.coverImageUrl || "",
      imageFit: favoritesData?.imageFit || "",
      favicon: favoritesData?.favicon || "",
      description: favoritesData?.description || "",
      buttonColor: favoritesData?.buttonColor,
      buttonText: favoritesData?.buttonText,
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    createLinkMutation.mutate({
      userId: destinationUserId,
      id: websiteId,
      strip: strip,
      coverImageUrl: websiteForm.values.coverImageUrl,
      imageFit: websiteForm.values.imageFit,
      favicon: websiteForm.values.favicon,
      title: websiteForm.values.title,
      url: websiteForm.values.url,
      description: websiteForm.values.description,
      buttonColor: websiteForm.values.buttonColor,
      buttonText: websiteForm.values.buttonText,
    });
  };

  const updateStripColorMutation = useUpdateStripColorMutation();

  function updateColor(color) {
    updateStripColorMutation.mutate({
      id: stripId,
      data: {
        iconBackgroundColor: color,
      },
    });
  }

  function NavIconExample({ favicon, iconBackgroundColor }) {
    return (
      <>
        {iconBackgroundColor || color ? (
          <>
            <div
              style={{
                backgroundColor: iconBackgroundColor || color,
                padding: (iconBackgroundColor || color) && "2px", // Adjust the padding to control the size of the white background
                borderRadius: (iconBackgroundColor || color) && "6px", // To maintain a circular shape if the icon is circular
                marginTop: "12px",
              }}
            >
              <Image
                src={favicon}
                radius="sm"
                width={36}
                height={36}
                withPlaceholder
                // placeholder={<></>}
              />
            </div>
          </>
        ) : (
          <>
            <Image
              src={favicon}
              radius="sm"
              width={36}
              height={36}
              withPlaceholder
              mt="sm"
              // placeholder={<></>}
            />
          </>
        )}
      </>
    );
  }

  return (
    <div
      className={snapshot?.isDragging ? classes.itemDragging : ""}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      <>
        <Modal
          onClose={() => {
            setOpened(false);
            websiteForm.reset();
          }}
          title={<Text>Edit website</Text>}
          opened={opened}
        >
          <LoadingOverlay visible={createLinkMutation.isLoading} />
          <form onSubmit={handleSubmit}>
            <TextInput
              data-autofocus
              label="URL"
              placeholder="https://ycombinator.com"
              mb="sm"
              {...websiteForm.getInputProps("url")}
            />
            <TextInput
              data-autofocus
              label="Title"
              placeholder="Y Combinator"
              mb="sm"
              {...websiteForm.getInputProps("title")}
            />
            {websiteForm.values.imageFit === "contain" ? (
              <Image
                fit="contain"
                p="md"
                height={188}
                radius="md"
                src={websiteForm.values.coverImageUrl}
                alt={title}
                withPlaceholder
                style={{
                  border: coverImageUrl ? "none" : "1px solid #CED4DA",
                }}
                imageProps={{
                  loading: "lazy",
                }}
              />
            ) : (
              <Image
                p={0}
                height={220}
                radius="md"
                src={websiteForm.values.coverImageUrl}
                alt={title}
                withPlaceholder
                style={{
                  border: coverImageUrl ? "none" : "1px solid #CED4DA",
                }}
                imageProps={{
                  loading: "lazy",
                }}
              />
            )}
            <TextInput
              label="Image"
              placeholder="https://yc-website.s3.us-west-2.amazonaws.com/why-yc/why-y-combinator.jpg"
              mb="sm"
              {...websiteForm.getInputProps("coverImageUrl")}
            />
            <div>
              <Text size="sm" fw={500}>
                Image fit
              </Text>
              <SegmentedControl
                // value={imageFit}
                // onChange={setImageFit}

                mb="sm"
                data={[
                  { label: "Cover", value: "cover" },
                  { label: "Contain", value: "contain" },
                ]}
                {...websiteForm.getInputProps("imageFit")}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexGrow: 1,
                gap: "12px",
              }}
            >
              <NavIconExample
                favicon={websiteForm.values.favicon}
                iconBackgroundColor={iconBackgroundColor}
              />
              <TextInput
                label="Favicon"
                placeholder="https://yc-website.s3.us-west-2.amazonaws.com/why-yc/why-y-combinator.jpg"
                mb="sm"
                styles={{
                  root: {
                    fontSize: "16px",
                    flexGrow: "1 !important",
                  },
                }}
                {...websiteForm.getInputProps("favicon")}
              />
            </div>
            <ColorInput
              placeholder="Pick color"
              label="Change favicon background color"
              my="xs"
              value={color}
              onChange={(newColor) => {
                updateColor(newColor);
                setColor(newColor);
              }}
            />
            <Textarea
              autosize
              minRows={2}
              label="Description"
              // placeholder="AI Generated description based on Title & Company"
              mb="sm"
              {...websiteForm.getInputProps("description")}
            />
            <Text size="sm" weight={500}>
              Button color
            </Text>
            <ColorPicker
              format="hex"
              mb="sm"
              // withPicker={false}
              onChange={(newColor) => {
                setButtonColor(newColor);
              }}
              swatches={[
                "#25262b",
                "#868e96",
                "#fa5252",
                "#e64980",
                "#be4bdb",
                "#7950f2",
                "#4c6ef5",
                "#228be6",
                "#15aabf",
                "#12b886",
                "#40c057",
                "#82c91e",
                "#fab005",
                "#fd7e14",
              ]}
              {...websiteForm.getInputProps("buttonColor")}
            />
            <TextInput
              data-autofocus
              label="Button text"
              placeholder="Visit site"
              mb="sm"
              {...websiteForm.getInputProps("buttonText")}
            />
            <Button
              type="submit"
              fullWidth
              disabled={createLinkMutation.isLoading}
            >
              Save
            </Button>
          </form>
        </Modal>
      </>
      <Paper
        radius="md"
        pl={6}
        pr="xs"
        py="xs"
        mb={8}
        withBorder
        style={{
          backgroundColor: "#F7F8F9",
        }}
      >
        <Group position="apart" spacing="xs" noWrap>
          <Flex align="center">
            {!isNotDraggable && (
              <div
                {...provided?.dragHandleProps}
                className={classes.dragHandle}
              >
                <IconGripVertical
                  size="1.05rem"
                  stroke={1.5}
                  color="gray"
                  style={{
                    cursor: "grab",
                  }}
                />
              </div>
            )}
            <Group spacing="xs" ml={isNotDraggable ? 4 : "xs"}>
              <Image
                height={58}
                width={110}
                src={coverImageUrl}
                radius="md"
                withPlaceholder
                alt={title}
                imageProps={{
                  loading: "lazy",
                }}
              />
              <div>
                <Text
                  size="sm"
                  weight={500}
                  maw={205}
                  lineClamp={1}
                  style={{ lineHeight: "1.2em" }}
                >
                  {title}
                </Text>
                <Text size="sm" color="dimmed" maw={205} lineClamp={1}>
                  {description}
                </Text>
              </div>
            </Group>
          </Flex>
          <Group spacing={0} position="right">
            <ActionIcon
              onClick={() => {
                setOpened(true);
              }}
            >
              <IconPencil size="1rem" stroke={1.5} />
            </ActionIcon>
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <ActionIcon>
                  <IconDots size="1rem" stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconTrash size="1rem" stroke={1.5} />}
                  color="red"
                  onClick={() => handleDelete(websiteId)}
                  disabled={deleteLinkMutation.isLoading}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </Paper>
    </div>
  );
};
