import { Group, Header, Text, Button, AppShell } from "@mantine/core";
import { SignInButton, SignUpButton } from "@clerk/clerk-react";
import { FeaturedProfiles } from "../components/FeaturedProfiles";
import { FooterHome } from "../components/FooterHome";
import { SectionEmbeddings } from "../components/SectionEmbeddings";
import { SectionPricing } from "../components/SectionPricing";
import { SectionHero } from "../components/SectionHero";
import { SectionSpotlight } from "../components/SectionSpotlight";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { SectionAnalytics } from "../components/SectionAnalytics";
import { SectionInTheWild } from "../components/SectionInTheWild";

export function LandingPage() {
  const isLargerThanSm = useMediaQuery("(min-width: 52em)");

  const headerLinks = [
    { title: "How it works", hashLink: "how-it-works" },
    { title: "Featured users", hashLink: "featured-users" },
    { title: "Analytics", hashLink: "analytics" },
    { title: "Pricing", hashLink: "pricing" },
  ];

  const FEATURED_USERS_ARRAY = [
    {
      username: "businessbarista2",
      profPic:
        "https://images.clerk.dev/uploaded/img_2XGY4nelZAZAuNdTUEt3S85Kk4V",
    },
    {
      username: "heydannymiranda",
      profPic:
        "https://images.clerk.dev/uploaded/img_2Yn1sz0B05xdv0NfqajVUiqK395",
    },
  ];
  return (
    <AppShell
      styles={{
        main: {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flexGrow: 1,
          backgroundColor: "#f8fafc",
        },
      }}
      padding={0}
      layout="alt"
      header={
        <Header
          height={65}
          sx={{
            background: "#FDFDFD",
          }}
        >
          <Group position="apart" mih={50} px="xs" spacing="xs" mt={7} noWrap>
            <Text color="white" size={30} weight={600}>
              <span className="gradient-text">Tapistree 🎨</span>
            </Text>
            {isLargerThanSm && (
              <Group spacing={32} noWrap>
                {headerLinks.map((link, index) => {
                  return (
                    <Link
                      key={index}
                      to={`/#${link.hashLink}`}
                      style={{
                        textDecoration: "none",
                        color: "#2983FA",
                        padding: "0px",
                        margin: "0px",
                        border: "none",
                        fontWeight: 600,
                      }}
                    >
                      {link.title}
                    </Link>
                  );
                })}
              </Group>
            )}
            <Group position="center" spacing="xs">
              <SignUpButton>
                <Button
                  variant="gradient"
                  gradient={{ from: " #516bfa", to: "#009bfa", deg: 35 }}
                  size="xs"
                >
                  Try for free
                </Button>
              </SignUpButton>
              <SignInButton>
                <Button variant="outline" color="#2983FA" size="xs">
                  Sign in
                </Button>
              </SignInButton>
            </Group>
          </Group>
        </Header>
      }
    >
      <SectionHero />
      <SectionSpotlight
        username={FEATURED_USERS_ARRAY[0].username}
        profPic={FEATURED_USERS_ARRAY[0].profPic}
        twitterBanner="https://pbs.twimg.com/profile_banners/846417084192055296/1607700327/600x200"
        twitterHandle="businessbarista"
        displayName="Alex Lieberman"
        biography="Cofounder, Morning Brew. Cofounder, storyarb. Host of 60 Second
        Startup & Founder's Journal."
        following="2,933"
        followers="255.2K"
        autoPlay
      />
      <SectionEmbeddings />
      {/* <SectionSpotlight
        username="aubrey"
        profPic={FEATURED_USERS_ARRAY[1].profPic}
        twitterBanner="https://pbs.twimg.com/profile_banners/1007896831186087938/1698096768/600x200"
        twitterHandle="heydannymiranda"
        displayName="Danny Miranda"
        biography="Host of The Danny Miranda Podcast. ArtOfInterviewing.com. Asking questions to the world's greatest entrepreneurs & thinkers to expand love & freedom:"
        following="1,225"
        followers="40.0K"
        autoPlay
      /> */}
      <SectionAnalytics />
      <SectionPricing />
      <SectionInTheWild />
      <FeaturedProfiles />

      <FooterHome />
    </AppShell>
  );
}
