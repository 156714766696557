import { useState, useEffect } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { Header, Flex, Text, useMantineTheme, ScrollArea } from "@mantine/core";
import { NavIcon } from "./NavIcon";
import {
  faAirbnb,
  faSpotify,
  faGoodreads,
  faGithub,
  faYoutube,
  faTwitter,
  faAmazon,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
import { useLinksQuery, useProfileQuery } from "../queries/queries";
import { useStripsQuery } from "../queries/queries";
import { useDebouncedState } from "@mantine/hooks";
import { useParams } from "react-router-dom";
import { spacesToDashes } from "../utils/dahesToSpaces";
import { stripUrl } from "../utils/stripUrl";

export const icons = {
  about: { type: "emoji", icon: "👋" },
  favorites: { type: "emoji", icon: "⭐" },
  airbnb: { type: "fa", icon: faAirbnb },
  github: { type: "fa", icon: faGithub },
  books: { type: "fa", icon: faGoodreads },
  linkedin: { type: "image", icon: "/LinkedIn.png", iconBackground: true },
  // x: { type: "image", icon: "/x-logo.png", width: 22, height: 22 },
  twitter: { type: "fa", icon: faTwitter },
  instagram: { type: "image", icon: "/Instagram.png", iconBackground: false },
  facebook: {
    type: "image",
    icon: "/Facebook-small.png",
    iconBackground: false,
  },
  tiktok: { type: "image", icon: "/TikTok.png", iconBackground: false },
  strava: { type: "image", icon: "/strava-icon.svg", iconBackground: false },
  substack: {
    type: "image",
    icon: "/substack-icon.png",
    iconBackground: false,
  },
  substack2: {
    type: "image",
    icon: "/substack-icon.png",
    iconBackground: false,
  },
  beehiiv: {
    type: "image",
    icon: "/beehiiv.png",
    iconBackground: false,
  },
  beehiiv2: {
    type: "image",
    icon: "/beehiiv.png",
    iconBackground: false,
  },
  "apple-music": {
    type: "image",
    icon: "/AppleMusic.svg",
    iconBackground: false,
  },
  "apple-podcasts": {
    type: "image",
    icon: "/apple-podcasts.png",
    iconBackground: false,
  },
  eventbrite: {
    type: "image",
    icon: "/eventbrite-icon.png",
    iconBackground: false,
  },
  spotify: { type: "fa", icon: faSpotify, iconBackground: false },
  youtube: { type: "fa", icon: faYoutube, iconBackground: true },
  writing: { type: "emoji", icon: "📝" },
  newsletter: { type: "emoji", icon: "📧" },
  photos: { type: "emoji", icon: "📸" },
  events: { type: "emoji", icon: "📆" },
  amazon: { type: "fa", icon: faAmazon },
  medium: { type: "fa", icon: faMedium },
  resume: { type: "emoji", icon: "📝" },
};

//WE NEED TO ADD IN THE FAVICON FOR THE WEBSITE CARDS FOR THE ICONS

export const colors = {
  about: "#FFFFFF",
  airbnb: "#FF385C",
  github: "#FFFFFF",
  books: "#D6D0C4",
  linkedin: "#0A66C2",
  twitter: "#1D9BF0",
  instagram: "#9A43AC",
  spotify: "#45DB74",
  youtube: "#FF0000",
  medium: "#FFFFFF",
};

export function MyNavbar() {
  const theme = useMantineTheme();
  const [activeSection, setActiveSection] = useState("");
  const [scrollPosition, setScrollPosition] = useDebouncedState(0, 50);
  const isLargerThanSm = useMediaQuery("(min-width: 48em)");
  const { username } = useParams();
  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useProfileQuery(username);

  const { data: stripsData } = useStripsQuery();
  const visibleStrips = stripsData?.filter((stripData) => stripData.visible);

  const { data: linksData } = useLinksQuery();

  function websiteFavicon(strip) {
    const linksDataFilteredByStrip = linksData?.find(
      (item) => item.strip === strip
    );
    const faviconFromLink = linksDataFilteredByStrip?.favicon;
    return faviconFromLink;
  }

  function websiteNavId(strip) {
    const linksDataFilteredByStrip = linksData?.find(
      (item) => item.strip === strip
    );
    const navId = stripUrl(linksDataFilteredByStrip?.url)?.replace(/\./g, "");

    return navId;
  }

  const handleScroll = () => {
    if (!stripsData) return;

    const sections = visibleStrips?.map((item) => item.strip.toLowerCase());

    const sectionPositions = sections?.map((id) => {
      const element = document.getElementById(id);
      return element ? element.getBoundingClientRect().top : null;
    });

    const OFFSET = -180;

    const currentSection = sections?.find(
      (_, index) => sectionPositions[index] >= 0 + OFFSET
    );
    setActiveSection(currentSection);

    const currentScrollPosition = window.pageXOffset;
    setScrollPosition(currentScrollPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [stripsData, activeSection]);

  if (profileIsLoading) {
    return <></>;
  } else if (profileIsError) {
    return <p>Error loading links</p>;
  } else {
    return (
      <Header
        height={50}
        mt={10}
        style={{
          maxWidth: "828px",
          marginLeft: "auto",
          marginRight: "auto",
          backgroundColor: "transparent",
          border: "none",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: isLargerThanSm ? "space-between" : "center",
            height: "50px",
            padding: "0px 10px",
            borderRadius: "24px",
            backgroundColor: "#202123",
            color: theme.white,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            border: "none",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          {isLargerThanSm && (
            <Text size="md" ta="left" weight={600} mx={10} lineClamp={1}>
              {profileData?.displayName}
            </Text>
          )}
          <ScrollArea type="never" style={{ borderRadius: "32px" }}>
            <Flex
              gap="lg"
              align="center"
              justify="center"
              px={10}
              style={{
                flexGrow: 1,
                minHeight: "32px",
                overflowY: "hidden",
              }}
            >
              {visibleStrips?.map((item) => {
                const strip = spacesToDashes(item.strip.toLowerCase());

                if (!icons[strip]) {
                  const customIcon = {
                    type: "image",
                    icon: websiteFavicon(item.strip),
                    iconBackgroundColor: item.iconBackgroundColor,
                  };

                  const navId = websiteNavId(item.strip);

                  return (
                    navId && (
                      <NavIcon
                        key={strip}
                        icon={customIcon}
                        hashRoute={navId}
                        username={username}
                        isActive={activeSection === strip}
                      />
                    )
                  );
                }
                return (
                  <NavIcon
                    key={strip}
                    icon={icons[strip]}
                    iconColor={colors[strip]}
                    hashRoute={strip}
                    username={username}
                    isActive={activeSection === strip}
                  />
                );
              })}
            </Flex>
          </ScrollArea>
          {isLargerThanSm && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            ></div>
          )}
        </div>
      </Header>
    );
  }
}
