import { ErrorBoundary } from "@sentry/react";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { GitHubLanguageIcons } from "../GitHubLanguageIcon";
import { CommitCalendar } from "../CommitCalendar";
import { GitHubFollowButton } from "../CTAButtons";
import { Strip } from "../Strip";

export function DummyGitHubStrip() {
  const finalProfileUrl = `https://github.com/levlesio`;

  const programmingLanguages = ["Python", "JavaScript", "PHP", "HTML", "CSS"];

  return (
    <Strip
      key="GitHub"
      id="github"
      nav="scroll"
      gradientValues={["#24292F"]}
      followButton={<GitHubFollowButton link={finalProfileUrl} />}
      title="GitHub"
      icon={{ type: "fa", icon: faGithub }}
      iconColor="white"
      iconLink={finalProfileUrl}
      explainer={<GitHubLanguageIcons languages={programmingLanguages} />}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          color: "white",
        }}
      >
        <div style={{ marginLeft: "10px" }}>
          <div style={{ height: "20px" }} />
          <ErrorBoundary>
            <CommitCalendar username="levelsio" />
          </ErrorBoundary>
        </div>
      </div>
    </Strip>
  );
}
