import { useState } from "react";
import { Switch, Tooltip } from "@mantine/core";
import {
  useUpdateStripVisibilityMutation,
  useStripsQuery,
} from "../queries/queries";

export function SwitchWithTooltip({ onLabel, offLabel, id }) {
  const stripsQuery = useStripsQuery();

  const { data: strips } = stripsQuery;
  const updateStripVisibilityMutation = useUpdateStripVisibilityMutation();

  function updateVisibility(visibility) {
    updateStripVisibilityMutation.mutate({
      id: id,
      data: {
        visible: visibility,
      },
    });
  }

  const thisStrip = strips?.find((strip) => strip.id === id);

  const [checked, setChecked] = useState(thisStrip?.visible);

  return (
    <Tooltip
      label={checked ? "Click to hide from site" : "Click to show on site"}
      withArrow
    >
      <div>
        <Switch
          size="sm"
          color="green"
          onLabel={onLabel}
          offLabel={offLabel}
          checked={checked}
          onChange={(event) => {
            setChecked(event.currentTarget.checked);
            updateVisibility(event.currentTarget.checked);
          }}
          // onClick={!checked ? () => open() : () => toggle()}
        />
      </div>
    </Tooltip>
  );
}
