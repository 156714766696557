// import { Button, Card, Group, Image, Rating, Text } from "@mantine/core";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faAmazon } from "@fortawesome/free-brands-svg-icons";

export const TAG = "prsmn-20";

// Function to extract ASIN from Amazon URL
const extractASIN = (url) => {
  const regex = /\/([A-Z0-9]{10})/;
  const matches = url.match(regex);
  return matches ? matches[1] : null;
};

// export function AmazonImage({ url }) {
//   const ASIN = extractASIN(url);

//   // If ASIN is not found, handle the error appropriately
//   if (!ASIN) {
//     console.error("Invalid URL: ASIN not found.");
//     return null; // You might want to return a different fallback UI
//   }

//   const imgSrc1 = `//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=${ASIN}&Format=_SL160_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=${TAG}&language=en_US`;
//   const imgSrc2 = `https://ir-na.amazon-adsystem.com/e/ir?tag=${TAG}&language=en_US&l=li2&o=1&a=${ASIN}`;

//   return (
//     <a href={`${url}&tag=${TAG}`} target="_blank" rel="noopener noreferrer">
//       <img border="0" src={imgSrc1} alt="Amazon Product" />
//       <img
//         src={imgSrc2}
//         width="1"
//         height="1"
//         border="0"
//         alt=""
//         style={{ border: "none", margin: 0 }}
//       />
//     </a>
//   );
// }

export function AmazonCard({ url }) {
  const ASIN = extractASIN(url);

  // Validate ASIN
  if (!ASIN) {
    return <p>Invalid Amazon URL</p>;
  }

  // Construct the iframe src URL
  const iframeSrc = `//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ss&ref=as_ss_li_til&ad_type=product_link&tracking_id=${TAG}&language=en_US&marketplace=amazon&region=US&placement=${ASIN}&asins=${ASIN}&linkId=5ce31449b3475f44db187ce5fe711968&show_border=false&link_opens_in_new_window=true`;

  return (
    <iframe
      title="Amazon listing"
      sandbox="allow-popups allow-scripts allow-modals allow-forms allow-same-origin"
      style={{ width: 120, height: 240, marginRight: "10px" }}
      marginWidth="0"
      marginHeight="0"
      scrolling="no"
      frameBorder="0"
      src={iframeSrc}
    ></iframe>
  );
}
