import { useSuperProfileQuery } from "../queries/queries";
import { StripSkeleton } from "../components/StripSkeleton";
import { Table, Text } from "@mantine/core";

export function AubreyPortal() {
  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useSuperProfileQuery();

  const rows = profileData?.map((user) => {
    // Convert createdAt to a Date object and format it
    const formattedDate = new Date(user.createdAt).toLocaleString("en-US");

    return (
      <tr key={user.username}>
        <td>{user.username}</td>
        <td>
          <a href={`https://tapistr.ee/${user.username}`}>
            https://tapistr.ee/{user.username}
          </a>
        </td>
        <td>{user.displayName}</td>
        <td>{formattedDate}</td>
      </tr>
    );
  });

  if (profileIsLoading) {
    return <StripSkeleton />;
  } else if (profileIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>Username</th>
              <th>Link</th>
              <th>Display Name</th>
              <th>Created at</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </Table>
      </>
    );
  }
}
