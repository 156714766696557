import { faPodcast } from "@fortawesome/free-solid-svg-icons";
import { Strip } from "../Strip";
import { AppleMusicEmbed } from "../AppleMusicEmbed";

export function DummyApplePodcastsStrip() {
  const linksDataByStrip = [
    { url: "https://podcasts.apple.com/us/podcast/huberman-lab/id1545953110" },
  ];

  return (
    <Strip
      id="apple-podcasts"
      nav="scroll"
      gradientValues={["#FAFAFA"]}
      title="Apple Podcasts"
      titleColor="#212121"
      icon={{ type: "fa", icon: faPodcast }}
      iconColor="#7224D8"
    >
      {linksDataByStrip?.map((link, index) => {
        return <AppleMusicEmbed url={link.url} key={index} />;
      })}
    </Strip>
  );
}
