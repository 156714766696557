import "./App.css";
import { LandingPage } from "./pages/LandingPage";
import { Profile } from "./pages/Profile";
import { Routes, Route } from "react-router-dom";
import { AppShell, Stack } from "@mantine/core";
import { Edit } from "./pages/Edit";
import { MantineProvider, LoadingOverlay } from "@mantine/core";
import { ScrollToHashElement } from "./components/ScrollToHashElement";
import { MyNavbar } from "./components/MyNavbar";
import {
  ClerkProvider,
  SignedIn,
  SignedOut,
  useUser,
  SignUp,
  SignIn,
  ClerkLoaded,
} from "@clerk/clerk-react";
import { useProfileQuery } from "./queries/queries";
import { UserProvider } from "./contexts/UserContext";
import Interim from "./pages/Interim";
import { NotFoundPage } from "./pages/NotFoundPage";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { CookieBanner } from "./components/CookieBanner";
import { useNavigate } from "react-router-dom";
import { FooterLogo } from "./components/FooterLogo";
import { HelmetProvider, Helmet } from "react-helmet-async";
// import { RemoveTrailingSlash } from "./components/RemoveTrailingSlash";
import { Analytics } from "./pages/Analytics";
import { AubreyPortal } from "./pages/AubreyPortal";
import { StravaAuthHandler } from "./components/StravaAuthHandler";
import { EventbriteAuthHandler } from "./components/EventbriteAuthHandler";
import { StravaPage } from "./pages/StravaPage";

export const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;
export const clerkSuperAdminId = process.env.REACT_APP_CLERK_SUPER_ADMIN_ID;

export function MainContent() {
  function SignedInComponent() {
    const {
      data: profileData,
      isLoading: profileIsLoading,
      isError: profileIsError,
    } = useProfileQuery();
    const { user } = useUser();

    const displayName = profileData?.displayName;
    const username = profileData?.username;
    const profileUrl = `https://tapistr.ee/${username}`; // Adjust with your actual domain and profile path logic

    // Construct your Open Graph URL, description, and image based on the user profile
    const ogTitle =
      username && displayName
        ? `${displayName} (@${username}) on Tapistree`
        : "Tapistree";
    const ogImage = profileData?.profPic || "%PUBLIC_URL%/logo250.png"; // Replace with your default image URL

    const navigate = useNavigate();

    // Does the Clerk logged in user id match the userId of the profile we've visited. User is on their own profile!.
    const isAdmin = user?.id === profileData?.clerkUserId;
    const isSuperAdmin = user?.id === clerkSuperAdminId;

    if (profileIsLoading) {
      return <LoadingOverlay visible={true} />;
    } else if (profileIsError) {
      navigate("/not-found");
      return null;
      // } else if (profileIsNotActivated && !isAdmin) {
      //   return <NotActivatedPage />;
    } else {
      const navbarComponent =
        isAdmin || isSuperAdmin ? (
          <SignedIn>
            <Edit />
          </SignedIn>
        ) : (
          <></>
        );

      return (
        <AppShell
          styles={{
            main: {
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              flexGrow: 1,
              backgroundColor: "#f8fafc",
            },
          }}
          padding={0}
          layout="alt"
          header={<MyNavbar />}
          navbar={navbarComponent}
        >
          <Helmet>
            <title>{username ? `@${username} | Tapistree` : "Tapistree"}</title>
            <meta property="og:title" content={ogTitle} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:type" content="profile" />
            <meta property="og:url" content={window.location.href} />
            <link rel="canonical" href={profileUrl} />
            <script
              defer
              data-domain={`tapistr.ee/${username}`}
              src="https://plausible.io/js/script.outbound-links.exclusions.js"
              data-exclude="/i/analytics"
            ></script>
          </Helmet>
          <Profile />
          <FooterLogo />
        </AppShell>
      );
    }
  }

  return (
    <MantineProvider
      withGlobalStyles
      withNormalizeCSS
      theme={{
        breakpoints: {
          xs: "30em",
          sm: "48em",
          md: "64em",
          lg: "74em",
          xl: "90em",
        },
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <>
              <SignedOut>
                <LandingPage />
              </SignedOut>
              <SignedIn>
                <Interim />
              </SignedIn>
            </>
          }
        />
        <Route path="/:username" element={<SignedInComponent />} />
        <Route path="/this-is-a-test/*" element={<p>this is a test</p>} />
        <Route path="/interim" element={<Interim />} />
        <Route path="sign-in/*" element={<SignIn redirectUrl="/interim" />} />
        <Route
          path="sign-up/*"
          element={
            <Stack align="center" justify="center" h="90vh">
              <SignUp redirectUrl="/interim" />
            </Stack>
          }
        />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/i/analytics" element={<Analytics />} />
        <Route path="/s/superextracosmos" element={<AubreyPortal />} />
        <Route path="/i/strava-auth" element={<StravaAuthHandler />} />
        <Route path="/:username/strava" element={<StravaPage />} />
        <Route path="/i/eventbrite-auth" element={<EventbriteAuthHandler />} />
      </Routes>
      <ScrollToHashElement />
      {/* <CookieBanner /> */}
    </MantineProvider>
  );
}

function App() {
  return (
    <ClerkProvider publishableKey={clerkPubKey}>
      <ClerkLoaded>
        <HelmetProvider>
          <UserProvider>
            <MainContent />
          </UserProvider>
        </HelmetProvider>
      </ClerkLoaded>
    </ClerkProvider>
  );
}

export default App;
