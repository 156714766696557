import { Modal, Text, Button } from "@mantine/core";

export function LeaveModal({ leaveModalOpened, setLeaveModalOpened }) {
  const handleClose = () => setLeaveModalOpened(false);

  return (
    <Modal
      open={leaveModalOpened}
      onClose={handleClose}
      title="Are you sure you want to leave this page?"
      size="xs"
    >
      <Text>Clicking the link will take you to another page.</Text>
      <Button onClick={handleClose} color="red">
        Go Back
      </Button>
      <Button onClick={handleClose} color="green">
        Proceed
      </Button>
    </Modal>
  );
}
