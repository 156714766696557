import { useState } from "react";
import {
  Group,
  createStyles,
  Text,
  Flex,
  ActionIcon,
  Image,
  Menu,
  Paper,
  Rating,
} from "@mantine/core";
import {
  useDeleteLinkMutation,
  useCreateLinkMutation,
  useProfileQuery,
} from "../queries/queries";
import { IconTrash, IconGripVertical, IconDots } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  item: {},

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  dragHandle: {
    display: "flex",
    alignItems: "center",
  },
  hidden: {
    visibility: "hidden",
  },
}));

export const BookSelectionCard = ({
  id,
  image,
  title,
  authorName,
  userRating,
  provided,
  snapshot,
}) => {
  const deleteLinkMutation = useDeleteLinkMutation();

  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  async function handleDelete(id) {
    deleteLinkMutation.mutate({
      id: id,
    });
  }

  const createLinkMutation = useCreateLinkMutation();

  const { classes } = useStyles();

  const [value, setValue] = useState(userRating);

  function handleRating(value) {
    createLinkMutation.mutate({
      id: id,
      userId: destinationUserId,
      userRating: value,
    });
    setValue(value);
  }

  return (
    <div
      className={snapshot?.isDragging ? classes.itemDragging : ""}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      <Paper
        radius="md"
        pl={6}
        pr="xs"
        py="xs"
        mb={8}
        withBorder
        style={{
          backgroundColor: "#F7F8F9",
        }}
      >
        <Group position="apart" spacing="xs">
          <Flex align="center">
            <div {...provided?.dragHandleProps} className={classes.dragHandle}>
              <IconGripVertical
                size="1.05rem"
                stroke={1.5}
                color="gray"
                style={{
                  cursor: "grab",
                }}
              />
            </div>
            <Group spacing="xs">
              <Image
                width={35}
                height={60}
                src={image}
                radius="sm"
                alt={title}
                withPlaceholder
                imageProps={{
                  loading: "lazy",
                }}
                ml="xs"
              />
              <div>
                <Group noWrap spacing="xs">
                  <Text maw={172} lineClamp={1} size="sm">
                    {title}
                  </Text>
                </Group>
                <Text maw={220} size="xs" color="dimmed" lineClamp={1}>
                  {authorName}
                </Text>
                <Rating
                  size="sm"
                  color="orange"
                  value={value}
                  onChange={handleRating}
                  fractions={2}
                />
              </div>
            </Group>
          </Flex>
          {/* <Group spacing={0} position="right"> */}
          <Menu
            transitionProps={{ transition: "pop" }}
            withArrow
            position="bottom-end"
            withinPortal
          >
            <Menu.Target>
              <ActionIcon>
                <IconDots size="1rem" stroke={1.5} />
              </ActionIcon>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item
                icon={<IconTrash size="1rem" stroke={1.5} />}
                color="red"
                onClick={() => handleDelete(id)}
                disabled={deleteLinkMutation.isLoading}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          {/* </Group> */}
        </Group>
      </Paper>
    </div>
  );
};
