import {
  // faSpotify,
  faGithub,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { useLocalStorage } from "../hooks/useLocalStorage";
import {
  Anchor,
  Box,
  Button,
  Divider,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Modal,
  Paper,
  ScrollArea,
  SimpleGrid,
  Stack,
  Text,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import { StripCheckbox } from "../components/AddStrips";
import { useEffect, useState, useRef } from "react";
import { getLinktree } from "../api/links";
import {
  useAddStripMutation,
  useCreateLinkMutation,
  useProfileMutation,
  useProfileQuery,
} from "../queries/queries";
import { useUserContext } from "../contexts/UserContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TwitterIcon } from "@mantine/ds";
import { useNavigate } from "react-router-dom";
import { IconRefresh } from "@tabler/icons-react";

export function LinktreeConvert() {
  const [linktreeValue, setLinktreeValue] = useState("");
  const [twitterValue, setTwitterValue] = useState("");
  const [instagramValue, setInstagramValue] = useState("");
  const [tikTokValue, setTikTokValue] = useState("");
  const [linkedinValue, setLinkedinValue] = useState("");
  const [githubValue, setGithubValue] = useState("");
  const [youtubeValue, setYoutubeValue] = useState("");
  const [substackValue, setSubstackValue] = useState("");

  const baseLinktreeUrl = "https://linktr.ee/";
  const baseTwitterUrl = "https://twitter.com/";
  const baseInstagramUrl = "https://instagram.com/";
  const baseTikTokUrl = "https://tiktok.com/@";
  const baseLinkedinUrl = "https://linkedin.com/in/";
  const baseGithubUrl = "https://github.com/";
  const baseYoutubeUrl = "https://youtube.com/@";
  const baseSubstackUrlStart = "https://";
  const baseSubstackUrlEnd = ".substack.com";

  const navigate = useNavigate();

  const [results, setResults] = useState("");
  const [linksByStrip, setLinksByStrip] = useState([]);
  const [linktreeIsLoading, setLinktreeIsLoading] = useState(false);
  const [linktreeError, setLinktreeError] = useState(false);

  async function handleSubmit(value) {
    setLinktreeIsLoading(true);
    setLinktreeError(false); //In case they're running it a 2nd time, we have to reset it.

    const data = await getLinktree(value);
    if (data.length < 1) {
      // Handle the case where getLinktree encountered an error and returned an empty array
      console.error(
        "Received empty data, likely due to an error in fetching Linktree data"
      );
      setResults("");
      setLinktreeValue("");
      setLinktreeError(true);
      // Optionally set an error state here to display a message to the user
    } else {
      // Proceed with normal handling if data is successfully retrieved
      setResults(data);
      setLinksByStrip(data.linksByStrip);
      setCheckedLinksByStrip(data.linksByStrip); // default to checking all
    }

    setLinktreeIsLoading(false);
  }

  const addStripMutation = useAddStripMutation();
  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  const createLinkMutation = useCreateLinkMutation();
  const profileMutation = useProfileMutation();

  const { loggedInUsername } = useUserContext();

  function addLinktree(strips) {
    //PROFILE.
    function updateProfile() {
      // Initialize the updates object with fields that are always included
      const updates = {
        profPic: results.profileData?.profPic,
        displayName: results.profileData?.displayName,
      };

      // Conditionally add the username field if the criteria are met
      if (
        loggedInUsername.startsWith("user") &&
        results.profileData?.usernameIsAvailable
      ) {
        updates.username = results.profileData?.username.toLowerCase();
      }

      // Execute the mutation with the constructed updates object
      profileMutation.mutate({
        username: loggedInUsername,
        updates: updates,
      });
    }

    updateProfile();

    //STRIPS.

    //Unique strips, except Websites :)
    const uniqueStrips = strips
      .map((item) => item.strip) // Extract the 'strip' values
      .filter(
        (value, index, self) =>
          value === "Website" || self.indexOf(value) === index
      ); // Include all 'Website' and filter out duplicates for others

    const stripsDataWithPositionAndVisible = uniqueStrips?.map(
      (strip, index) => {
        return {
          strip: strip,
          stripPosition: index,
          visible: true,
          showFollowButton: strip === "YouTube" ? true : false,
        };
      }
    );

    addStripMutation.mutate({
      userId: destinationUserId,
      stripsData: stripsDataWithPositionAndVisible,
    });

    //LINKS.

    const profilePlatforms = new Set([
      "Twitter",
      "GitHub",
      "Substack",
      "LinkedIn",
      "TikTok",
    ]);

    for (let i = 0; i < checkedLinksByStrip.length; i++) {
      // Check if the strip is the type where it's a default profileUrl
      let isProfileUrl = profilePlatforms.has(checkedLinksByStrip[i].strip);

      createLinkMutation.mutate({
        url: checkedLinksByStrip[i].url,
        isProfileUrl: isProfileUrl,
        userId: destinationUserId,
        strip: checkedLinksByStrip[i].strip,
      });
    }

    setLinktreeValue("");
    setResults("");
    setLinksByStrip([]);
    setOpened(false);
    loggedInUsername.startsWith("user") &&
      results.profileData?.usernameIsAvailable &&
      navigate(`/${results.profileData?.username}`);
  }

  //STRIP SELECTOR a.k.a CHECKBOXES

  const [opened, setOpened] = useLocalStorage("opened", true);
  const [checkedLinksByStrip, setCheckedLinksByStrip] = useState([]);

  const handleCheckboxChange = (isChecked, strip, urlDescription) => {
    console.log(strip, urlDescription);
    if (isChecked) {
      console.log("checked!");
      setCheckedLinksByStrip([
        ...checkedLinksByStrip,
        {
          url: urlDescription,
          strip: strip,
        },
      ]);
    } else {
      console.log("unchecked");
      setCheckedLinksByStrip(
        checkedLinksByStrip?.filter((item) => item.url !== urlDescription)
      );
    }
  };

  //We always want to show ALL options, not just checked ones here.
  const strips = linksByStrip?.map((item, index) => {
    const urlDescription = item?.url;

    if (item.strip.startsWith("Website")) {
      const uniqueStripIdentifier = item.strip;
      console.log(uniqueStripIdentifier);

      return (
        <StripCheckbox
          {...item}
          popular={false}
          key={item.url + "index" + index}
          strip={uniqueStripIdentifier}
          alternateStripName="Website"
          description={urlDescription}
          defaultChecked={true}
          onChange={handleCheckboxChange}
        />
      );
    } else {
      return (
        <StripCheckbox
          {...item}
          popular={false}
          key={item.url + "index" + index}
          description={urlDescription}
          defaultChecked={true}
          onChange={handleCheckboxChange}
        />
      );
    }
  });

  const userElementRef = useRef(null);

  function addSocials() {
    const strips = [
      twitterValue
        ? { strip: "Twitter", url: baseTwitterUrl + twitterValue }
        : null,
      instagramValue
        ? { strip: "Instagram", url: baseInstagramUrl + instagramValue }
        : null,
      tikTokValue
        ? { strip: "TikTok", url: baseTikTokUrl + tikTokValue }
        : null,
      linkedinValue
        ? { strip: "LinkedIn", url: baseLinkedinUrl + linkedinValue }
        : null,
      githubValue
        ? { strip: "GitHub", url: baseGithubUrl + githubValue }
        : null,
      youtubeValue
        ? { strip: "YouTube", url: baseYoutubeUrl + youtubeValue }
        : null,
      substackValue
        ? {
            strip: "Substack",
            url: baseSubstackUrlStart + substackValue + baseSubstackUrlEnd,
          }
        : null,
    ].filter((item) => item !== null);

    const stripsDataWithPositionAndVisible = strips?.map((strip, index) => {
      return {
        strip: strip.strip,
        stripPosition: index,
        visible: true,
        showFollowButton: false,
      };
    });

    addStripMutation.mutate({
      userId: destinationUserId,
      stripsData: stripsDataWithPositionAndVisible,
    });

    for (let i = 0; i < strips.length; i++) {
      console.log("Ok:" + strips[i].strip, strips[i].url);
      createLinkMutation.mutate({
        url: strips[i].url,
        isProfileUrl: true,
        userId: destinationUserId,
        strip: strips[i].strip,
      });
    }

    setOpened(false);
    setLinktreeValue("");
    setTwitterValue("");
    setInstagramValue("");
    setTikTokValue("");
    setLinkedinValue("");
    setGithubValue("");
    setYoutubeValue("");
    setSubstackValue("");
  }

  useEffect(() => {
    if (userElementRef.current) {
      userElementRef.current.style.setProperty(
        "--user-profile-photo",
        `url(${results.profileData?.profPic})`
      );
    }
  }, [results]);

  const showLinktree =
    !twitterValue &&
    !instagramValue &&
    !tikTokValue &&
    !linkedinValue &&
    !githubValue &&
    !youtubeValue &&
    !substackValue;

  const showSocials = !results;
  const disableSocials = linktreeValue;

  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        styles={{
          header: {
            backgroundColor: "transparent",
            height: "25px",
            padding: "0px 10px 0px 0px",
          },
        }}
      >
        <Box pos="relative">
          <LoadingOverlay visible={linktreeIsLoading} />
          {!results ? (
            <Stack spacing="xs">
              <Stack spacing={6}>
                <Text size="sm" fw={600}>
                  Migrate from Linktree
                </Text>
                <Flex>
                  <Image
                    radius="sm"
                    src="/linktree.png"
                    height={36}
                    width={36}
                    mr={3}
                  />
                  <TextInput
                    disabled={!showLinktree}
                    icon={<Text size="sm">{baseLinktreeUrl}</Text>}
                    autoCapitalize="none"
                    placeholder="aubrey"
                    mr={3}
                    value={linktreeValue}
                    onChange={(event) =>
                      setLinktreeValue(event.currentTarget.value)
                    }
                    styles={{
                      root: {
                        fontSize: "16px",
                        flexGrow: "1 !important",
                      },
                      input: {
                        fontSize: "16px",
                        lineHeight: "20px",
                        "&[data-with-icon]": {
                          paddingLeft: "108px",
                        },
                      },
                      icon: {
                        minWidth: "114px",
                        color: "black",
                      },
                    }}
                  />
                </Flex>
                <Button
                  fullWidth
                  onClick={() => handleSubmit(linktreeValue)}
                  disabled={!showLinktree}
                >
                  Import Linktree
                </Button>
              </Stack>
              {linktreeError && (
                <Text color="red" size="sm">
                  We're sorry, we encountered an error.
                </Text>
              )}
            </Stack>
          ) : (
            <Anchor
              size="xs"
              onClick={() => setResults("")}
              style={{ display: "flex", alignItems: "center" }}
            >
              <IconRefresh size="0.9rem" stroke={1.5} /> {"  "}
              &nbsp;Retry
            </Anchor>
          )}
        </Box>
        {results && (
          <>
            {results.profileData && (
              <div ref={userElementRef} className="bgphoto">
                <Paper
                  withBorder
                  radius="md"
                  // shadow="lg"
                  mt="sm"
                  p="xs"
                  sx={{ backgroundColor: "transparent" }}
                >
                  <Stack spacing={6}>
                    <Group align="center" noWrap>
                      <Image
                        radius="50%"
                        height={60}
                        width={60}
                        src={results.profileData?.profPic}
                        withPlaceholder
                      />
                      <Stack spacing={6} align="stretch">
                        <Text size="sm" weight={600}>
                          {results.profileData?.displayName}
                        </Text>
                        <Text size="sm">@{results.profileData?.username}</Text>
                      </Stack>
                    </Group>
                    <Text align="center" size="sm">
                      {results.profileData?.profileDescription}
                    </Text>
                  </Stack>
                </Paper>
              </div>
            )}
            <ScrollArea h="40vh" type="auto" offsetScrollbars mt="xs">
              <SimpleGrid
                cols={1}
                breakpoints={[
                  { maxWidth: "md", cols: 1 },
                  { maxWidth: "sm", cols: 1 },
                ]}
                spacing="xs"
              >
                {strips}
              </SimpleGrid>
            </ScrollArea>
            <Button
              fullWidth
              color="green"
              mt="xs"
              onClick={() => addLinktree(checkedLinksByStrip)}
            >
              Create profile
            </Button>
          </>
        )}
        {showSocials ? (
          <>
            <Divider
              labelPosition="center"
              size="sm"
              my="sm"
              label={
                <Text size="sm" fw={600}>
                  Or
                </Text>
              }
            />
            <Stack>
              <Text size="sm" fw={600}>
                Add your social media profiles
              </Text>

              <Flex align="end">
                <Image
                  radius="md"
                  src="/Instagram.png"
                  height={36}
                  width={36}
                  mr={3}
                />

                <TextInput
                  disabled={disableSocials}
                  icon={<Text size="sm">{baseInstagramUrl}</Text>}
                  placeholder="aubrey"
                  mr={3}
                  value={instagramValue}
                  onChange={(event) =>
                    setInstagramValue(event.currentTarget.value)
                  }
                  styles={{
                    root: {
                      fontSize: "16px",
                      flexGrow: "1 !important",
                    },
                    input: {
                      fontSize: "16px",
                      lineHeight: "20px",
                      "&[data-with-icon]": {
                        paddingLeft: "151px",
                      },
                    },
                    icon: {
                      minWidth: "156px",
                      color: "black",
                    },
                  }}
                />
              </Flex>

              <Flex align="end">
                <Image
                  radius="md"
                  src="/TikTok.png"
                  height={36}
                  width={36}
                  mr={3}
                />

                <TextInput
                  disabled={disableSocials}
                  icon={<Text size="sm">{baseTikTokUrl}</Text>}
                  placeholder="aubrey"
                  mr={3}
                  value={tikTokValue}
                  onChange={(event) =>
                    setTikTokValue(event.currentTarget.value)
                  }
                  styles={{
                    root: {
                      fontSize: "16px",
                      flexGrow: "1 !important",
                    },
                    input: {
                      fontSize: "16px",
                      lineHeight: "20px",
                      "&[data-with-icon]": {
                        paddingLeft: "137px",
                      },
                    },
                    icon: {
                      minWidth: "141px",
                      color: "black",
                    },
                  }}
                />
              </Flex>

              <Flex align="end">
                <ThemeIcon color="#FAFAFA" size={36} mr={3} radius="sm">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    size="2x"
                    style={{
                      color: "#FF0000",
                    }}
                  />
                </ThemeIcon>
                <TextInput
                  disabled={disableSocials}
                  icon={<Text size="sm">{baseYoutubeUrl}</Text>}
                  placeholder="aubrey"
                  mr={3}
                  value={youtubeValue}
                  onChange={(event) =>
                    setYoutubeValue(event.currentTarget.value)
                  }
                  styles={{
                    root: {
                      fontSize: "16px",
                      flexGrow: "1 !important",
                    },
                    input: {
                      fontSize: "16px",
                      lineHeight: "20px",
                      "&[data-with-icon]": {
                        paddingLeft: "152px",
                      },
                    },
                    icon: {
                      minWidth: "157px",
                      color: "black",
                    },
                  }}
                />
              </Flex>

              <Flex align="end">
                <ThemeIcon color="#00ACEE" size={36} mr={3}>
                  <TwitterIcon size="1.5rem" color="white" />
                </ThemeIcon>

                <TextInput
                  disabled={disableSocials}
                  icon={<Text size="sm">{baseTwitterUrl}</Text>}
                  placeholder="aubrey"
                  mr={3}
                  value={twitterValue}
                  onChange={(event) =>
                    setTwitterValue(event.currentTarget.value)
                  }
                  styles={{
                    root: {
                      fontSize: "16px",
                      flexGrow: "1 !important",
                    },
                    input: {
                      fontSize: "16px",
                      lineHeight: "20px",
                      "&[data-with-icon]": {
                        paddingLeft: "128px",
                      },
                    },
                    icon: {
                      minWidth: "133px",
                      color: "black",
                    },
                  }}
                />
              </Flex>
              {/* <Flex align="end">
                <Image
                  radius="sm"
                  src="/LinkedIn.png"
                  height={36}
                  width={36}
                  mr={3}
                />

                <TextInput
                  disabled={disableSocials}
                  icon={<Text size="sm">{baseLinkedinUrl}</Text>}
                  placeholder="aubrey"
                  mr={3}
                  value={linkedinValue}
                  onChange={(event) =>
                    setLinkedinValue(event.currentTarget.value)
                  }
                  styles={{
                    root: {
                      fontSize: "16px",
                      flexGrow: "1 !important",
                    },
                    input: {
                      fontSize: "16px",
                      lineHeight: "20px",
                      "&[data-with-icon]": {
                        paddingLeft: "152px",
                      },
                    },
                    icon: {
                      minWidth: "156px",
                      color: "black",
                    },
                  }}
                />
              </Flex> */}
              <Flex align="end">
                <ThemeIcon color="#24292F" size={36} mr={3} radius="sm">
                  <FontAwesomeIcon icon={faGithub} size="2x" />
                </ThemeIcon>
                <TextInput
                  disabled={disableSocials}
                  icon={<Text size="sm">{baseGithubUrl}</Text>}
                  placeholder="aubrey"
                  mr={3}
                  value={githubValue}
                  onChange={(event) =>
                    setGithubValue(event.currentTarget.value)
                  }
                  styles={{
                    root: {
                      fontSize: "16px",
                      flexGrow: "1 !important",
                    },
                    input: {
                      fontSize: "16px",
                      lineHeight: "20px",
                      "&[data-with-icon]": {
                        paddingLeft: "128px",
                      },
                    },
                    icon: {
                      minWidth: "133px",
                      color: "black",
                    },
                  }}
                />
              </Flex>

              <Flex align="end">
                <Image
                  src="/substack-icon.png"
                  alt="Substack"
                  width={36}
                  height={36}
                  mr={3}
                />
                <TextInput
                  disabled={disableSocials}
                  icon={<Text size="sm">{baseSubstackUrlStart}</Text>}
                  placeholder="aubrey"
                  mr={3}
                  value={substackValue}
                  onChange={(event) =>
                    setSubstackValue(event.currentTarget.value)
                  }
                  styles={{
                    root: {
                      fontSize: "16px",
                      flexGrow: "1 !important",
                    },
                    input: {
                      fontSize: "16px",
                      lineHeight: "20px",
                      paddingRight: "110px",
                      "&[data-with-icon]": {
                        paddingLeft: "52px",
                      },
                    },
                    icon: {
                      minWidth: "57px",
                      color: "black",
                    },
                    rightSection: {
                      minWidth: "120px",
                    },
                  }}
                  rightSection={<Text size="sm">{baseSubstackUrlEnd}</Text>}
                />
              </Flex>

              <Button
                disabled={disableSocials}
                fullWidth
                onClick={addSocials}
                // disabled={checkedItems?.length === 0 || results?.length === 0}
              >
                Get started
              </Button>
            </Stack>
          </>
        ) : (
          <></>
        )}
      </Modal>
      <Button
        fullWidth
        radius="md"
        color="gray"
        onClick={() => setOpened(true)}
        sx={{
          // backgroundColor: "#333333",
          boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Image src="/linktree.png" height={24} width={24} mr={8} radius="sm" />
        <Text size="lg">Migrate your Linktree</Text>
      </Button>
    </>
  );
}

//   <a href="https://firstround.com/person/chris-fralic/" target="_blank" rel="noopener" data-testid="LinkButton" class="sc-pFZIQ sc-fodVxV ldGKnQ hAiyjL group" aria-describedby="profile-chris-fralic " height="auto"><div class="w-full h-full"><div data-testid="LinkThumbnail" class="sc-bdfBwQ sc-gsTCUz sc-TmcTc dgVnpq bhdLno cepotg"></div><p class="sc-hKgILt sc-eLgOdN gXKGT cEudwS">First Round</p></div></a>
