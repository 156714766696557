import "../App.css";
import { Text } from "@mantine/core";
import { useLinksQuery } from "../queries/queries";

import { Strip } from "./Strip";

import { StripSkeleton } from "./StripSkeleton";

export function ResumeStrip() {
  const STRIP = "Resume";
  const {
    // data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  // const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id={STRIP.toLowerCase()}
        nav="scroll"
        gradientValues={["#FFFFFF"]}
        icon={{ type: "emoji", icon: "📝" }}
        title="Resume"
        titleColor="#000000"
        iconColor="white"
      >
        {/* <PdfPreview /> */}
      </Strip>
    );
  }
}
