import { Skeleton, Card, Center } from "@mantine/core";

export function SkeletonCard() {
  return (
    <Card miw={260} m={0} p="xs" radius="lg" mt={10}>
      <Skeleton height={50} circle mb="sm" />
      <Skeleton height={8} radius="xl" />
      <Skeleton height={8} mt={6} radius="xl" />
      <Skeleton height={8} mt={6} width="70%" radius="xl" />
      <Skeleton height={80} width={220} radius="md" mt="sm" />
      <Center>
        <Skeleton height={30} my="xs" radius="xl" />
      </Center>
    </Card>
  );
}
