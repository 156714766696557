import "../App.css";
import { useLinksQuery, useProfileQuery } from "../queries/queries";
import { YouTubeCard } from "../components/YouTubeCard";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { Text } from "@mantine/core";
import { YouTubeSubscribeButton } from "./CTAButtons";
import { Strip } from "./Strip";
import { StripSkeleton } from "./StripSkeleton";
import { ErrorBoundary } from "@sentry/react";

export function YouTubeStrip() {
  const STRIP = "YouTube";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const { data: profileData } = useProfileQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);
  const youTubeChannelId = profileData?.youTubeChannelId;
  const youTubeChannelUrl = profileData?.youTubeChannelUrl;

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="youtube"
        nav="scroll-tall"
        gradientValues={["#FFFFFF"]}
        followButton={
          youTubeChannelUrl &&
          youTubeChannelId && (
            <div>
              <YouTubeSubscribeButton
                channelId={youTubeChannelId}
                dataLayout //Full
                subscriberCount
              />
            </div>
          )
        }
        title="Videos"
        icon={{ type: "fa", icon: faYoutube }}
        iconColor="#FF0000"
        titleColor="#000000"
      >
        {linksDataByStrip?.map((link) => {
          return (
            <ErrorBoundary key={link.id}>
              <YouTubeCard
                youTubeUrl={link.url}
                youTubeChannelId={link.youTubeChannelId}
              />
            </ErrorBoundary>
          );
        })}
      </Strip>
    );
  }
}
