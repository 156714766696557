import React, { useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import { Group, Avatar, Text, Autocomplete, Loader } from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";
import {
  // useBooksMutation,
  useCreateLinkMutation,
  useLinksQuery,
  useProfileQuery,
  // useDeleteLinkMutation,
} from "../queries/queries";
import { baseUrl } from "../api/links";
import { titleCase } from "title-case";

export function BooksSearchBar() {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200, {
    leading: false,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  // const [inputValue, setInputValue] = useState("");
  const STRIP = "Books";
  const LIMIT = 8;

  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchResults = async () => {
      setIsSearching(true);

      try {
        const response = await axios.get(
          `${baseUrl}/books-search?query=${debouncedSearchTerm}&limit=${LIMIT}`,
          { cancelToken: source.token }
        );
        setSearchResults(response.data);
      } catch (error) {
        if (axios.isCancel(error)) {
          console.log("Request canceled", error.message);
        } else {
          console.log(error);
        }
      } finally {
        setIsSearching(false);
      }
    };

    if (debouncedSearchTerm) {
      fetchResults();
    } else {
      setSearchResults([]);
    }

    // Cancel the request if component unmounts or the debouncedSearchTerm changes
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [debouncedSearchTerm]);

  const data = searchResults?.map((item, index) => {
    function generateOpenLibraryUrl(key) {
      const baseUrl = "https://openlibrary.org";

      if (key.startsWith("/works/") || key.startsWith("/books/")) {
        return `${baseUrl}${key}`;
      } else {
        throw new Error(
          'Invalid key format. Key must start with either "/works/" or "/books/".'
        );
      }
    }

    const image =
      item.cover_i &&
      `https://covers.openlibrary.org/b/id/${item.cover_i}-M.jpg`;

    // (`https://covers.openlibrary.org/b/id/${item.cover_i}-L.jpg
    // `https://covers.openlibrary.org/b/id/${item.cover_i}-S.jpg`;

    const authorName = item.author_name?.join(", ") || "";
    const title = titleCase(item.title);
    // const isbn = item.isbn ? item.isbn[0] : null;
    const ratingsCount = item.ratings_count;
    const ratingsAverage = item.ratings_average;
    const value = generateOpenLibraryUrl(item.key);
    const url = generateOpenLibraryUrl(item.key);
    const group = "Books";

    return {
      index,
      image,
      authorName,
      title,
      // isbn,
      ratingsCount,
      ratingsAverage,
      value,
      url,
      group,
    };
  });

  // const handleInputChange = (value) => {
  //   setSearchTerm(value);
  //   setInputValue(value);
  // };

  const handleItemSubmit = (item) => {
    setIsSaving(true);
    handleSave(item);
  };

  const AutoCompleteItem = forwardRef(
    (
      { image, authorName, title, ratingsCount, ratingsAverage, ...others },
      ref
    ) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Avatar src={image} />
          <div>
            <Text>{title}</Text>
            <Text size="xs" color="dimmed">
              {authorName}
            </Text>
          </div>
        </Group>
      </div>
    )
  );

  const {
    data: linksData,
    // isLoading: linksIsLoading,
    // isError: linksIsError,
  } = useLinksQuery();

  // Sort the links by urlPosition in descending order
  const sortedLinks = linksData?.sort((a, b) => b.urlPosition - a.urlPosition);

  // Get the highest existing urlPosition number and add 1
  const nextUrlPosition =
    sortedLinks?.length > 0 ? sortedLinks[0].urlPosition + 1 : 0;

  const newInputSuccess = () => {
    setIsSaving(false);
    setSearchTerm("");
  };

  const createLinkMutation = useCreateLinkMutation(newInputSuccess);
  // const booksMutation = useBooksMutation();

  function handleSave(item) {
    createLinkMutation.mutate({
      url: item.url,
      userId: destinationUserId,
      strip: STRIP,
      title: item.title,
      // isbn: item.isbn,
      authorName: item.authorName,
      coverImageUrl: item.image,
      ratingsAverage: item.ratingsAverage,
      ratingsCount: item.ratingsCount,
      urlPosition: nextUrlPosition,
    });

    // booksMutation.mutate({
    //   isbn: item.isbn,
    //   userId: loggedInUserId,
    // });
  }

  return (
    <>
      <Autocomplete
        label={`Search for Books`}
        placeholder="Type to search"
        disabled={isSaving}
        initiallyOpened={false}
        itemComponent={AutoCompleteItem}
        data={data}
        value={searchTerm}
        limit={LIMIT * 2}
        rightSection={isSearching && <Loader size="xs" />}
        filter={() => true}
        onChange={setSearchTerm}
        onItemSubmit={handleItemSubmit}
        mt={10}
        maxDropdownHeight={400}
        styles={{
          input: {
            fontSize: "16px",
            height: "20px",
            lineHeight: "20px",
          },
        }}
      />
    </>
  );
}
