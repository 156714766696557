import React, { useState, useEffect } from "react";
import axios from "axios";
import { forwardRef } from "react";
import {
  Box,
  Group,
  Avatar,
  Autocomplete,
  Loader,
  Paper,
  ScrollArea,
  Text,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";

export function CompaniesSearchBar({ form, experienceType }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 500, {
    leading: true,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    const fetchResults = async () => {
      setIsSearching(true);
      try {
        const searchUrl = `https://autocomplete.clearbit.com/v1/companies/suggest?query=${debouncedSearchTerm}`;
        const response = await axios.get(searchUrl);

        const companiesWithLogos = response.data.map((company) => {
          const logoURL = `https://logo.clearbit.com/${company.domain}`;
          return { ...company, logo: logoURL };
        });

        setSearchResults(companiesWithLogos);
        setIsSearching(false);
      } catch (error) {
        console.log(error);
        setIsSearching(false);
      }
    };

    if (debouncedSearchTerm) {
      fetchResults();
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  const handleInputChange = (value) => {
    form.setFieldValue("company", value);
    setSearchTerm(value);
    setInputValue(value);
  };

  const data = searchResults?.map((company, index) => ({
    key: index,
    name: company.name,
    coverImageUrl: company.logo,
    domain: company.domain,
  }));

  console.log(data);

  const AutoCompleteItem = forwardRef(
    ({ name, coverImageUrl, domain, industryMain, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group>
          <Avatar src={coverImageUrl} />
          <Box>
            <Text size="sm">{name}</Text>
            <Text size="xs" color="dimmed">
              {domain}
            </Text>
          </Box>
        </Group>
      </div>
    )
  );

  const AttributionFooter = () => (
    <Paper padding="xs" shadow="xs" styles={{ borderTop: "1px solid #e5e5e5" }}>
      <Text size="xs" align="center" color="dimmed">
        Logos provided by{" "}
        <a
          href="https://clearbit.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Clearbit
        </a>
      </Text>
    </Paper>
  );

  return (
    <Autocomplete
      label={experienceType === "Education" ? "School" : "Company"}
      placeholder={
        experienceType === "Education" ? "Vanderbilt University" : "Hooli"
      }
      required
      limit={10}
      initiallyOpened={false}
      itemComponent={AutoCompleteItem}
      data={data}
      value={inputValue}
      rightSection={isSearching && <Loader size="xs" />}
      filter={() => true}
      mb="sm"
      onItemSubmit={(company) => {
        form.setFieldValue("company", company.name);
        form.setFieldValue("coverImageUrl", company.coverImageUrl);
        form.setFieldValue("url", company.domain);
        setSearchTerm("");
        setInputValue("");
      }}
      {...form.getInputProps("company", "coverImageUrl", "url")} //How do I Make this sschool when it's education form...
      onChange={(value) => handleInputChange(value)}
      dropdownComponent={forwardRef(({ children, ...props }, ref) => (
        <ScrollArea {...props} viewportRef={ref}>
          {children}
          <AttributionFooter />
        </ScrollArea>
      ))}
    />
  );
}
