import { Image } from "@mantine/core";
import { Strip } from "../Strip";
import { TikTokEmbed } from "react-social-media-embed";
import { ErrorBoundary } from "@sentry/react";
import { TikTokProfileEmbed } from "../TikTokProfileEmbed";

export function DummyTikTokStrip() {
  const linksDataByStrip = [
    { url: "https://www.tiktok.com/@420doggface208/video/6876424179084709126" },
    { url: "https://www.tiktok.com/@khaby.lame/video/6965122051178892549" },
  ];

  return (
    <Strip
      id="tiktok" //Lowercase
      nav="full"
      gradientValues={["#FFFFFF"]}
      title="TikTok"
      titleColor="#000000"
      icon={{
        type: "image",
        icon: <Image src="/TikTok.png" height={36} width={36} />,
      }}
      iconColor="white"
      // iconLink="YOUR TikTok PROFILE...."TBU
    >
      <ErrorBoundary>
        <TikTokProfileEmbed url="https://www.tiktok.com/@khaby.lame" />
      </ErrorBoundary>
    </Strip>
  );
}
