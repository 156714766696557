import { Container, Flex, Stack, Text, Title } from "@mantine/core";
import { PricingCard } from "./PricingCard";

export function SectionPricing() {
  return (
    <Stack
      p="sm"
      sx={{
        // backgroundColor: "#EFF6FE",
        backgroundColor: "#F6F9FC",
      }}
      id="pricing"
      py={60}
    >
      <Title order={1} color="#444" ta="center" mb="md">
        Our <span className="gradient-text">pricing</span>
      </Title>
      <Flex justify="center" gap="md" wrap="wrap">
        <PricingCard
          priceName="Free"
          feature1="Up to 4 strips"
          feature2="Up to 20 links"
          price={0}
        />
        <PricingCard
          priceName="Starter"
          feature1="Unlimited strips"
          feature2="Unlimited links"
          price={5}
        />
        <PricingCard
          priceName="Premium"
          feature1="Unlimited strips"
          feature2="Unlimited links"
          feature3
          price={8}
        />
      </Flex>
      <Container p="xs">
        <Text size="sm" color="dimmed">
          Tapistree is currently in beta. In exchange for putting up with our
          bugs, you will receive hands-on support and your feature requests will
          be prioritized.
        </Text>
      </Container>
    </Stack>
  );
}
