import React from "react";
import {
  Card,
  Image,
  Text,
  Rating,
  UnstyledButton,
  Anchor,
} from "@mantine/core";
// import { TAG } from "./AmazonCard";

export function GoodreadsCard({
  image,
  title,
  authorName,
  userRating,
  amazonAffiliateTag,
}) {
  const encodedTitle = encodeURIComponent(title);

  return (
    <div className="card-book">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <div style={{ display: "flex", alignItems: "center", height: "100px" }}>
          <UnstyledButton>
            <Anchor
              target="_blank"
              href={
                amazonAffiliateTag
                  ? `https://www.amazon.com/s?k=${encodedTitle}&tag=${amazonAffiliateTag}`
                  : `https://www.amazon.com/s?k=${encodedTitle}`
              }
            >
              <Image
                src={image}
                width={60}
                height={100}
                radius="md"
                alt={title}
                withPlaceholder
                imageProps={{
                  loading: "lazy",
                }}
              />
            </Anchor>
          </UnstyledButton>
          <div style={{ paddingLeft: "10px" }}>
            <Text weight={500} lineClamp={2}>
              {title}
            </Text>
            <Text size="sm" color="dimmed" lineClamp={1}>
              by {authorName}
            </Text>
            {userRating && (
              <Rating
                size="sm"
                color="orange"
                value={Number(userRating).toFixed(1)}
                readOnly
                fractions={2}
              />
            )}
          </div>
        </div>
      </Card>
    </div>
  );
}
