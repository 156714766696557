import { useEffect, useState } from "react";
import {
  UnstyledButton,
  Checkbox,
  Text,
  Image,
  Modal,
  Button,
  SimpleGrid,
  ThemeIcon,
  createStyles,
  rem,
  ScrollArea,
  Badge,
  Flex,
} from "@mantine/core";
import { useUncontrolled } from "@mantine/hooks";
import {
  useAddStripMutation,
  useProfileQuery,
  useStripsQuery,
} from "../queries/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAirbnb,
  // faAmazon,
  faGoodreads,
  faGithub,
  faMedium,
  faSpotify,
  faYoutube,
  faItunesNote,
} from "@fortawesome/free-brands-svg-icons";
import { TwitterIcon } from "@mantine/ds";

import { dashesToSpaces } from "../utils/dahesToSpaces";
import { IconBrowser, IconPlus } from "@tabler/icons-react";
import { v4 as uuidv4 } from "uuid";

// const slidesIcon =
//   "https://www.gstatic.com/images/branding/product/1x/slides_2020q4_48dp.png";

const useStyles = createStyles((theme, { checked }) => ({
  button: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    transition: "background-color 150ms ease, border-color 150ms ease",
    border: `${rem(1)} solid ${
      checked
        ? theme.fn.variant({ variant: "outline", color: theme.primaryColor })
            .border
        : theme.colorScheme === "dark"
        ? theme.colors.dark[8]
        : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.xs,
    backgroundColor: checked
      ? theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .background
      : theme.colorScheme === "dark"
      ? theme.colors.dark[8]
      : theme.white,
  },

  body: {
    flex: 1,
    marginLeft: theme.spacing.xs,
    maxWidth: "280px",
  },
}));

export function StripCheckbox({
  checked,
  defaultChecked,
  popular,
  paid,
  onChange,
  className,
  strip,
  alternateStripName,
  // icon,
  // iconBackgroundColor,
  description,
  ...others
}) {
  const [value, handleChange] = useUncontrolled({
    value: checked,
    defaultValue: defaultChecked,
    finalValue: false,
    onChange,
  });

  const handleClick = () => {
    handleChange(!value);
    onChange && onChange(!value, strip, description);
  };

  const { classes, cx } = useStyles({ checked: value });

  const icon = stripOptions.find(
    (item) => item.strip === strip || item.alternateStripName === "Website"
  )?.icon;

  const iconBackgroundColor = stripOptions.find(
    (item) => item.strip === strip || item.alternateStripName === "Website"
  )?.iconBackgroundColor;

  return (
    <UnstyledButton
      {...others}
      onClick={handleClick}
      className={cx(classes.button, className)}
    >
      <ThemeIcon
        color={iconBackgroundColor ? iconBackgroundColor : "#FFFFFF"}
        size="xl"
      >
        {icon}
      </ThemeIcon>
      <div className={classes.body}>
        <Flex align="center">
          <Text weight={500} size="sm">
            {alternateStripName ? alternateStripName : dashesToSpaces(strip)}
          </Text>
          {popular && (
            <Badge size="xs" ml={5} color="green">
              Popular
            </Badge>
          )}
          {paid && (
            <Badge size="xs" ml={5} color="yellow">
              Paid
            </Badge>
          )}
        </Flex>
        <Text
          color="dimmed"
          size="xs"
          sx={{ lineHeight: 1, wordBreak: "break-all" }}
          mt={5}
          lineClamp={2}
          truncate
          // maw={180}
          mr={4}
          style={{ whiteSpace: "pre-line" }}
        >
          {description}
        </Text>
      </div>
      <Checkbox
        // defaultChecked={defaultChecked}
        checked={value}
        onChange={() => {}}
        tabIndex={-1}
        styles={{ input: { cursor: "pointer" } }}
        m={4}
      />
    </UnstyledButton>
  );
}

export const stripOptions = [
  {
    strip: "About",
    icon: <span className="add-strip-icon">👋</span>,
    iconBackgroundColor: "gray[3]",
    description: "Add a brief bio",
    popular: true,
  },
  {
    strip: "Favorites",
    icon: <span className="add-strip-icon">⭐</span>,
    description: "Add a collection of websites",
  },
  {
    strip: "LinkedIn",
    icon: <Image src="/LinkedIn.png" alt="LinkedIn" width={40} height={40} />,
    description: "Add education & experience",
    // popular: true,
    // paid: true,
  },
  {
    strip: "GitHub",
    icon: <FontAwesomeIcon icon={faGithub} size="2x" />,
    iconBackgroundColor: "#24292F",
    description: "Add skills, commit activity, and more",
  },
  {
    strip: "Instagram",
    icon: <Image src="/Instagram.png" alt="Instagram" width={40} height={40} />,
    description: "Add your favorite posts",
  },
  {
    strip: "Facebook",
    icon: (
      <Image src="/Facebook-small.png" alt="Facebook" width={40} height={40} />
    ),
    description: "Add your favorite posts",
  },
  {
    strip: "TikTok",
    icon: <Image src="/TikTok.png" alt="TikTok" width={40} height={40} />,
    description: "Add your favorite posts",
    popular: true,
  },
  {
    strip: "Strava",
    icon: <Image src="/strava-icon.svg" alt="Strava" width={40} height={40} />,
    description: "Add your Strava activity",
  },
  {
    strip: "Eventbrite",
    alternateStripName: "Eventbrite (Organizer)",
    icon: (
      <Image
        src="/eventbrite-organizer-icon.jpeg"
        alt="Eventbrite"
        width={40}
        height={40}
        radius="sm"
      />
    ),
    description: "Add your Eventbrite events",
  },
  {
    strip: "Twitter",
    icon: <TwitterIcon size="1.5rem" color="white" />,
    iconBackgroundColor: "#00ACEE",
    description: "Add your favorite tweets",
    popular: true,
  },
  // {
  //   strip: "X",
  //   icon: (
  //     <Image src="/x-logo.png" alt="X" width={40} height={40} padding="md" />
  //   ),
  //   iconBackgroundColor: "#000000",
  //   description: "Add your favorite posts",
  // },
  {
    strip: "Spotify",
    icon: (
      <FontAwesomeIcon
        icon={faSpotify}
        size="2x"
        style={{ color: "#45DB74" }}
      />
    ),
    iconBackgroundColor: "#000000",
    description: "Add podcasts and albums",
    popular: true,
  },
  {
    strip: "Substack",
    icon: (
      <Image src="/substack-icon.png" alt="Substack" width={40} height={40} />
    ),
    description: "Add Substack sign-up embed",
  },
  {
    strip: "Substack2",
    icon: (
      <Image src="/substack-icon.png" alt="Substack" width={40} height={40} />
    ),
    description: "Add a 2nd Substack sign-up embed",
  },
  {
    strip: "Beehiiv",
    icon: <Image src="/beehiiv.png" alt="Beehiiv" width={40} height={40} />,
    description: "Add Beehiiv sign-up embed",
  },
  {
    strip: "Beehiiv2",
    icon: <Image src="/beehiiv.png" alt="Beehiiv" width={40} height={40} />,
    description: "Add a 2nd Beehiiv sign-up embed",
  },
  {
    strip: "Apple-Music",
    icon: <FontAwesomeIcon icon={faItunesNote} size="2x" />,
    iconBackgroundColor: "#F5334D",
    description: "Add your favorite albums & songs",
  },
  {
    strip: "Apple-Podcasts",
    icon: (
      <Image src="/apple-podcasts.png" alt="Podcasts" width={40} height={40} />
    ),
    description: "Add your favorite podcasts",
  },
  {
    strip: "YouTube",
    icon: (
      <FontAwesomeIcon
        icon={faYoutube}
        size="2x"
        style={{
          color: "#FF0000",
        }}
      />
    ),
    iconBackgroundColor: "white",
    description: "Add your favorite videos",
    popular: true,
  },
  {
    strip: "Books",
    icon: <FontAwesomeIcon icon={faGoodreads} size="2x" />,
    iconBackgroundColor: "#D6D0C4",
    description: "Add your favorite books",
  },
  {
    strip: "Writing",
    icon: <span className="add-strip-icon">📝</span>,
    iconBackgroundColor: "#FAF3F0",
    description: "Add your favorite essays or articles",
  },
  {
    strip: "Airbnb",
    icon: <FontAwesomeIcon icon={faAirbnb} size="2x" />,
    iconBackgroundColor: "#FF385C",
    description: "Add Airbnbs",
  },
  {
    strip: "Medium",
    icon: <FontAwesomeIcon icon={faMedium} size="2x" />,
    iconBackgroundColor: "#000000",
    description: "Add Medium posts",
  },
  {
    strip: "Photos",
    icon: <span className="add-strip-icon">📸</span>,
    iconBackgroundColor: "tan",
    description: "Add photos",
  },
  {
    strip: `Website${uuidv4()}`,
    alternateStripName: `Website`,
    icon: <IconBrowser size={40} stroke={1.5} color="#339AF0" />,
    description: "Add any website as its own strip",
    popular: true,
  },
  // {
  //   strip: `Website-Collection`,
  //   icon: <IconBrowser size={40} stroke={1.5} color="#339AF0" />,
  //   description: "Add multiple pages from a website to a carousel",
  //   popular: true,
  // },

  // {
  //   strip: "Movies",
  //   icon: "🎥",
  //   iconBackgroundColor: "yellow",
  //   description: "Add your favorite movies",
  // },
  // {
  //   strip: "Custom",
  //   icon: "❔",
  //   iconBackgroundColor: "gray[3]",
  //   description: "Add any link!",
  // },
  // {
  //   strip: "Slides",
  //   icon: <Image src={slidesIcon} alt="LinkedIn" width={40} height={40} />,
  //   iconBackgroundColor: "#FBBC04",
  //   description: "Add a Google Slides presentation",
  // },
  // {
  //   strip: "Events",
  //   icon: "📅",
  //   iconBackgroundColor: "indigo[3]",
  //   description: "Add events you're attending",
  // },
  // {
  //   strip: "Resume",
  //   icon: "📝",
  //   iconBackgroundColor: "white",
  //   description: "Add your resume",
  // },
  // {
  //   strip: "PowerPoint",
  //   icon: <FontAwesomeIcon icon={faFilePowerpoint} size="2x" />,
  //   iconBackgroundColor: "#C43E1C",
  //   description: "Add your presentations & decks",
  // },
  // {
  //   strip: "Excel",
  //   icon: <FontAwesomeIcon icon={faFileExcel} size="2x" />,
  //   iconBackgroundColor: "#138146",
  //   description: "Add your spreadsheets",
  // },
  // {
  //   strip: "Amazon",
  //   icon: <FontAwesomeIcon icon={faAmazon} size="2x" />,
  //   iconBackgroundColor: "#444654",
  //   description: "Add products",
  // },
  // {
  //   strip: "Website",
  //   icon: <IconBrowser size={40} stroke={1.5} color="#339AF0" />,
  //   description: "Add any website as its own strip",
  //   // allowDuplicate: true,
  // },
];

export const DEFAULT_CHECKED_STRIPS = [
  "About",
  "Books",
  "Instagram",
  "Twitter",
  "Spotify",
];

export function AddStrips() {
  const [opened, setOpened] = useState(false);

  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  const { data: stripsData } = useStripsQuery(destinationUserId);

  const [websiteUUID, setWebsiteUUID] = useState("");

  // Filter out the stripOptions based on the stripsData, allowing duplicates for options with allowDuplicate set to true
  let availableStrips = stripOptions
    ?.filter(
      (option) =>
        option.allowDuplicate ||
        !stripsData?.some((dataStrip) => dataStrip.strip === option.strip)
    )
    .sort((a, b) => a.strip.localeCompare(b.strip));

  const [checkedItems, setCheckedItems] = useState([]);

  const handleCheckboxChange = (isChecked, strip) => {
    if (isChecked) {
      setCheckedItems([...checkedItems, strip]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== strip));
    }
  };

  const strips = availableStrips.map((strip) => {
    if (strip.alternateStripName === "Website" && websiteUUID) {
      const uniqueStripIdentifier = strip.strip + websiteUUID;

      return (
        <StripCheckbox
          {...strip}
          key={uniqueStripIdentifier}
          strip={uniqueStripIdentifier}
          onChange={handleCheckboxChange}
        />
      );
    } else {
      return (
        <StripCheckbox
          {...strip}
          key={strip.strip}
          onChange={handleCheckboxChange}
        />
      );
    }
  });

  const addStripMutation = useAddStripMutation();

  function addStrip(strips) {
    // Get the highest existing stripPosition number and add 1
    const nextStripPosition =
      (stripsData && stripsData.length > 0
        ? stripsData[stripsData.length - 1].stripPosition
        : 0) + 1 || 0;

    const stripsDataWithPositionAndVisible = strips?.map((strip, index) => {
      return {
        strip: strip,
        stripPosition: nextStripPosition + index,
        visible: true,
        showFollowButton:
          strip === "LinkedIn" || strip === "Strava" ? true : false,
      };
    });

    addStripMutation.mutate({
      userId: destinationUserId,
      stripsData: stripsDataWithPositionAndVisible,
    });
  }

  const handleAddButtonClick = () => {
    addStrip(checkedItems); //Submits an array of strips.
    setOpened(false);
    setCheckedItems([]);
  };

  useEffect(() => {
    // Regenerate UUID when the modal is opened
    if (opened) {
      setWebsiteUUID(uuidv4());
    } else {
      setWebsiteUUID(""); // Reset UUID when modal is closed
    }
  }, [opened]);

  return (
    <>
      <Modal
        title="Add a strip"
        size="auto"
        opened={opened}
        onClose={() => {
          setOpened(false);
          setCheckedItems([]);
        }}
      >
        <ScrollArea h="70vh" type="auto" offsetScrollbars>
          <SimpleGrid
            cols={2}
            breakpoints={[
              { maxWidth: "md", cols: 2 },
              { maxWidth: "sm", cols: 1 },
            ]}
          >
            {strips}
          </SimpleGrid>
        </ScrollArea>
        <Button
          fullWidth
          mt="xs"
          onClick={handleAddButtonClick}
          disabled={checkedItems.length === 0}
        >
          Add
        </Button>
      </Modal>
      <Button
        fullWidth
        color="blue"
        radius="md"
        onClick={() => setOpened(true)}
        sx={{ boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)" }}
      >
        <IconPlus />
        <Text size="lg">Add a Strip</Text>
      </Button>
    </>
  );
}
