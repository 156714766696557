import React from "react";
import { Card, Text } from "@mantine/core";

export function EventCard({ event, date, address }) {
  return (
    <div className="card">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <div style={{ display: "flex", alignItems: "center", height: "100px" }}>
          <span style={{ fontSize: "3em" }}>🗓️</span>
          <div style={{ paddingLeft: "10px" }}>
            <Text weight={500} style={{ whiteSpace: "pre-wrap" }}>
              {event}
            </Text>
            <Text size="sm" color="dimmed">
              {date}
            </Text>
            <Text size="sm" color="dimmed">
              {address}
            </Text>
          </div>
        </div>
      </Card>
    </div>
  );
}
