import "../App.css";
import { Image, Text } from "@mantine/core";
import { Strip } from "./Strip";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";
import { SubstackEmbed } from "./SubstackEmbed";
import { ErrorBoundary } from "@sentry/react";

export function SubstackStrip() {
  const STRIP = "Substack";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.find((item) => item.strip === STRIP);

  const substackStripColor = linksDataByStrip?.substackStripColor || "#FEFFFF";
  const substackTitleColor = linksDataByStrip?.substackTitleColor || "#404040";

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <>
        <Strip
          id="substack"
          nav="no-scroll"
          gradientValues={[substackStripColor]}
          title="Substack"
          titleColor={substackTitleColor}
          icon={{
            type: "image",
            icon: (
              <Image src="/substack-icon.png" height={36} width={36} mt={-2} />
            ),
          }}
        >
          <ErrorBoundary>
            {linksDataByStrip?.url ? (
              <SubstackEmbed url={linksDataByStrip.url} />
            ) : (
              <></>
            )}
          </ErrorBoundary>
        </Strip>
      </>
    );
  }
}

export function SubstackStrip2() {
  const STRIP = "Substack2";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.find((item) => item.strip === STRIP);

  const substackStripColor = linksDataByStrip?.substackStripColor || "#FEFFFF";
  const substackTitleColor = linksDataByStrip?.substackTitleColor || "#404040";

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <>
        <Strip
          id="substack2"
          nav="no-scroll"
          gradientValues={[substackStripColor]}
          title="Substack"
          titleColor={substackTitleColor}
          icon={{
            type: "image",
            icon: (
              <Image src="/substack-icon.png" height={36} width={36} mt={-2} />
            ),
          }}
        >
          <ErrorBoundary>
            {linksDataByStrip?.url ? (
              <SubstackEmbed url={linksDataByStrip.url} />
            ) : (
              <></>
            )}
          </ErrorBoundary>
        </Strip>
      </>
    );
  }
}
