import "../App.css";
import { useLinksQuery, useProfileQuery } from "../queries/queries";
import { Image } from "@mantine/core";
import { Strip } from "./Strip";
import { ErrorBoundary } from "@sentry/react";
import { StravaFollowButton } from "./CTAButtons";
import { StravaCalendar } from "./StravaCalendar";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { baseUrl } from "../api/links";
import axios from "axios";
import { convertDistance } from "../utils/convertDistance";

export function StravaStrip() {
  const STRIP = "Strava";

  const { data: linksData } = useLinksQuery();
  const { data: profileData } = useProfileQuery();
  const { username } = useParams();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  const profileUrl =
    linksDataByStrip?.find((link) => link.isProfileUrl)?.url ||
    linksDataByStrip?.[0]?.url ||
    "";

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  function addValueForOneYearAgo(data) {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const oneYearAgoStr = oneYearAgo.toISOString().split("T")[0];

    const existingEntry = data.find((entry) => entry.date === oneYearAgoStr);
    if (!existingEntry) {
      data.push({ date: oneYearAgoStr, count: 0, level: 0 });
    }

    // Optionally sort the data by date if needed
    data.sort((a, b) => new Date(a.date) - new Date(b.date));
    return data;
  }

  async function getStravaData(username) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/get-strava-data/${username}?timeFrame=LTM`
      );

      const updatedData = addValueForOneYearAgo(
        response.data.coloredHeatmapData
      );

      setData(updatedData);
    } catch (error) {
      console.error("Error while fetching Strava data:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  // Sum the count values
  const totalDistance = data?.reduce((acc, item) => acc + item?.count, 0);
  // Convert the distance unit from kilometers to miles
  const convertedDistance = convertDistance(
    totalDistance,
    profileData?.distanceUnit
  );

  // Round the total
  let roundedTotalDistance = Math.round(convertedDistance);

  useEffect(() => {
    // Check if username exists before making the API call
    if (username) {
      getStravaData(username);
    }
  }, [username]);

  // Render nothing if username is not provided
  if (!username) {
    return null;
  }

  return (
    <Strip
      id="strava" //Lowercase
      nav="scroll"
      gradientValues={["#FFFFFF"]}
      title={
        <Image
          src="/api_logo_pwrdBy_strava_stack_light.svg"
          width={140}
          mt={-3}
          ml={-5}
        />
      }
      titleColor="#000000"
      followButton={<StravaFollowButton link={profileUrl} />}
      icon={{
        type: "image",
        icon: <Image src="/strava-icon.svg" height={32} width={32} mt={-2} />,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          color: "white",
        }}
      >
        <div style={{ marginLeft: "10px" }}>
          <ErrorBoundary>
            <StravaCalendar
              username={username}
              loading={loading}
              data={data}
              roundedTotalDistance={roundedTotalDistance}
              distanceUnit={profileData?.distanceUnit}
              minWidth="700px"
            />
          </ErrorBoundary>
        </div>
      </div>
    </Strip>
  );
}
