import React, { useState } from "react";
import {
  ActionIcon,
  Button,
  createStyles,
  Flex,
  Loader,
  ThemeIcon,
  Menu,
  Group,
  rem,
  Textarea,
} from "@mantine/core";
import {
  IconCheck,
  IconGripVertical,
  IconTrash,
  // IconPencil,
  IconDots,
} from "@tabler/icons-react";
import {
  useCreateLinkMutation,
  useLinksQuery,
  useDeleteLinkMutation,
  useProfileQuery,
} from "../queries/queries";

const useStyles = createStyles((theme) => ({
  item: {},
  itemDragging: {
    boxShadow: theme.shadows.sm,
  },
  symbol: {
    fontSize: rem(30),
    fontWeight: 700,
    width: rem(60),
  },
  dragHandle: {
    display: "flex",
    alignItems: "center",
    paddingRight: 4,
  },
  hidden: {
    visibility: "hidden",
  },
}));

export function MyTextArea({
  id,
  defaultUrl,
  isProfileUrl,
  defaultSaved,
  defaultVerified,
  placeholder,
  strip,
  provided,
  snapshot,
}) {
  const [saved, setSaved] = useState(defaultSaved);
  const [verified, setVerified] = useState(defaultVerified);
  const [inputValue, setInputValue] = useState(defaultUrl);
  const [newInput, setNewInput] = useState(true);

  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  const newInputSuccess = () => setInputValue("");
  const existingInputSuccess = () => {
    setSaved(true);
    setVerified(true);
  };

  const {
    data: linksData,
    // isLoading: linksIsLoading,
    // isError: linksIsError,
  } = useLinksQuery();

  const createLinkMutation = useCreateLinkMutation(
    newInput ? newInputSuccess : existingInputSuccess
  );
  const deleteLinkMutation = useDeleteLinkMutation();

  function handleSave(inputValue) {
    // Sort the links by urlPosition in descending order
    const linksDataFilteredByStrip = linksData?.filter(
      (item) => item.strip === strip
    );
    // .sort((a, b) => a.urlPosition - b.urlPosition);

    //Get the highest existing urlPosition number and add 1.
    const highestUrlPosition = linksDataFilteredByStrip.reduce(
      (max, linkData) => {
        return Math.max(max, linkData.urlPosition);
      },
      0
    );

    const isFirstEntry = linksDataFilteredByStrip?.length === 0;
    const nextUrlPosition = isFirstEntry ? 0 : highestUrlPosition + 1;

    // Base mutation, whether it's new input or an edit.
    const baseMutationObject = {
      id: id,
      url: inputValue,
      isProfileUrl: isProfileUrl,
      userId: destinationUserId,
      strip: strip,
    };

    // If it's a new input, append the urlPosition property
    const finalMutationObject = newInput
      ? {
          ...baseMutationObject,
          urlPosition: nextUrlPosition,
        }
      : baseMutationObject;

    createLinkMutation.mutate(finalMutationObject);
  }

  function editInput() {
    setSaved(false);
    setVerified(false);
  }

  async function handleDelete(id) {
    deleteLinkMutation.mutate({
      id: id,
    });
  }

  const { classes, cx } = useStyles();

  return (
    <div
      className={snapshot?.isDragging ? classes.itemDragging : ""}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      <Flex align="center" py={5}>
        {!isProfileUrl && (
          <div
            {...provided?.dragHandleProps}
            className={cx(classes.dragHandle, {
              [classes.hidden]: !defaultSaved,
            })}
          >
            <IconGripVertical
              size="1.05rem"
              stroke={1.5}
              color="gray"
              style={{
                cursor: "grab",
              }}
            />
          </div>
        )}
        <Textarea
          minRows={2}
          placeholder={placeholder}
          disabled={saved}
          mr={3}
          rightSectionWidth={30}
          rightSection={
            createLinkMutation.isLoading ? (
              <Loader size="xs" />
            ) : verified ? (
              <ThemeIcon color="green" size="xs" radius="xl">
                <IconCheck />
              </ThemeIcon>
            ) : (
              <ThemeIcon color="gray" size="xs" radius="xl">
                <IconCheck />
              </ThemeIcon>
            )
          }
          value={inputValue}
          onChange={(event) => setInputValue(event.currentTarget.value)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSave(inputValue);
            }
          }}
          styles={{
            root: {
              fontSize: "16px",
              flexGrow: "1 !important",
            },
            input: {
              fontSize: "16px",
              lineHeight: "20px",
            },
          }}
        />
        <Flex align="center">
          {saved ? (
            <Button
              // color={"gray[1]"}
              onClick={() => {
                editInput();
                setNewInput(false);
              }}
              style={{ marginRight: "8px" }}
            >
              Edit
            </Button>
          ) : (
            <Button
              color="blue"
              onClick={() => handleSave(inputValue)}
              disabled={createLinkMutation.isLoading || inputValue === ""}
              style={{ padding: "0px 16px", marginRight: "8px" }}
            >
              Save
            </Button>
          )}
          <Group spacing={0} position="right">
            {/* <ActionIcon>
              <IconPencil size="1rem" stroke={1.5} />
            </ActionIcon> */}
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <ActionIcon>
                  <IconDots size="1rem" stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconTrash size="1rem" stroke={1.5} />}
                  color="red"
                  onClick={() => handleDelete(id)}
                  disabled={deleteLinkMutation.isLoading}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Flex>
      </Flex>
    </div>
  );
}
