import React, { useState } from "react";
import {
  ActionIcon,
  Button,
  createStyles,
  Flex,
  Loader,
  TextInput,
  ThemeIcon,
  Menu,
  Group,
  rem,
} from "@mantine/core";
import {
  IconCheck,
  IconGripVertical,
  IconTrash,
  // IconPencil,
  IconDots,
} from "@tabler/icons-react";
import { useProfileQuery, useProfileMutation } from "../queries/queries";
import { useParams } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  item: {},

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  symbol: {
    fontSize: rem(30),
    fontWeight: 700,
    width: rem(60),
  },
  dragHandle: {
    display: "flex",
    alignItems: "center",
    paddingRight: 4,
  },
  hidden: {
    visibility: "hidden",
  },
}));

export function ProfileInput({
  id,
  defaultUrl,
  isProfileUrl,
  isNotDraggable,
  defaultSaved,
  defaultVerified,
  placeholder,
  regexCheck,
  errorMessage,
  provided,
  snapshot,
}) {
  const [saved, setSaved] = useState(defaultSaved);
  const [verified, setVerified] = useState(defaultVerified);
  const [inputValue, setInputValue] = useState(defaultUrl);
  const [isError, setIsError] = useState(false);

  const profileMutation = useProfileMutation();
  const { username } = useParams();

  function handleSave(inputValue) {
    profileMutation.mutate({
      username: username,
      updates: {
        youTubeChannelUrl: inputValue,
      },
    });

    setSaved(true);
  }

  function editInput() {
    setSaved(false);
    setVerified(false);
  }

  async function handleDelete() {
    profileMutation.mutate({
      username: username,
      updates: {
        youTubeChannelUrl: null,
        youTubeChannelId: null,
      },
    });
  }

  // Update the isError state whenever value changes
  function checkInput(value) {
    const match = value?.match(regexCheck);
    setIsError(!match);
  }

  const { classes, cx } = useStyles();

  return (
    <div
      className={snapshot?.isDragging ? classes.itemDragging : ""}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      <Flex align="center" py={5}>
        {!isNotDraggable && !isProfileUrl && (
          <div
            {...provided?.dragHandleProps}
            className={cx(classes.dragHandle, {
              [classes.hidden]: !defaultSaved,
            })}
          >
            <IconGripVertical
              size="1.05rem"
              stroke={1.5}
              color="gray"
              style={{
                cursor: "grab",
              }}
            />
          </div>
        )}
        <TextInput
          //   label={inputLabels && inputLabels[1]}
          placeholder={placeholder}
          disabled={saved}
          error={isError ? errorMessage : null}
          mr={3}
          rightSectionWidth={30}
          rightSection={
            profileMutation.isLoading ? (
              <Loader size="xs" />
            ) : verified ? (
              <ThemeIcon color="green" size="xs" radius="xl">
                <IconCheck />
              </ThemeIcon>
            ) : (
              <ThemeIcon color="gray" size="xs" radius="xl">
                <IconCheck />
              </ThemeIcon>
            )
          }
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.currentTarget.value);
            checkInput(event.currentTarget.value);
          }}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              handleSave(inputValue);
            }
          }}
          styles={{
            root: {
              fontSize: "16px",
              flexGrow: "1 !important",
            },
            input: {
              fontSize: "16px",
              height: "20px",
              lineHeight: "20px",
            },
          }}
        />
        <Flex align="center">
          {saved ? (
            <Button
              onClick={() => {
                editInput();
              }}
              style={{ marginRight: "8px" }}
            >
              Edit
            </Button>
          ) : (
            <Button
              color="blue"
              onClick={() => handleSave(inputValue)}
              disabled={
                profileMutation.isLoading || inputValue === "" || isError
              }
              style={{ padding: "0px 16px", marginRight: "8px" }}
            >
              Save
            </Button>
          )}
          <Group spacing={0} position="right">
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
              className={cx(classes.dragHandle, {
                [classes.hidden]: !defaultSaved,
              })}
            >
              <Menu.Target>
                <ActionIcon>
                  <IconDots size="1rem" stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconTrash size="1rem" stroke={1.5} />}
                  color="red"
                  onClick={() => handleDelete(id)}
                  disabled={profileMutation.isLoading || inputValue === ""}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Flex>
      </Flex>
    </div>
  );
}
