import { faItunesNote } from "@fortawesome/free-brands-svg-icons";
import { Strip } from "../Strip";
import { AppleMusicEmbed } from "../AppleMusicEmbed";

export function DummyAppleMusicStrip() {
  return (
    <Strip
      id="apple-music"
      nav="scroll-tall"
      gradientValues={["#FAFAFA"]}
      title="Apple Music"
      titleColor="#212121"
      icon={{ type: "fa", icon: faItunesNote }}
      iconColor="#F5334D"
    >
      <AppleMusicEmbed url="https://music.apple.com/us/album/1989-taylors-version-deluxe/1713845538" />
    </Strip>
  );
}
