import { Strip } from "../Strip";
import { faMedium } from "@fortawesome/free-brands-svg-icons";
import { MediumCard } from "../MediumCard";

export function DummyMediumStrip() {
  const linksDataByStrip = [
    {
      id: "7968c218-f615-4cfa-b284-5943fa4a751b",
      createdAt: "2023-11-19T01:10:00.713Z",
      updatedAt: "2023-11-19T01:10:00.713Z",
      url: "https://medium.com/conquering-corporate-america/10-tricks-to-appear-smart-during-meetings-27b489a39d1a",
      urlPosition: 4,
      isProfileUrl: null,
      strip: "Medium",
      linkType: null,
      userId: "20d7b7ae-e5b9-4ac3-b577-c072223e9e71",
      artistName: "Medium",
      albumName: null,
      coverImageUrl:
        "https://miro.medium.com/v2/resize:fit:1000/1*T0XzSydvRHNCKCzC0wX3iw.jpeg",
      imageFit: null,
      favicon: "https://miro.medium.com/v2/1*m-R_BkNf1Qjr1YbyOIJY2w.png",
      description:
        "Like everyone, appearing smart in meetings is my top priority. Sometimes this can be difficult if you start daydreaming about your next vacation, your next nap, or bacon. When this happens, it’s good…",
      title: "10 Tricks to Appear Smart During Meetings",
      ISBN: null,
      episodeTitle: null,
      authorName: null,
      ratingsAverage: null,
      ratingsCount: null,
      userRating: null,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: null,
      jobDescription: null,
    },
    {
      id: "56fe03e8-08c6-4de2-849f-b86fe53de31f",
      createdAt: "2023-11-19T01:09:45.503Z",
      updatedAt: "2023-11-19T01:09:45.503Z",
      url: "https://awilkinson.medium.com/slack-s-2-8-billion-dollar-secret-sauce-5c5ec7117908",
      urlPosition: 3,
      isProfileUrl: null,
      strip: "Medium",
      linkType: null,
      userId: "20d7b7ae-e5b9-4ac3-b577-c072223e9e71",
      artistName: "Medium",
      albumName: null,
      coverImageUrl:
        "https://miro.medium.com/v2/resize:fit:1200/1*quxuSggwBdYkyCoYlE3OAA.png",
      imageFit: null,
      favicon: "https://miro.medium.com/v2/1*m-R_BkNf1Qjr1YbyOIJY2w.png",
      description:
        "How Slack stole a multi-billion dollar market by becoming everyone’s favorite sassy robot sidekick.",
      title: "Slack’s $25 Billion Dollar Secret Sauce",
      ISBN: null,
      episodeTitle: null,
      authorName: null,
      ratingsAverage: null,
      ratingsCount: null,
      userRating: null,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: null,
      jobDescription: null,
    },
  ];

  return (
    <Strip
      id="medium"
      nav="scroll-tall"
      gradientValues={["#fafbfc"]}
      title="Medium"
      titleColor="#000000"
      icon={{ type: "fa", icon: faMedium }}
      iconColor="black"
    >
      {linksDataByStrip?.map((item, index) => {
        return (
          <MediumCard
            key={index}
            url={item.url}
            coverImageUrl={item.coverImageUrl}
            favicon={item.favicon}
            title={item.title}
            description={item.description}
            artistName={item.artistName}
          />
        );
      })}
    </Strip>
  );
}
