import {
  Stack,
  Indicator,
  Flex,
  Title,
  Button,
  Card,
  Image,
  rem,
  Text,
  Group,
  Anchor,
} from "@mantine/core";
import { MyUserButton } from "./FeaturedProfiles";
// import { UsernameInputHome } from "./UsernameInputHome";
import { useState } from "react";
import { useScrollIntoView } from "@mantine/hooks";
import { IconPlayerPlay } from "@tabler/icons-react";
import { ErrorBoundary } from "@sentry/react";

export function VerifiedUser({ username, profPic }) {
  return (
    <Flex mt={-60}>
      <Indicator
        position="bottom-end"
        color="transparent"
        size={16}
        offset={20}
        // label={<Image height={28} width={28} src="/verified.png" />}
      >
        <MyUserButton
          username={username}
          profPic={profPic}
          height={108}
          width={108}
        />
      </Indicator>
    </Flex>
  );
}

// export function VerifiedUser({ username, profPic }) {
//   return (
//     <Flex mt={-40}>
//       <Indicator
//         position="bottom-end"
//         color="transparent"
//         size={16}
//         offset={20}
//         // label={<Image height={28} width={28} src="/verified.png" />}
//       >
//         <MyUserButton
//           username={username}
//           profPic={profPic}
//           height={108}
//           width={108}
//         />
//       </Indicator>
//     </Flex>
//   );
// }

export function TwitterBio({
  username,
  profPic,
  twitterHandle,
  displayName,
  twitterBanner,
  biography,
  following,
  followers,
  scrollIntoView,
}) {
  const [isFollowing, setIsFollowing] = useState(false);
  const [hoverState, setHoverState] = useState(false);

  const handleToggle = () => {
    setIsFollowing((prevValue) => !prevValue);
    // Reset hover state to false on click
    setHoverState(false);
  };

  const handleMouseEnter = () => {
    // Only change hover state if already following
    if (isFollowing) {
      setHoverState(true);
    }
  };

  const handleMouseLeave = () => {
    setHoverState(false);
  };

  const buttonText = () => {
    if (isFollowing) {
      return hoverState ? " Unfollow " : "Following";
    }
    return "Follow";
  };

  return (
    <Card shadow="lg" withBorder radius="md" mb="md" maw={450}>
      <Card.Section>
        <Image src={twitterBanner} />
      </Card.Section>
      <Group align="top" spacing="xs">
        <div style={{ pointerEvents: "none" }}>
          <VerifiedUser username={username} profPic={profPic} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            flexGrow: 1,
          }}
        >
          <Button
            radius="xl"
            size="sm"
            variant={isFollowing ? "outline" : "filled"}
            sx={{
              backgroundColor: isFollowing ? "#FFFFFF" : "#0F1419",
              color: isFollowing ? "#0F1419" : "#FFFFFF",
            }}
            styles={(theme) => ({
              root: {
                paddingLeft: rem(16),
                paddingRight: rem(16),
                weight: 600,
                border: hoverState
                  ? "1px solid #FDC9CE"
                  : isFollowing
                  ? "1px solid #cfd9de"
                  : "1px solid #0F1419",
                "&:not([data-disabled])": theme.fn.hover({
                  backgroundColor: hoverState
                    ? "#FEEBED"
                    : isFollowing
                    ? "#FFFFFF"
                    : "#0F1419",
                  color: hoverState
                    ? "#F4222F"
                    : isFollowing
                    ? "#0F1419"
                    : "#FFFFFF",
                }),
              },
              label: {
                fontWeight: isFollowing ? 700 : 800,
                fontSize: "15px",
              },
            })}
            onClick={handleToggle}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {buttonText()}
          </Button>
        </div>
        <div style={{ marginTop: -10 }}>
          <Flex align="center">
            <Title order={2}>{displayName}</Title>
            <Image mt={3} ml={2} height={21} width={21} src="/verified.png" />
          </Flex>
          <Text color="#536471">@{twitterHandle}</Text>
          <Text mb={2} mt="sm" miw={300}>
            {biography}
          </Text>
          <Anchor
            color="#1d9bf0"
            onClick={() =>
              scrollIntoView({
                alignment: "center",
              })
            }
          >
            <Flex align="center" mb="xs" mt="xs">
              <svg
                viewBox="0 0 24 24"
                aria-hidden="true"
                style={{
                  height: "18px",
                  width: "18px",
                  fill: "#536471",
                  marginRight: "3px",
                }}
              >
                <g>
                  <path d="M18.36 5.64c-1.95-1.96-5.11-1.96-7.07 0L9.88 7.05 8.46 5.64l1.42-1.42c2.73-2.73 7.16-2.73 9.9 0 2.73 2.74 2.73 7.17 0 9.9l-1.42 1.42-1.41-1.42 1.41-1.41c1.96-1.96 1.96-5.12 0-7.07zm-2.12 3.53l-7.07 7.07-1.41-1.41 7.07-7.07 1.41 1.41zm-12.02.71l1.42-1.42 1.41 1.42-1.41 1.41c-1.96 1.96-1.96 5.12 0 7.07 1.95 1.96 5.11 1.96 7.07 0l1.41-1.41 1.42 1.41-1.42 1.42c-2.73 2.73-7.16 2.73-9.9 0-2.73-2.74-2.73-7.17 0-9.9z"></path>
                </g>
              </svg>
              tapistr.ee/{username}
            </Flex>
          </Anchor>
          <Group>
            <span>
              <b>{following}</b>{" "}
              <span style={{ color: "#536471" }}>Following</span>
            </span>
            <span>
              <b>{followers}</b>{" "}
              <span style={{ color: "#536471" }}>Followers</span>
            </span>
          </Group>
        </div>
      </Group>
    </Card>
  );
}

export function SectionSpotlight({
  username,
  profPic,
  twitterHandle,
  twitterBanner,
  displayName,
  biography,
  following,
  followers,
  autoPlay,
}) {
  const { scrollIntoView, targetRef } = useScrollIntoView({
    duration: 700, // quick scroll duration
    // easing: (t) => t, // simple linear easing function, you can customize this
    // axis: 'y', // vertical scroll
    // cancelable: true,
    offset: 50, // 50 pixels from the nearest edge
    onScrollFinish: () => {
      console.log("Scrolling finished");
    },
  });

  return (
    <Stack
      p="sm"
      sx={{ backgroundColor: "#EFF6FE", scrollMarginTop: "50px" }}
      pt={60}
      pb={40}
      id="featured-users"
    >
      <Title order={1} color="#444" ta="center">
        User <span className="gradient-text">spotlight</span> 🌟
      </Title>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "15px",
        }}
      >
        <TwitterBio
          username={twitterHandle}
          twitterHandle={twitterHandle}
          twitterBanner={twitterBanner}
          displayName={displayName}
          biography={biography}
          following={following}
          followers={followers}
          profPic={profPic}
          scrollIntoView={scrollIntoView}
        />
        {autoPlay && (
          <ErrorBoundary>
            <div ref={targetRef}>
              <iframe
                title={username}
                key={username}
                src={`https://tapistr.ee/${username}`}
                loading="lazy"
                className="preview"
              ></iframe>
            </div>
          </ErrorBoundary>
        )}
      </div>
      {/* <Center>
        <UsernameInputHome />
      </Center> */}
    </Stack>
  );
}
