import "../App.css";
import { Image, Skeleton } from "@mantine/core";
import { Strip } from "./Strip";
import axios from "axios";
import { baseUrl } from "../api/links";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { EventbriteCard } from "./EventbriteCard";

export function EventbriteStrip() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  const { username } = useParams();

  async function getEventbriteData() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/get-eventbrite-data/${username}`
      );
      setEvents(response.data);
    } catch (error) {
      console.error("Error while fetching Eventbrite data:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getEventbriteData();
  }, []);

  const hidePastEvents = true; // Move this outside the component if it's a global setting

  const filteredEvents = events.filter((item) => {
    const now = new Date().getTime();
    const startTime = new Date(item.startUtc).getTime();
    return hidePastEvents ? startTime >= now : true;
  });

  return (
    <Strip
      id="eventbrite"
      nav="scroll-tall"
      gradientValues={["#FFFFFF"]}
      title="Events"
      titleColor="#444444"
      icon={{
        type: "image",
        icon: (
          <Image
            src="/eventbrite-icon.png"
            height={36}
            width={36}
            radius="md"
          />
        ),
      }}
      iconColor="white"
    >
      {loading
        ? // Display a predefined number of skeletons when loading
          Array.from({ length: 2 }, (_, index) => (
            <Skeleton
              visible={true}
              key={index}
              height={420}
              width="100%"
            ></Skeleton>
          ))
        : filteredEvents.map((item, index) => (
            <EventbriteCard
              key={index}
              title={item.title}
              description={item.description}
              date={item.date} // Ensure these props are correctly used or updated as needed
              price={item.price}
              location={item.location}
              status={item.status}
              startUtc={item.startUtc}
              coverImageUrl={item.coverImageUrl}
              eventId={item.eventId}
            />
          ))}
    </Strip>
  );
}
// }
