export function SubstackEmbed({ url }) {
  const style = {
    margin: 0,
    width: "100%",
    height: "393px",
    borderRadius: "4px",
    backgroundColor: undefined,
    border: undefined,
  };

  // Remove trailing slash if it exists
  const formattedUrl = url.endsWith("/") ? url.slice(0, -1) : url;

  const finalEmbedUrl = `${formattedUrl}/embed`;

  return (
    <>
      <div className="card-full">
        <iframe
          title="substack"
          src={finalEmbedUrl}
          frameBorder="0"
          scrolling="no"
          style={style}
        ></iframe>
      </div>
    </>
  );
}
