import { Card } from "@mantine/core";

export function BeehiivEmbed({ url }) {
  // Use a function to normalize the URL by removing any existing `?slim=true` parameter
  function normalizeUrl(url) {
    // Check if '?slim=true' is present in the URL and remove it
    return url?.replace(/[?&]slim=true/gi, "");
  }

  // Ensure the embedUrl is case insensitive and normalize it
  const lowerCaseEmbedUrl = normalizeUrl(url?.toLowerCase());

  // Determine the embed type based on the URL format
  let embedType;
  if (lowerCaseEmbedUrl.includes("embeds.beehiiv.com")) {
    embedType = "embed";
    // } else if (lowerCaseEmbedUrl.includes("startupsocial.beehiiv.com")) {
    //   embedType = "full";
  } else {
    // Default or error handling if needed
    embedType = "full"; // or any default or error handling
  }

  const style = {
    margin: 0,
    width: embedType === "full" ? "100%" : "100%",
    height: embedType === "full" ? "570px" : "348px",
    borderRadius: embedType === "full" ? "0.5rem" : "0.5rem",
    backgroundColor: embedType === "full" ? "transparent" : undefined,
    border: undefined,
    boxShadow:
      "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
  };

  return (
    <div>
      <Card
        radius="md"
        p={0}
        mx="xs"
        mt="xs"
        mb="lg"
        sx={() => ({
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        })}
      >
        <iframe
          id="beehiiv"
          title="newsletter"
          src={lowerCaseEmbedUrl}
          // datatestid="beehiiv-embed"
          frameBorder="0"
          scrolling="no"
          style={style}
        ></iframe>
      </Card>
    </div>
  );
}
