import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";
import { useLoggedInQuery } from "../queries/queries";
import { LoadingOverlay } from "@mantine/core";
import { useState } from "react";

function Interim() {
  const navigate = useNavigate();
  const { user } = useUser();

  const clerkUserId = user?.id;

  const {
    data: loggedInData,
    isLoading: loggedInDataIsLoading,
    isError: loggedInDataIsError,
    refetch,
  } = useLoggedInQuery(clerkUserId);

  const loggedInUsername = loggedInData?.username;

  const [retryCount, setRetryCount] = useState(0);

  useEffect(() => {
    let retryTimeout;

    if (!loggedInDataIsLoading && loggedInUsername) {
      navigate(`/${loggedInUsername}`);
      console.log("Navigated to user's page");
    } else if (retryCount >= 3) {
      navigate("/");
      console.log("Exceeded maximum retries, navigated to homepage");
    } else if (loggedInDataIsError || !loggedInUsername) {
      retryTimeout = setTimeout(() => {
        refetch(); // Try to fetch the data again
        setRetryCount((prevCount) => prevCount + 1); // Increment the retry count
      }, 1000); // Wait for 1 second before checking again
    }

    // Return the cleanup function
    return () => {
      if (retryTimeout) {
        clearTimeout(retryTimeout);
      }
    };
  }, [
    navigate,
    loggedInUsername,
    loggedInDataIsLoading,
    loggedInDataIsError,
    refetch,
    retryCount,
  ]);

  if ((loggedInDataIsError || !loggedInUsername) && retryCount < 7) {
    return <LoadingOverlay visible={true} />;
  } else return <></>;
}

export default Interim;
