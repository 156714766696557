import { createContext, useContext } from "react";
import { useUser } from "@clerk/clerk-react";
import { useLoggedInQuery } from "../queries/queries";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { user } = useUser();

  const clerkUserId = user?.id;

  const {
    data: loggedInData,
    // isLoading: loggedInDataIsLoading,
    // isError: loggedInDataIsError,
  } = useLoggedInQuery(clerkUserId);

  const loggedInDisplayName = loggedInData?.displayName;
  const loggedInProfPic = loggedInData?.profPic;
  const loggedInUsername = loggedInData?.username;
  const loggedInUserId = loggedInData?.id;
  const loggedInEmail = loggedInData?.email;

  // if (loggedInData) {
  //   console.log(loggedInData);
  // }
  // Only display LoadingOverlay or Error if we have tried to fetch loggedInData (i.e., a user is logged in)
  // if (clerkUserId && loggedInDataIsLoading) {
  //   console.log("weird");
  //   console.log(loggedInData);
  //   return <LoadingOverlay visible />;
  // } else if (clerkUserId && loggedInDataIsError) {
  //   return <p>Error loading links</p>;
  // } else {
  return (
    <UserContext.Provider
      value={{
        loggedInUserId,
        loggedInUsername,
        loggedInDisplayName,
        loggedInProfPic,
        loggedInEmail,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
// };

export const useUserContext = () => {
  return useContext(UserContext);
};
