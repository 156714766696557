import {
  Anchor,
  Button,
  Card,
  Flex,
  Image,
  Text,
  Container,
} from "@mantine/core";
import { AmazonCard } from "./AmazonCard";
import { useState } from "react";

export function WebsiteCard({
  url,
  coverImageUrl,
  imageFit,
  title,
  description,
  favicon,
  buttonColor,
  buttonText,
}) {
  const [faviconIsValid, setFaviconIsValid] = useState(true);

  function isAbsoluteURL(str) {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  // Check if the URL is absolute
  if (!isAbsoluteURL(url)) {
    url = "https://" + url;
  }

  // Check if the URL is an Amazon UK or Amazon US URL
  const isAmazonUrl =
    url.includes("https://www.amazon.com/") ||
    url.includes("https://www.amazon.co.uk/");

  // If it's an Amazon URL, render the AmazonCard instead
  if (isAmazonUrl) {
    return <AmazonCard url={url} />;
  }

  return (
    <div className="card-xl">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <Card.Section>
          <Anchor target="_blank" href={url}>
            {imageFit === "contain" ? (
              <Image
                fit="contain"
                p="md"
                height={208}
                radius="md"
                src={coverImageUrl}
                alt={title}
                withPlaceholder
                imageProps={{
                  loading: "lazy",
                }}
              />
            ) : (
              <Image
                p={0}
                height={240}
                radius="md"
                src={coverImageUrl}
                alt={title}
                withPlaceholder
                imageProps={{
                  loading: "lazy",
                }}
              />
            )}
          </Anchor>
        </Card.Section>
        <Container p={0}>
          <Flex mt={6} align="center">
            {favicon && faviconIsValid && (
              <Image
                src={favicon}
                width={20}
                height={20}
                alt={title}
                // withPlaceholder
                pr={4}
                mt={7}
                mr={6}
                imageProps={{
                  loading: "lazy",
                }}
                onError={() => setFaviconIsValid(false)}
              />
            )}
            <Anchor
              color="dark"
              href={url}
              target="_blank"
              weight={500}
              size="lg"
              mt={6}
              lineClamp={1}
            >
              {title}
            </Anchor>
          </Flex>
          <Text
            color="dimmed"
            size="sm"
            lineClamp={2}
            maw={450}
            mb="xs"
            mih="43.375px"
          >
            {description}
          </Text>
          <Button
            color={!buttonColor && "green"}
            fullWidth
            component="a"
            target="_blank"
            href={url}
            styles={(theme) => ({
              root: {
                backgroundColor: buttonColor,
                "&:not([data-disabled])": buttonColor
                  ? theme.fn.hover({
                      backgroundColor: theme.fn.darken(buttonColor, 0.05),
                    })
                  : {},
              },
            })}
          >
            {buttonText ? buttonText : "Visit site"}
          </Button>
        </Container>
      </Card>
    </div>
  );
}
