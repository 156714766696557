import {
  Card,
  Button,
  Flex,
  Image,
  Group,
  Anchor,
  Text,
  Stack,
} from "@mantine/core";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { stripUrl } from "../utils/stripUrl";
import { ErrorBoundary } from "@sentry/react";

export function WebsiteStrip({ strip }) {
  const [faviconIsValid, setFaviconIsValid] = useState(true);

  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const isLargerThanSm = useMediaQuery("(min-width: 36em)");

  const linksDataByStrip = linksData?.find((item) => item.strip === strip);

  if (!linksDataByStrip) {
    // Handle the case where no matching item is found
    // This could be returning null, a loading indicator, an error message, etc.
    return <></>;
  }

  const {
    title,
    description,
    favicon,
    url,
    coverImageUrl,
    imageFit,
    buttonColor,
    buttonText,
  } = linksDataByStrip;

  const navId = stripUrl(url).replace(/\./g, "");

  let absoluteUrl;

  function isAbsoluteURL(str) {
    try {
      new URL(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  // Check if the URL is absolute
  if (!isAbsoluteURL(url)) {
    absoluteUrl = "https://" + url;
  } else if (isAbsoluteURL) {
    absoluteUrl = url;
  }

  function LargeWebsiteCard() {
    return (
      <Card
        p={0}
        radius="md"
        mx="xs"
        mt="xs"
        mb="lg"
        style={{
          display: "flex",
          flexDirection: "column",
          overflowX: "auto", // Making horizontal overflow scrollable
          backgroundColor: "#FFFFFF",
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Flex>
          <Stack
            p="md"
            spacing={2}
            justify="space-between"
            style={{ width: "87%" }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Anchor
                color="dark"
                href={absoluteUrl}
                target="_blank"
                weight={500}
                size="xl"
                lineClamp={1}
              >
                {title}
              </Anchor>
              <Text
                color="dimmed"
                size="sm"
                lineClamp={3}
                maw={450}
                // mih="43.375px"
              >
                {description}
              </Text>
              <Group align="flex-end" spacing={0} noWrap>
                {favicon && faviconIsValid && (
                  <Image
                    src={favicon}
                    width={20}
                    height={20}
                    alt={title}
                    pr={4}
                    mt={7}
                    mr={6}
                    radius="xs"
                    onError={() => setFaviconIsValid(false)}
                  />
                )}
                <Anchor
                  color="#444"
                  href={absoluteUrl}
                  target="_blank"
                  size="sm"
                  lineClamp={1}
                  maw={300}
                >
                  {absoluteUrl}
                </Anchor>
              </Group>
            </div>
            <Button
              color={!buttonColor && "green"}
              fullWidth
              component="a"
              target="_blank"
              href={absoluteUrl}
              styles={(theme) => ({
                root: {
                  backgroundColor: buttonColor,
                  "&:not([data-disabled])": buttonColor
                    ? theme.fn.hover({
                        backgroundColor: theme.fn.darken(buttonColor, 0.05),
                      })
                    : {},
                },
              })}
            >
              {buttonText ? buttonText : "Visit site"}
            </Button>
          </Stack>
          {imageFit === "contain" ? (
            <Image
              fit="contain"
              p="md"
              height={208}
              radius="md"
              src={coverImageUrl}
              alt={title}
              withPlaceholder
              imageProps={{
                loading: "lazy",
              }}
            />
          ) : (
            <Image
              p={0}
              height={207}
              // fit="contain"
              src={coverImageUrl}
              alt={title}
              withPlaceholder
              imageProps={{
                loading: "lazy",
              }}
            />
          )}
        </Flex>
      </Card>
    );
  }

  function SmallWebsiteCard() {
    return (
      <Card
        p={0}
        radius="md"
        mx="xs"
        mt="xs"
        mb="lg"
        style={{
          display: "flex",
          flexDirection: "column",
          // overflowX: "auto", // Making horizontal overflow scrollable
          backgroundColor: "#FFFFFF",
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Card.Section>
          {imageFit === "contain" ? (
            <Image
              fit="contain"
              // p="md"
              height={208}
              radius="md"
              src={coverImageUrl}
              alt={title}
              withPlaceholder
              imageProps={{
                loading: "lazy",
              }}
            />
          ) : (
            <Image
              p={0}
              height={217}
              // fit="contain"
              src={coverImageUrl}
              alt={title}
              withPlaceholder
              imageProps={{
                loading: "lazy",
              }}
            />
          )}
        </Card.Section>

        <Stack
          p="md"
          spacing="xs"
          justify="space-between"
          style={{ width: "100%" }}
        >
          <div>
            <Anchor
              color="dark"
              href={absoluteUrl}
              target="_blank"
              weight={500}
              size="lg"
              lineClamp={1}
            >
              {title}
            </Anchor>
            <Text
              color="dimmed"
              size="sm"
              lineClamp={3}
              //   maw={450}
              mih="43.375px"
            >
              {description}
            </Text>
            <Group align="flex-end" spacing={0} noWrap>
              {favicon && faviconIsValid && (
                <Image
                  src={favicon}
                  width={20}
                  height={20}
                  alt={title}
                  pr={4}
                  mt={7}
                  mr={6}
                  radius="xs"
                  onError={() => setFaviconIsValid(false)}
                />
              )}
              <Anchor
                color="dark"
                href={absoluteUrl}
                target="_blank"
                size="sm"
                lineClamp={1}
              >
                {absoluteUrl}
              </Anchor>
            </Group>
          </div>
          <Button
            color={!buttonColor && "green"}
            fullWidth
            component="a"
            target="_blank"
            href={absoluteUrl}
            styles={(theme) => ({
              root: {
                backgroundColor: buttonColor,
                "&:not([data-disabled])": buttonColor
                  ? theme.fn.hover({
                      backgroundColor: theme.fn.darken(buttonColor, 0.05),
                    })
                  : {},
              },
            })}
          >
            {buttonText ? buttonText : "Visit site"}
          </Button>
        </Stack>
      </Card>
    );
  }

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <div id={navId}>
        <ErrorBoundary key={linksDataByStrip?.id}>
          {isLargerThanSm ? <LargeWebsiteCard /> : <SmallWebsiteCard />}
        </ErrorBoundary>
      </div>
    );
  }
}
