import {
  useQuery,
  useMutation,
  QueryCache,
  QueryClient,
} from "@tanstack/react-query";
import {
  getProfile,
  getAllProfiles,
  updateProfile,
  getLoggedIn,
  getLinks,
  createLink,
  updateLink,
  deleteLink,
  updateUrlPositions,
  getExperience,
  getStrips,
  addStrip,
  deleteStrip,
  updateStripPositions,
  updateStripVisibility,
  updateFollowButtonVisibility,
  addBooks,
  getAnalytics,
  updateStripColor,
} from "../api/links";
import { notifications, showNotification } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useParams } from "react-router-dom";
import { useAuth } from "@clerk/clerk-react";

const profileKeys = {
  all: ["profile"],
  username: () => [...profileKeys.all, "username"],
};

const loggedInKeys = {
  all: ["loggedIn"],
  clerkUserId: () => [...loggedInKeys.all, "loggedIn"],
};

const linksKeys = {
  all: ["links"],
  userId: () => [...linksKeys.all, "links"],
};

const experienceKeys = {
  all: ["experience"],
};

const stripsKeys = {
  all: ["strips"],
  userId: () => [...stripsKeys.all, "username"],
};

export const useProfileQuery = () => {
  const { username } = useParams();

  return useQuery({
    queryKey: ["profile", username],
    queryFn: () => getProfile(username),
    staleTime: 0, // Ensures data is always refetched when queryKey changes
    cacheTime: 0, // Data will not be kept in cache
    useErrorBoundary: (error) => error.response?.stats >= 500,
  });
};

export const useSuperProfileQuery = () => {
  const { getToken } = useAuth();

  const queryKey = ["profile"];

  return useQuery(queryKey, async () => {
    const token = await getToken();
    return getAllProfiles({ token });
  });
};

export const useControlledProfileQuery = (username) => {
  return useQuery({
    enabled: username != null,
    queryKey: ["profile", username],
    queryFn: () => getProfile(username),
    staleTime: 0, // Ensures data is always refetched when queryKey changes
    cacheTime: 0, // Data will not be kept in cache
    useErrorBoundary: (error) => error.response?.stats >= 500,
  });
};

export const useLoggedInQuery = (clerkUserId) => {
  return useQuery({
    enabled: clerkUserId != null,
    queryKey: ["loggedIn", clerkUserId],
    queryFn: () => getLoggedIn(clerkUserId),
    useErrorBoundary: (error) => error.response?.stats >= 500,
  });
};

export const useProfileMutation = () => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async ({ username, updates }) => {
      const token = await getToken();
      return updateProfile({ username, updates, token });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({
          queryKey: profileKeys.all,
        });
      },
    }
  );
  return mutation;
};

export const useBooksMutation = () => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async ({ userId, isbn }) => {
      const token = await getToken();
      return addBooks({ userId, isbn, token });
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries({
          queryKey: profileKeys.all,
        });
      },
    }
  );
  return mutation;
};

export const useLinksQuery = () => {
  const { username } = useParams();

  const query = useQuery({
    queryKey: linksKeys.all,
    queryFn: () => getLinks(username),
  });
  return query;
};

export const useAnalyticsQuery = (userId) => {
  const { getToken } = useAuth();

  const queryKey = ["analytics", userId];

  return useQuery(
    queryKey,
    async () => {
      const token = await getToken();
      return getAnalytics({ userId, token });
    },
    {
      enabled: !!userId,
    }
  );
};

export const useExperienceQuery = () => {
  const query = useQuery({
    queryKey: experienceKeys.all,
    queryFn: getExperience,
  });
  return query;
};

export const useStripsQuery = () => {
  const { username } = useParams();

  return useQuery({
    queryKey: ["strips", username],
    queryFn: () => getStrips(username),
    useErrorBoundary: (error) => error.response?.stats >= 500,
  });
};

export const useCreateLinkMutation = (onSuccess) => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async (newLinkData) => {
      const token = await getToken();
      return createLink({ ...newLinkData, token });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["links"]);
        showNotification({
          title: "Success!",
          message: "Link added to your page 🥳",
          autoClose: 3000,
          color: "green",
          icon: <IconCheck />,
        });
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      },
      onError: () => {
        // Display a notification when an error occurs
        showNotification({
          title: "An error occurred",
          message: "We had trouble adding that link",
          autoClose: 3000,
          color: "red",
          icon: <IconX />,
        });
      },
    }
  );
  return mutation;
};

export const useUpdateLinkMutation = (onSuccess, onError) => {
  return useMutation(updateLink, {
    onSuccess: async (data) => {
      await queryClient.invalidateQueries(linksKeys.all);
      if (typeof onSuccess === "function") {
        onSuccess(data);
      }
    },
    onError: (error) => {
      if (typeof onError === "function") {
        onError(error);
      }
    },
  });
};

export const useAddStripMutation = (onSuccess) => {
  const { getToken } = useAuth();
  const mutation = useMutation(
    async ({ userId, stripsData }) => {
      const token = await getToken();
      return addStrip({ userId, stripsData, token });
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["strips"]);
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      },
    }
  );
  return mutation;
};

export const useUpdateUrlPositionsMutation = (onSuccess) => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async (newUrlPositions) => {
      const token = await getToken();
      await updateUrlPositions(newUrlPositions, token);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["links"]);
        if (typeof onSuccess === "function") {
        }
      },
    }
  );
  return mutation;
};

//May not have to use onMutate now that I'm using localStripData state
export const useUpdateStripPositionsMutation = () => {
  const { getToken } = useAuth();
  const mutation = useMutation(
    async (newStripPositions) => {
      const token = await getToken(); // Get the token
      return updateStripPositions(newStripPositions, token); // Pass the token to updateStripPositions function
    },
    {
      // When mutate is called:
      onMutate: async (newStripPositions) => {
        // Cancel any outgoing refetches
        // (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(["strips"]);
        // Snapshot the previous value

        const previousStrips = queryClient.getQueryData(["strips"]);
        // Optimistically update to the new value

        queryClient.setQueryData(["strips"], newStripPositions);
        // Return a context object with the snapshotted value

        return { previousStrips };
      },
      // If the mutation fails,
      // use the context returned from onMutate to roll back
      onError: (err, newStripPositions, context) => {
        queryClient.setQueryData(["strips"], context.previousStrips);
      },
      // Always refetch after error or success:
      onSettled: () => {
        queryClient.invalidateQueries({ queryKey: ["strips"] });
      },
    }
  );
  return mutation;
};

export const useUpdateStripVisibilityMutation = (onSuccess) => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async (updatedStripVisibility) => {
      const token = await getToken();
      return updateStripVisibility(updatedStripVisibility, token);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(["strips"]);
        if (typeof onSuccess === "function") {
          onSuccess(data, variables, context);
        }
      },
    }
  );

  return mutation;
};

export const useUpdateStripColorMutation = (onSuccess) => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async (updatedStripColor) => {
      const token = await getToken();
      return updateStripColor(updatedStripColor, token);
    },
    {
      onSuccess: async (data, variables, context) => {
        await queryClient.invalidateQueries(["strips"]);
        if (typeof onSuccess === "function") {
          onSuccess(data, variables, context);
        }
      },
    }
  );

  return mutation;
};

export const useUpdateFollowButtonVisibilityMutation = (onSuccess) => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async (visibility) => {
      const token = await getToken();
      return updateFollowButtonVisibility(visibility, token);
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(["strips"]);
        if (typeof onSuccess === "function") {
        }
      },
    }
  );

  return mutation;
};

export const useDeleteLinkMutation = () => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async (id) => {
      const jwt = await getToken();
      return deleteLink(id, jwt);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(linksKeys.all);
      },
    }
  );
  return mutation;
};

export const useDeleteStripMutation = () => {
  const { getToken } = useAuth();

  const mutation = useMutation(
    async (id) => {
      const jwt = await getToken();
      return deleteStrip(id, jwt);
    },
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(stripsKeys.all);
      },
    }
  );
  return mutation;
};

export const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      notifications.show({
        message: error.message || "We encountered an error.",
        color: "red",
        icon: <IconX size="1rem" />,
      });
    },
  }),
});
