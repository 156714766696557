import { ErrorBoundary } from "@sentry/react";
import { InstagramEmbed } from "react-social-media-embed";
import { Card, ScrollArea } from "@mantine/core";

export function DummyInstagramStrip() {
  const linksDataByStrip = [
    { url: "https://www.instagram.com/taylorswift/" },
    { url: "https://www.instagram.com/killatrav/" },
    { url: "https://www.instagram.com/chiefs/" },
  ];

  return (
    <ErrorBoundary>
      <Card
        p="xs"
        radius="md"
        mx="xs"
        mt="xs"
        mb="lg"
        sx={(theme) => ({
          color: theme.white,
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
          overflowX: "auto", // Making horizontal overflow scrollable
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        })}
      >
        <ScrollArea type="auto" offsetScrollbars>
          <div className="card-container-scroll">
            {linksDataByStrip.map((link, index) => {
              return (
                <ErrorBoundary key={link.url + index}>
                  <InstagramEmbed
                    placeholderProps={{
                      style: {
                        height: 550,
                        width: 324,
                      },
                    }}
                    width="100%"
                    url={link.url}
                    frame={window}
                  />
                </ErrorBoundary>
              );
            })}
          </div>
        </ScrollArea>
      </Card>
    </ErrorBoundary>
  );
}
