import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "../components/Strip";
import { Polaroid } from "../components/Polaroid";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";

export function PhotosStrip() {
  const STRIP = "Photos";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="photos"
        nav="scroll"
        titleColor="#424242"
        gradientValues={["#EEE1C3"]}
        title="Photos"
        icon={{ type: "emoji", icon: "📸" }}
        iconColor="white"
      >
        {linksDataByStrip.map((item, index) => {
          return (
            <Polaroid
              key={index}
              image={item.url}
              description={item.description}
              emoji={item.emoji}
            />
          );
        })}
      </Strip>
    );
  }
}
