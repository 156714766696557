import {
  ActionIcon,
  Box,
  Button,
  Card,
  CopyButton,
  Divider,
  Flex,
  Group,
  LoadingOverlay,
  TextInput,
  Modal,
  Text,
  Tooltip,
  UnstyledButton,
  Title,
  Badge,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconCheck, IconPencil } from "@tabler/icons-react";
import { useState, Suspense } from "react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import { useEmojiFavicon } from "../utils/emojiToFavicon";
import { useProfileMutation, useProfileQuery } from "../queries/queries";
import { UnsplashSearchBar } from "./UnsplashSearchBar";
import { UsernameInput } from "./UsernameInput";
import { UserButton } from "@clerk/clerk-react";
import { useUserContext } from "../contexts/UserContext";
import { isPremium } from "../utils/productCatalog";

const isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints;

export function UserCard({ username }) {
  const userLink = `https://tapistr.ee/${username}`;
  const defaultFaviconUrl = "https://mantine.dev/favicon.svg";

  const [showPicker, setShowPicker] = useState(false);
  const [showCoverPhotoModal, setShowCoverPhotoModal] = useState(false);
  const [showNameModal, setShowNameModal] = useState(false);
  const [showUsernameModal, setShowUsernameModal] = useState(false);

  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useProfileQuery(username);

  const profileMutation = useProfileMutation();

  const coverPhoto = profileData?.coverPhoto;

  const { loggedInEmail } = useUserContext();

  const customerEmailEncoded = encodeURIComponent(loggedInEmail);

  const handleEmojiUpdate = (emoji) => {
    profileMutation.mutate({
      username: username,
      updates: {
        emoji: emoji,
      },
    });
  };

  useEmojiFavicon(profileData?.emoji, defaultFaviconUrl);

  function EmojiModal() {
    return (
      <Modal
        opened={showPicker && !isTouchDevice}
        onClose={() => {
          setShowPicker(false);
        }}
        closeOnClickOutside
        closeOnEscape
        padding="xs"
        size="auto"
        lockScroll={false}
      >
        <Picker
          data={data}
          maxFrequentRows={0}
          onEmojiSelect={(e) => {
            handleEmojiUpdate(e.native);
            setShowPicker(false);
          }}
        />
      </Modal>
    );
  }

  function CoverPhotoModal() {
    const [saved, setSaved] = useState(false);
    const [inputValue, setInputValue] = useState("");

    function editInput() {
      setSaved(false);
    }

    const handleSave = (url) => {
      profileMutation.mutate({
        username: username,
        updates: {
          coverPhoto: url,
        },
      });
      setShowCoverPhotoModal(false);
    };

    return (
      <Modal
        title="Cover photo"
        opened={showCoverPhotoModal}
        onClose={() => setShowCoverPhotoModal(false)}
        closeOnClickOutside
        padding="xs"
        // size="auto"
      >
        <UnsplashSearchBar handleSave={handleSave} />
        <Divider my="xs" label="or" labelPosition="center" />
        <Flex align="end" py={5}>
          <TextInput
            label="Enter photo URL"
            placeholder="photos.unsplash.com"
            disabled={saved}
            mr={3}
            value={inputValue}
            onChange={(event) => setInputValue(event.currentTarget.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSave(inputValue);
              }
            }}
            styles={{
              root: {
                fontSize: "16px",
                flexGrow: "1 !important",
              },
              input: {
                fontSize: "16px",
                height: "20px",
                lineHeight: "20px",
              },
            }}
          />
          <>
            {saved ? (
              <Button
                // color={"gray[1]"}
                onClick={() => {
                  editInput();
                }}
                style={{ marginRight: "8px" }}
              >
                Edit
              </Button>
            ) : (
              <Button
                color="blue"
                onClick={() => handleSave(inputValue)}
                disabled={profileIsLoading || inputValue === ""}
                style={{ padding: "0px 16px", marginRight: "8px" }}
              >
                Save
              </Button>
            )}
          </>
        </Flex>
        {/* <Divider my="xs" label="or" labelPosition="center" />
        <PhotoDropzone /> */}
        {/* TBU^^^ uploads */}
      </Modal>
    );
  }

  function NameModal() {
    const [saved, setSaved] = useState(false);
    const [myName, setMyName] = useState("");

    function editInput() {
      setSaved(false);
    }
    //TBU. Needs to be a form.
    const handleSave = (myName, lastName) => {
      profileMutation.mutate({
        username: username,
        updates: {
          displayName: myName,
        },
      });
      setShowNameModal(false);
    };

    return (
      <Modal
        opened={showNameModal}
        onClose={() => setShowNameModal(false)}
        closeOnClickOutside
        padding="xs"
        // size="auto"
      >
        <Flex align="end" py={5}>
          <TextInput
            label="Name"
            placeholder="Truman Burbank"
            disabled={saved}
            mr={3}
            value={myName}
            onChange={(event) => setMyName(event.currentTarget.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSave(myName);
              }
            }}
            styles={{
              root: {
                fontSize: "16px",
                flexGrow: "1 !important",
              },
              input: {
                fontSize: "16px",
                height: "20px",
                lineHeight: "20px",
              },
            }}
          />
          <>
            {saved ? (
              <Button
                // color={"gray[1]"}
                onClick={() => {
                  editInput();
                }}
                style={{ marginRight: "8px" }}
              >
                Edit
              </Button>
            ) : (
              <Button
                color="blue"
                onClick={() => handleSave(myName)}
                disabled={profileIsLoading || myName === ""}
                style={{ padding: "0px 16px", marginRight: "8px" }}
              >
                Save
              </Button>
            )}
          </>
        </Flex>
      </Modal>
    );
  }
  function UsernameModal() {
    return (
      <Modal
        opened={showUsernameModal}
        onClose={() => setShowUsernameModal(false)}
        closeOnClickOutside
        padding="xs"
        title="Update username"
        // size="auto"
      >
        <Title color="red" order={3}>
          WARNING: You will lose your historical analytics by changing your
          username.
        </Title>
        <UsernameInput />
      </Modal>
    );
  }

  if (profileIsLoading) {
    return <></>;
  } else if (profileIsError) {
    return <p>Error loading links</p>;
  } else {
    return (
      <>
        <EmojiModal />
        <CoverPhotoModal />
        <NameModal />
        <UsernameModal />
        <Card
          shadow="sm"
          p={0}
          radius="md"
          withBorder
          m={0}
          sx={(theme) => ({
            color: theme.white,
            display: "flex",
            flexDirection: "column",
            minHeight: "200px",
          })}
        >
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              minHeight: "200px",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundImage: `url(${coverPhoto})`,
                backgroundSize: "cover",
                backgroundPosition: "center top",
                backgroundRepeat: "repeat-x",
              }}
            ></div>
            <div
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                zIndex: 333,
              }}
            >
              <Badge
                variant={
                  isPremium(profileData?.stripePriceId) ? "gradient" : "light"
                }
                color={
                  profileData?.stripeSubscriptionStatus !== "active"
                    ? "blue"
                    : isPremium(profileData?.stripePriceId)
                    ? ""
                    : "green"
                }
              >
                {profileData?.stripeSubscriptionStatus !== "active"
                  ? "Free"
                  : isPremium(profileData?.stripePriceId)
                  ? "Premium"
                  : "Starter"}
              </Badge>
            </div>
            <div
              style={{
                position: "absolute",
                top: 10,
                right: 10,
                zIndex: 333,
              }}
            >
              <ActionIcon
                variant="light"
                radius="xl"
                onClick={() => setShowCoverPhotoModal(true)}
              >
                <IconPencil size="1em" />
              </ActionIcon>
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.5)",
              }}
            ></div>
            <div style={{ padding: "10px", position: "relative" }}>
              <Suspense fallback={<div>Loading...</div>}>
                <Box pos="relative">
                  <LoadingOverlay visible={profileIsLoading} />
                  <Group m="sm">
                    <UserButton />
                  </Group>
                  <Flex justify="center">
                    <Tooltip label="Edit">
                      <UnstyledButton
                        onClick={() => {
                          setShowPicker(true);
                        }}
                        style={{ minWidth: "1.5rem" }}
                      >
                        {profileData?.emoji}&nbsp;
                      </UnstyledButton>
                    </Tooltip>
                    {profileData?.displayName && (
                      <Text weight={500} color="white">
                        {" "}
                        •&nbsp;{profileData.displayName}
                      </Text>
                    )}
                    {profileData?.username && (
                      <Text weight={500}>
                        &nbsp;•&nbsp;{profileData.username}
                      </Text>
                    )}
                  </Flex>
                  <Group position="center" mt="sm">
                    <CopyButton value={userLink} timeout={2000}>
                      {({ copied, copy }) => (
                        <Button
                          variant="default"
                          radius="xs"
                          size="xs"
                          compact
                          uppercase
                          onClick={() => {
                            copy();
                            notifications.show({
                              message: "Copied to clipboard!",
                              color: "green",
                              icon: <IconCheck size="1rem" />,
                            });
                          }}
                        >
                          Share Link
                        </Button>
                      )}
                    </CopyButton>
                    <Button
                      variant="default"
                      radius="xs"
                      size="xs"
                      compact
                      uppercase
                      component="a"
                      href={`https://billing.stripe.com/p/login/cN20202jQ1M1ayYeUU?prefilled_email=${customerEmailEncoded}`}
                    >
                      Billing
                    </Button>
                    <Button
                      variant="default"
                      radius="xs"
                      size="xs"
                      compact
                      uppercase
                      onClick={() => setShowNameModal(true)}
                    >
                      Edit Name
                    </Button>
                    <Button
                      variant="default"
                      radius="xs"
                      size="xs"
                      compact
                      uppercase
                      onClick={() => setShowUsernameModal(true)}
                    >
                      Edit Username
                    </Button>
                  </Group>
                </Box>
              </Suspense>
            </div>
          </div>
        </Card>
      </>
    );
  }
}
