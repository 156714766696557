import {
  Anchor,
  Avatar,
  Badge,
  Container,
  Group,
  Image,
  Paper,
  Stack,
  Skeleton,
  Text,
  Button,
  rem,
  Indicator,
  Tooltip,
  Loader,
} from "@mantine/core";
import { StravaCalendar } from "../components/StravaCalendar";
import { useEffect, useState } from "react";
import { baseUrl } from "../api/links";
import axios from "axios";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStrava } from "@fortawesome/free-brands-svg-icons";
import html2canvas from "html2canvas";
import { faCopy, faShare } from "@fortawesome/free-solid-svg-icons";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons-react";
import { useProfileQuery } from "../queries/queries";
import { convertDistance } from "../utils/convertDistance";

export function StravaPage() {
  const { username } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [athleteProfile, setAthleteProfile] = useState("");
  const [clubs, setClubs] = useState([]);
  const [copied, setCopied] = useState(false);
  const [copyLoading, setCopyLoading] = useState(false);
  const isTouchDevice = "ontouchstart" in window || navigator.msMaxTouchPoints;

  const { data: profileData } = useProfileQuery();

  async function getStravaData(username) {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/get-strava-data/${username}`
      );
      // Ensure January 1st is present for each year with data
      const updatedData = ensureJanuaryFirst(response.data.coloredHeatmapData);
      setData(updatedData);
      setClubs(response.data.clubsArray);
      setAthleteProfile(response.data.athleteProfile);
    } catch (error) {
      console.error("Error while fetching Strava data:", error);
      return [];
    } finally {
      setLoading(false);
    }
  }

  // Group data by year
  const dataByYear = data.reduce((acc, item) => {
    const year = item.date.split("-")[0];
    if (!acc[year]) {
      acc[year] = [];
    }
    acc[year].push(item);
    return acc;
  }, {});

  // Render calendars for each year
  function ActivityCalendars({ loading }) {
    if (loading) {
      return (
        <Stack spacing="sm" mt={10} miw={200}>
          <div>
            <Text weight={500} mb="xs"></Text>
            <StravaCalendar loading={loading} />
          </div>
        </Stack>
      );
    }

    const years = Object.keys(dataByYear).sort(); // Ensure years are sorted to find the earliest year
    //Reverse the years to show the latest year first
    years.reverse();
    const earliestYear = years[years.length - 1];

    return years.map((year) => {
      // Calculate total miles for the year
      const totalKilometers = dataByYear[year].reduce(
        (acc, item) => acc + item.count,
        0
      );

      let convertedDistance;
      if (profileData?.distanceUnit === "miles") {
        convertedDistance = totalKilometers / 1.609344; // Convert without rounding here
      } else {
        convertedDistance = totalKilometers; // No conversion needed, use the original total
      }

      // Round the total
      let roundedTotalDistance = Math.round(convertedDistance);

      // Hide the color legend for all but the earliest year's calendar
      const hideColorLegend = year !== earliestYear;

      return (
        <Stack spacing="sm" mt={10} miw={200} key={year}>
          <div key={year}>
            <Text weight={500} mb="xs">
              {year}
            </Text>
            <StravaCalendar
              loading={loading}
              data={dataByYear[year]}
              roundedTotalDistance={roundedTotalDistance}
              distanceUnit={profileData?.distanceUnit}
              hideColorLegend={hideColorLegend}
            />
          </div>
        </Stack>
      );
    });
  }

  // Parse the date from the athleteProfile
  const profileDate = new Date(athleteProfile.profileCreatedAt);

  // Format the date as "since Month Year"
  const formattedDate = `${profileDate.toLocaleString("default", {
    month: "long",
  })} ${profileDate.getFullYear()}`;

  const grandTotalMiles = data.reduce((acc, item) => acc + item.count, 0);
  const roundedGrandTotalMiles = Math.round(grandTotalMiles);
  const activityEmojis = athleteProfile.activityTypes?.map(
    (activity) => activity.emoji
  );
  // Assuming activityEmojis is an array of emoji strings. If it's already a string, adjust accordingly.
  const activityEmojisString = activityEmojis?.join(""); // Join the emojis into one string without commas.

  const tweetText = `My activity report powered by @Strava\nMiles traveled: ${roundedGrandTotalMiles}🔸\nActivities recorded: ${activityEmojisString}\n\nCreate yours at https://tapistr.ee via @aubthread `;
  const encodedTweetText = encodeURIComponent(tweetText);
  const tweetUrl = `https://twitter.com/intent/tweet?text=${encodedTweetText}&related=strava,aubthread,tapistree`;

  const activityBadges = athleteProfile.activityTypes?.map(
    (activity, index) => {
      return (
        <Badge key={index} variant="light" color={activity.color} mr={4}>
          {activity.emoji} {activity.displayName} ({activity.count})
        </Badge>
      );
    }
  );

  function Streaks() {
    const distanceUnit = athleteProfile.distanceUnit;
    const convertedDistanceCurrentStreak = convertDistance(
      athleteProfile.currentStreak?.kilometers,
      profileData?.distanceUnit
    );
    const convertedDistanceLongestStreak = convertDistance(
      athleteProfile.longestStreak?.kilometers,
      profileData?.distanceUnit
    );

    // Round the total
    let roundedTotalDistanceCurrentStreak = Math.round(
      convertedDistanceCurrentStreak
    );
    let roundedTotalDistanceLongestStreak = Math.round(
      convertedDistanceLongestStreak
    );

    return (
      // <Stack spacing="sm" mt={10} miw={200}>
      <>
        <br></br>
        <Badge leftSection={"🔥"} variant="filled">
          Current streak: {athleteProfile.currentStreak?.days} days,{" "}
          {roundedTotalDistanceCurrentStreak} {distanceUnit}
        </Badge>
        <br></br>
        <Badge leftSection={"🔥🔥"} variant="filled">
          Longest streak: {athleteProfile.longestStreak?.days} days,{" "}
          {roundedTotalDistanceLongestStreak} {distanceUnit}
        </Badge>
        {/* </Stack> */}
      </>
    );
  }

  //   const clubsBadges = clubs?.map((club, index) => {
  //     return (
  //       <Anchor key={index} href={club.url} target="_blank" rel="noopener">
  //         <Avatar src={club.profilePic} size="sm" />
  //       </Anchor>
  //     );
  //   });

  useEffect(() => {
    // Check if username exists before making the API call
    if (username) {
      getStravaData(username);
    }
  }, [username]);

  // Render nothing if username is not provided
  if (!username) {
    return null;
  }

  const ensureJanuaryFirst = (data) => {
    const yearsWithActivity = [
      ...new Set(data.map((item) => item.date.slice(0, 4))),
    ];

    // Ensure sorted order to prevent interval issues
    yearsWithActivity.sort().forEach((year) => {
      const januaryFirst = `${year}-01-01`;
      const DecemberThirtyFirst = `${year}-12-31`;
      if (!data.find((item) => item.date === januaryFirst)) {
        data.push({
          date: januaryFirst,
          count: 0,
          level: 0,
        });
      }
      if (!data.find((item) => item.date === DecemberThirtyFirst)) {
        data.push({
          date: DecemberThirtyFirst,
          count: 0,
          level: 0,
        });
      }
    });

    // Return data sorted by date to ensure correct interval processing
    return data.sort((a, b) => a.date.localeCompare(b.date));
  };

  const captureAndCopyToClipboard = async () => {
    const element = document.getElementById("elementToCapture");
    if (!element) {
      console.error("Element not found");
      return;
    }
    setCopyLoading(true);
    try {
      const canvas = await html2canvas(element);
      canvas.toBlob(async (blob) => {
        try {
          const item = new ClipboardItem({ "image/png": blob });
          await navigator.clipboard.write([item]);
          showNotification({
            title: "Copied to clipboard!",
            message: "Now, click the share button or paste anywhere!",
            autoClose: 3000,
            color: "green",
            icon: <IconCheck />,
          });
          setCopied(true);
        } catch (error) {
          console.error("Error copying image to clipboard:", error);
          showNotification({
            title: "Hmm",
            message: "We might have messed that up",
            autoClose: 3000,
            color: "orange",
            icon: <IconCheck />,
          });
        }
      }, "image/png");
    } catch (error) {
      console.error("Error capturing image:", error);
    } finally {
      setCopyLoading(false);
    }
  };

  //   async function share(canvas) {
  //     try {
  //       canvas.toBlob(async (blob) => {
  //         navigator
  //           .share({
  //             title: "Strava Heatmap",
  //             text: "Check out my #StravaActivity history over time. A free tool by @aubthread. Create one at https://tapistr.ee",
  //             files: [
  //               new File([blob], "activity.png", {
  //                 type: blob.type
  //               })
  //             ]
  //           })
  //           .catch(() => {
  //             // do nothing
  //           });
  //       }, "image/png");
  //     } catch (err) {
  //       console.error(err);
  //     }
  //   }

  //   const onShare = (e) => {
  //     e.preventDefault();
  //     share(canvasRef.current);
  //   };

  async function share(elementId) {
    const element = document.getElementById(elementId);
    if (!element) {
      console.error("Element not found");
      return;
    }

    try {
      const canvas = await html2canvas(element);
      canvas.toBlob(async (blob) => {
        try {
          await navigator.share({
            title: "Strava Heatmap",
            text: tweetText,
            files: [
              new File([blob], "activity.png", {
                type: blob.type,
              }),
            ],
          });
        } catch (error) {
          console.error("Error sharing:", error);
        }
      }, "image/png");
    } catch (err) {
      console.error("Error in sharing process:", err);
    }
  }

  const onShare = (e) => {
    e.preventDefault();
    const elementId = "elementToCapture"; // The ID of the element you want to share
    share(elementId);
  };

  async function copyToClipboard(canvas) {
    if ("ClipboardItem" in window) {
      // https://bugs.webkit.org/show_bug.cgi?id=222262
      // https://web.dev/async-clipboard/
      const item = new ClipboardItem({
        "image/png": new Promise((resolve) => {
          canvas.toBlob(resolve, "image/png");
        }),
      });
      navigator.clipboard
        .write([item])
        .then(() => alert("🎉 Copied image!"))
        .catch((err) => {
          alert("Sorry, copying image is not supported on this browser");
          console.error("failed to copy");
        });
    } else {
      alert("Sorry, copying image is not supported on this browser");
      console.error("failed to copy");
    }
  }

  return (
    <>
      <Group position="apart" mih={50} px="xs" spacing="xs" mt={7} noWrap>
        <Text color="white" size={30} weight={600}>
          <span className="gradient-text">Tapistree 🎨</span>
        </Text>
      </Group>

      <Container pt="sm" p="xs" maw={860}>
        <Paper py="xs" px={2}>
          {loading ? (
            <>
              <Group spacing="xs" noWrap mb={4}>
                <Skeleton height={56} circle />
                <Stack spacing={2} miw={250}>
                  <Skeleton height={12} width="70%" radius="xl" />
                  <Skeleton height={16} mt={6} width="70%" radius="xl" />
                  <Skeleton height={8} mt={6} width="70%" radius="xl" />
                </Stack>
              </Group>
              <Loader mt="xl" color="orange" visible={loading} />
            </>
          ) : (
            <Group spacing="xs" noWrap mb={4}>
              <Indicator
                color="transparent"
                size={16}
                offset={6}
                label={
                  athleteProfile.premium && (
                    <Image
                      height={16}
                      width={16}
                      src="https://d3nn82uaxijpm6.cloudfront.net/assets/svg/badges_multicolor_summit_small-a9f1366377ea9bcfb95dd73917f97b674a8c64d9f00bb029d58c23730158b328.svg"
                    />
                  )
                }
              >
                <Avatar src={athleteProfile.profPic} radius="xl" size="lg" />
                {/* <img
                  src={athleteProfile.profPic}
                  alt="Profile"
                  width="50px"
                  height="50px"
                  style={{ borderRadius: "50%" }} // This makes the image circular
                /> */}
              </Indicator>
              <Stack spacing={2}>
                <Text weight={500} lineClamp={1} miw={180}>
                  {athleteProfile.firstName} {athleteProfile.emojiFlag}{" "}
                  {athleteProfile.premium}
                </Text>
                <Button
                  size="sm"
                  component="a"
                  variant="outline"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.strava.com/athletes/${athleteProfile.id}`}
                  leftIcon={
                    <FontAwesomeIcon
                      icon={faStrava}
                      size="lg"
                      color="#FC4C02"
                    />
                  }
                  radius="xs"
                  styles={(theme) => ({
                    root: {
                      backgroundColor: "#FFFFFF",
                      color: "#FC4C02",
                      fontSize: "11px",
                      border: "1px solid #FC4C02",
                      height: rem(20),
                      paddingLeft: rem(10),
                      paddingRight: rem(10),
                      "&:not([data-disabled])": theme.fn.hover({
                        //Very light orange hue of our primary color
                        backgroundColor: "#ffede6",
                      }),
                    },
                    leftIcon: {
                      marginRight: "4px",
                    },
                    label: {
                      fontWeight: 500,
                    },
                  })}
                >
                  View on Strava
                </Button>
                <Text size="xs" color="dimmed">
                  Member since {formattedDate}
                </Text>
              </Stack>
            </Group>
          )}
          {/* <Group spacing={8} my={6}>
              {clubsBadges}
            </Group> */}
        </Paper>
        {activityBadges}
        {athleteProfile && <Streaks />}
      </Container>
      <div id="elementToCapture" style={{ maxWidth: "860px", margin: "auto" }}>
        <Container pt="sm" p="xs" maw={860}>
          <ActivityCalendars loading={loading} />
          <img
            src="/api_logo_pwrdBy_strava_stack_light.png"
            width={120}
            alt="Powered by Strava"
            style={{
              marginLeft: "-8px",
              marginTop: "5px",
            }}
          />
          <Text size="sm" color="dimmed" mt="sm">
            Made by{" "}
            <Anchor href="https://twitter.com/aubthread">@aubthread</Anchor>.
            Create your chart at{" "}
            <Anchor href="https://tapistr.ee">tapistr.ee</Anchor>
          </Text>
        </Container>
      </div>
      <Container pt="sm" p="xs" maw={860}>
        <Group>
          {!isTouchDevice && (
            <>
              <Button
                miw={180}
                onClick={captureAndCopyToClipboard}
                leftIcon={!copyLoading && <FontAwesomeIcon icon={faCopy} />}
                styles={(theme) => ({
                  root: {
                    backgroundColor: "#FC4C02",
                    "&:not([data-disabled])": theme.fn.hover({
                      backgroundColor: theme.fn.darken("#FC4C02", 0.05),
                    }),
                  },
                })}
              >
                {copyLoading ? (
                  <Loader size="sm" visible={true} color="orange" />
                ) : (
                  "Copy as Image"
                )}
              </Button>
              <Tooltip
                label={
                  !copied &&
                  "Copy the image first so you can paste it into the tweet!"
                }
                multiline
                disabled={copied}
                w={400}
              >
                <Button
                  component="a"
                  href={tweetUrl}
                  target="_blank"
                  disabled={!copied}
                  data-disabled={!copied || undefined}
                  sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
                  leftIcon={<Image src="/x-logo.png" height={14} width={14} />}
                  miw={180}
                  styles={(theme) => ({
                    root: {
                      backgroundColor: "#000000",
                      "&:not([data-disabled])": theme.fn.hover({
                        backgroundColor: "#000000",
                      }),
                    },
                  })}
                >
                  Share on X
                </Button>
              </Tooltip>
            </>
          )}
          {isTouchDevice && (
            <Button
              onClick={onShare}
              fullWidth
              leftIcon={<FontAwesomeIcon icon={faShare} />}
              styles={(theme) => ({
                root: {
                  backgroundColor: "#FC4C02",
                  "&:not([data-disabled])": theme.fn.hover({
                    backgroundColor: theme.fn.darken("#FC4C02", 0.05),
                  }),
                },
              })}
            >
              Share
            </Button>
          )}
        </Group>
        <Text size="xs" color="dimmed" mt="sm">
          Tapistr.ee is not affiliated with or endorsed by Strava.
        </Text>
      </Container>
    </>
  );
}
