import { useEffect } from "react";
import { useListState } from "@mantine/hooks";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { StrictModeDroppable } from "./StrictModeDroppable";
import {
  useStripsQuery,
  useUpdateStripPositionsMutation,
} from "../queries/queries";
import { Loader } from "@mantine/core";

export function DndListStrip({ children }) {
  const { data: stripsData } = useStripsQuery();
  const stripsDataLength = stripsData?.length.toString();

  const updateStripPositionsMutation = useUpdateStripPositionsMutation();
  // const [localStripsData, setLocalStripsData] = useState(stripsData);

  const [state, handlers] = useListState(stripsData);

  // const updateStripPositionMutation = useUpdateStripPositionsMutation();

  useEffect(() => {
    handlers.setState((prevState) => {
      // Perform a shallow comparison for arrays of objects

      const isDifferent = prevState?.length !== stripsDataLength;

      // console.log("Added Url detected");
      return isDifferent ? stripsData : prevState;
    });
    // eslint-disable-next-line
  }, [stripsDataLength]);

  function handleDragEnd(destination, source, callback) {
    if (!destination) return;

    const newState = Array.from(state);
    const [removed] = newState.splice(source.index, 1);
    newState.splice(destination.index, 0, removed);
    handlers.setState(newState);

    const updatedStripPositions = newState?.map((item, index) => {
      return {
        id: item.id,
        strip: item.strip,
        stripPosition: index,
      };
    });

    if (callback) {
      callback(updatedStripPositions);
    }
  }

  function updateStrip(newState) {
    updateStripPositionsMutation.mutate(newState);
  }

  //Using stripsData.map here will cause the drag and drop to blink back momentarily.
  const items =
    Array.isArray(state) &&
    state?.map((item, index) => {
      return (
        <Draggable
          key={`${item.strip}-${item.id}`}
          index={index}
          draggableId={`${item.strip}-${item.id}`}
        >
          {(provided, snapshot) =>
            children({
              item,
              provided,
              snapshot,
            })
          }
        </Draggable>
      );
    });

  if (!stripsData) {
    return <Loader />;
  } else {
    return (
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          handleDragEnd(destination, source, updateStrip);
        }}
      >
        <StrictModeDroppable droppableId="dnd-list-strip" direction="vertical">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {items}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    );
  }
}
