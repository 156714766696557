import React from "react";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { IconBrandTwitter } from "@tabler/icons-react";
import { extractTweetId } from "../../utils/extractTweetId";
import { TwitterFollowButton } from "../CTAButtons";
import { Strip } from "../Strip";
import { Tweet } from "react-tweet";
// import { TwitterTweetEmbed } from "react-twitter-embed";

export function DummyTwitterStrip() {
  const linksDataByStrip = [
    { url: "https://twitter.com/elonmusk/status/1519480761749016577" },
    { url: "https://twitter.com/jack/status/20" },
    { url: "https://twitter.com/SBF_FTX/status/1591989554881658880" },
  ];

  return (
    <Strip
      id="twitter"
      nav="scroll"
      // gradientValues={["#000000"]}
      // title="Posts"
      // icon={{
      //   type: "image",
      //   icon: <Image src="/x-logo.png" height={28} width={28} mr={2} />,
      // }}
      gradientValues={["#1D9BF0"]}
      followButton={<TwitterFollowButton />}
      title="Tweets"
      icon={{ type: "fa", icon: faTwitter }}
      iconColor="white"
      // iconLink="YOUR TWITTER PROFILE...."TBU
    >
      {linksDataByStrip?.map((link, index) => {
        const tweetId = extractTweetId(link.url);
        return (
          <div key={index} style={{ maxWidth: "320px" }}>
            <Tweet id={tweetId} />
          </div>
          // <div key={link.id} style={{ maxWidth: "320px" }}>
          //   <TwitterTweetEmbed tweetId={tweetId} />
          // </div>
        );
      })}
    </Strip>
  );
}
