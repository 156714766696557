import {
  Button,
  Card,
  Center,
  Container,
  Flex,
  Group,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";

export function PricingCard({
  priceName,
  feature1,
  feature2,
  feature3,
  price,
}) {
  return (
    <>
      <Center>
        <Card m="xs" shadow="lg" radius="md" withBorder miw={300} maw={420}>
          <Title order={2} color="blue">
            <span className={priceName === "Premium" ? "gradient-text" : ""}>
              {priceName}
            </span>
          </Title>
          <Container p="xs">
            <Group mb={4}>
              <ThemeIcon
                color={feature1 ? "green" : "gray"}
                size="xs"
                radius="xl"
              >
                <IconCheck />
              </ThemeIcon>
              <Text color="#444">{feature1}</Text>
            </Group>
            <Group mb={4}>
              <ThemeIcon
                color={feature2 ? "green" : "gray"}
                size="xs"
                radius="xl"
              >
                <IconCheck />
              </ThemeIcon>
              <Text color="#444">{feature2}</Text>
            </Group>
            <Group mb={4} noWrap>
              <ThemeIcon
                color={feature3 ? "green" : "gray"}
                size="xs"
                radius="xl"
              >
                {feature3 ? <IconCheck /> : <IconX />}
              </ThemeIcon>
              <Text color={feature3 ? "#444" : "dimmed"}>
                Analytics dashboard
              </Text>
            </Group>
          </Container>
          <Container p="xs">
            <Flex align="center">
              <Text fw={500} size="xs" color="#444444">
                $
              </Text>
              <Text fw={500} mr={4} color="#444444">
                {price}
              </Text>{" "}
              <Text color="dimmed" size="sm">
                per month
              </Text>
            </Flex>
          </Container>
          <Button
            variant={priceName === "Premium" ? "gradient" : "light"}
            // color={priceName === "Premium" && "green"}
            gradient={
              priceName === "Premium"
                ? { from: "#516bfa", to: "#009bfa", deg: 35 }
                : ""
            }
            fullWidth
            component="a"
            href="https://tapistr.ee/sign-up"
          >
            Try for free
          </Button>
        </Card>
      </Center>
    </>
  );
}
