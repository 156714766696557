import { useState } from "react";
import { SpotifySearchBar } from "../components/SpotifySearchBar";
import { BookSelectionCard } from "../components/BookSelectionCard";
import { BooksSearchBar } from "../components/BooksSearchBar";
import { DropzoneButton } from "../components/DropzoneButton";
// import { EventInput } from "../components/EventInput";
import {
  ActionIcon,
  Anchor,
  Box,
  Button,
  Checkbox,
  Code,
  ColorInput,
  Divider,
  Flex,
  Group,
  Image,
  List,
  LoadingOverlay,
  Modal,
  ScrollArea,
  SegmentedControl,
  Text,
  Tooltip,
} from "@mantine/core";
import { StripInput } from "../components/StripInput";
import { UrlInput } from "../components/UrlInput";
import { MyTextArea } from "./MyTextArea";
import { TwitterIcon } from "@mantine/ds";
import {
  faSpotify,
  faGoodreads,
  faGithub,
  faYoutube,
  faAmazon,
  faAirbnb,
  faMedium,
  faItunesNote,
} from "@fortawesome/free-brands-svg-icons";
import {
  faFileExcel,
  faFilePowerpoint,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DndList } from "../components/DndList";
import { SpotifySelectionCard } from "../components/SpotifySelectionCard";
import { LinkedInSelectionCard } from "../components/LinkedInSelectionCard";
import { AddExperience } from "../components/AddExperience";
import { AddEducation } from "../components/AddEducation";
import {
  useUpdateFollowButtonVisibilityMutation,
  useStripsQuery,
  useLinksQuery,
  useProfileQuery,
  useCreateLinkMutation,
  useProfileMutation,
} from "../queries/queries";
import { LanguagesMultiSelect } from "./LanguageMultiSelect";
import { NewsletterEmbedInput } from "./NewsletterEmbedInput";
import { WebsiteSelectionCard } from "./WebsiteSelectionCard";
import {
  IconBrowser,
  IconExternalLink,
  IconPencil,
  IconPlus,
} from "@tabler/icons-react";
import { stripUrl } from "../utils/stripUrl";
import { AmazonAffiliateModal } from "./AmazonAffiliateModal";
import { useParams } from "react-router-dom";
import { ProfileInput } from "./ProfileInput";
import { baseUrl } from "../api/links";

export function StripInputRenderer({ id, strip, provided, snapshot }) {
  const { username } = useParams();
  const { data: linksData } = useLinksQuery();
  const [experienceModalOpened, setExperienceModalOpened] = useState(false);
  const [educationModalOpened, setEducationModalOpened] = useState(false);
  const [amazonAffiliateOpened, setAmazonAffiliateOpened] = useState(false);
  const [editing, setEditing] = useState(false);
  const [experienceId, setExperienceId] = useState(null);
  const [stripColor, setStripColor] = useState("");
  const [titleColor, setTitleColor] = useState("");

  const linksDataFilteredByStrip = linksData?.filter(
    (item) => item.strip === strip
  );

  const linksDataFilteredByStripAndExperience = linksData?.filter(
    (item) => item.strip === strip && item.experienceType === "Experience"
  );

  const linksDataFilteredByStripAndEducation = linksData?.filter(
    (item) => item.strip === strip && item.experienceType === "Education"
  );

  // const linksDataFilteredByStripAndPosts = linksData?.filter(
  //   (item) => item.strip === strip && item.experienceType === "Posts"
  // );

  const profileUrlEntryByStrip = linksDataFilteredByStrip?.find(
    (link) => link.isProfileUrl
  );

  // const hasYouTubeChannelId = linksData?.filter(
  //   (item) => item.strip === strip && item.youTubeChannelId !== null
  // );

  // const youTubeChannelId = linksDataFilteredByStrip?.filter(
  //   (item) => item?.youTubeChannelId !== null
  // )[0]?.youTubeChannelId;

  const stripsQuery = useStripsQuery();
  const { data: profileData } = useProfileQuery(username);

  const destinationUserId = profileData?.id;

  const [distanceUnit, setDistanceUnit] = useState(profileData?.distanceUnit);

  const {
    data: strips,
    // isLoading: stripsLoading,
    // error: stripsError,
  } = stripsQuery;

  // const websiteStrips = strips?.filter((strip) =>
  //   strip.strip.startsWith("Website")
  // );

  // console.log(websiteStrips);

  // const websiteStripCount = websiteStrips?.length;

  // const websiteStripNumbers = websiteStrips?.map((strip) => {
  //   // Use a regular expression to find numbers at the end of the string
  //   const match = strip?.strip.match(/\d+$/);
  //   // If a match is found, return it as a number, otherwise return null
  //   return match ? parseInt(match[0], 10) : 1;
  // });

  // console.log(websiteStripNumbers);

  // const highestWebsiteStripCount = Math.max(...websiteStripNumbers);
  // const highestWebsiteStripCount = 4;

  // console.log(linksDataFilteredByStrip);

  // const isFirstEntry = websiteStripCount === 0;
  // const nextWebsiteStripNumber = isFirstEntry
  //   ? ""
  //   : highestWebsiteStripCount + 1;

  const updateFollowButtonVisibilityMutation =
    useUpdateFollowButtonVisibilityMutation();

  function updateFollowButtonVisibility(visibility) {
    updateFollowButtonVisibilityMutation.mutate({
      id: id,
      data: {
        showFollowButton: visibility,
      },
    });
  }

  const showFollowButtonByStrip = strips?.find(
    (item) => item.strip === strip
  )?.showFollowButton;

  const substackRegex =
    /^https:\/\/[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.substack\.com\/?$/i;
  const beehiivRegex =
    /^https:\/\/embeds\.beehiiv\.com\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}\/?$/;

  const youtubeRegex =
    /^(https?:\/\/(?:www\.)?youtube\.com\/watch\?v=[a-zA-Z0-9_-]+(?:&list=[a-zA-Z0-9_-]+)?|https?:\/\/youtu\.be\/[a-zA-Z0-9_-]+|https?:\/\/(?:www\.)?youtube\.com\/@[\w-]+)$/i;

  const twitterRegex =
    /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[a-zA-Z0-9_]+$/;

  const [opened, setOpened] = useState(false);

  function twitterUrlToUsername(url) {
    const regex = /(?:twitter\.com|x\.com)\/(?:@)?(\w+)/;
    const match = url?.match(regex);
    return match ? match[1] : null;
  }

  const createLinkMutation = useCreateLinkMutation();

  function submitStripColorChange(newStripColor, id) {
    setStripColor(newStripColor);
    createLinkMutation.mutate({
      id: id,
      userId: destinationUserId,
      substackStripColor: newStripColor,
    });
  }

  function submitTitleColorChange(newTitleColor, id) {
    setTitleColor(newTitleColor);
    createLinkMutation.mutate({
      id: id,
      userId: destinationUserId,
      substackTitleColor: newTitleColor,
    });
  }

  const profileMutation = useProfileMutation();

  function handleProfileUpdate(distanceUnit) {
    profileMutation.mutate({
      username: username,
      updates: {
        distanceUnit: distanceUnit,
      },
    });
    setDistanceUnit(distanceUnit);
  }

  if (!strip) {
    return <LoadingOverlay />;
  } else {
    switch (strip) {
      case "LinkedIn":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/LinkedIn.png"
                alt="LinkedIn"
                width={40}
                height={40}
              />
            }
            strip="LinkedIn"
            stripColor="#FFFFFF"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500}>
              Enter the URL of your profile
            </Text>
            {profileUrlEntryByStrip ? (
              <>
                <UrlInput
                  key={profileUrlEntryByStrip.id}
                  defaultUrl={profileUrlEntryByStrip.url}
                  isProfileUrl={true}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://www.linkedin.com/in/samyeager/"
                  strip={profileUrlEntryByStrip.strip}
                  id={profileUrlEntryByStrip.id}
                  // provided={provided}
                  // snapshot={snapshot}
                />
                <Checkbox
                  label="Display 'Follow' button"
                  size="xs"
                  my="xs"
                  checked={showFollowButtonByStrip || false}
                  onChange={(event) => {
                    updateFollowButtonVisibility(event.currentTarget.checked);
                  }}
                />
              </>
            ) : (
              <UrlInput
                defaultUrl=""
                isProfileUrl={true}
                defaultSaved={false}
                defaultVerified={false}
                placeholder="https://www.linkedin.com/in/samyeager/"
                strip="LinkedIn"
              />
            )}
            {linksDataFilteredByStripAndExperience?.length > 0 && (
              <>
                <Divider my="xs" label="Work" labelPosition="left" />
                <ScrollArea.Autosize mah={460}>
                  <DndList
                    strip="LinkedIn"
                    experienceType="Experience"
                    key="LinkedInExperience"
                  >
                    {({ item, provided, snapshot }) => (
                      <LinkedInSelectionCard
                        key={item.id}
                        id={item.id}
                        url={item.url}
                        image={item.image}
                        coverImageUrl={item.coverImageUrl}
                        title={item.jobTitle}
                        company={item.company}
                        setOpened={setExperienceModalOpened}
                        setEditing={setEditing}
                        setExperienceId={setExperienceId}
                        provided={provided}
                        snapshot={snapshot}
                      />
                    )}
                  </DndList>
                </ScrollArea.Autosize>
              </>
            )}
            {linksDataFilteredByStripAndEducation?.length > 0 && (
              <>
                <Divider my="xs" label="Education" labelPosition="left" />
                <ScrollArea.Autosize mah={460}>
                  <DndList
                    strip="LinkedIn"
                    experienceType="Education"
                    key="LinkedInEducation"
                  >
                    {({ item, provided, snapshot }) => {
                      const fieldOfStudy = item?.fieldOfStudy
                        ? `, ${item.fieldOfStudy}`
                        : "";
                      const degreeAndMajor = item?.degree
                        ? item.degree + fieldOfStudy
                        : "";

                      return (
                        <LinkedInSelectionCard
                          key={item.id}
                          id={item.id}
                          url={item.url}
                          image={item.image}
                          coverImageUrl={item.coverImageUrl}
                          title={item.school}
                          company={degreeAndMajor}
                          setOpened={setEducationModalOpened}
                          setEditing={setEditing}
                          setExperienceId={setExperienceId}
                          provided={provided}
                          snapshot={snapshot}
                        />
                      );
                    }}
                  </DndList>
                </ScrollArea.Autosize>
              </>
            )}
            <>
              <Divider my="xs" label="Posts" labelPosition="left" />
              <Text size="sm" weight={500} m="0px 0px 0px 22px">
                Enter post URL
              </Text>
              <ScrollArea.Autosize mah={460}>
                <DndList key="LinkedIn" strip="LinkedIn" experienceType="Posts">
                  {({ item, provided, snapshot }) => (
                    <UrlInput
                      key={item.id}
                      defaultUrl={item.url}
                      defaultSaved={true}
                      defaultVerified={true}
                      experienceType="Posts"
                      placeholder="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6988525664752525313"
                      strip={item.strip}
                      id={item.id}
                      provided={provided}
                      snapshot={snapshot}
                    />
                  )}
                </DndList>
              </ScrollArea.Autosize>
              <UrlInput
                defaultUrl=""
                defaultSaved={false}
                defaultVerified={false}
                experienceType="Posts"
                placeholder="https://www.linkedin.com/embed/feed/update/urn:li:ugcPost:6988525664752525313"
                strip="LinkedIn"
              />
            </>
            <Box>
              <Divider my="xs" label="or" labelPosition="center" />
              <Group grow>
                <AddEducation
                  opened={educationModalOpened}
                  setOpened={setEducationModalOpened}
                  editing={editing}
                  setEditing={setEditing}
                  experienceId={experienceId}
                />
                <AddExperience
                  opened={experienceModalOpened}
                  setOpened={setExperienceModalOpened}
                  editing={editing}
                  setEditing={setEditing}
                  experienceId={experienceId}
                />
              </Group>
            </Box>
          </StripInput>
        );
      case "GitHub":
        return (
          <StripInput
            id={id}
            icon={<FontAwesomeIcon icon={faGithub} size="2x" />}
            strip="GitHub"
            stripColor="#24292F"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500}>
              Enter the URL of your profile
            </Text>
            {profileUrlEntryByStrip ? (
              <UrlInput
                key={profileUrlEntryByStrip.id}
                defaultUrl={profileUrlEntryByStrip.url}
                isProfileUrl={true}
                defaultSaved={true}
                defaultVerified={true}
                placeholder="https://github.com/aubreythreadgill" //Ok
                strip={profileUrlEntryByStrip.strip}
                id={profileUrlEntryByStrip.id}
                // provided={provided}
                // snapshot={snapshot}
              />
            ) : (
              <UrlInput
                defaultUrl=""
                isProfileUrl={true}
                defaultSaved={false}
                defaultVerified={false}
                placeholder="https://github.com/aubreythreadgill" //Ok
                strip="GitHub"
              />
            )}
            <LanguagesMultiSelect />
            <Checkbox
              label="Display 'Follow' button"
              size="xs"
              my="xs"
              checked={showFollowButtonByStrip || false}
              onChange={(event) => {
                updateFollowButtonVisibility(event.currentTarget.checked);
              }}
            />
          </StripInput>
        );
      case "Excel":
        return (
          <StripInput
            id={id}
            icon={<FontAwesomeIcon icon={faFileExcel} size="2x" />}
            strip="Excel"
            stripColor="#138146"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList key="Excel" strip="Excel">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="Enter URL"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="Enter URL"
              strip="Excel"
              // id={id}
            />
          </StripInput>
        );
      case "PowerPoint":
        return (
          <StripInput
            id={id}
            icon={<FontAwesomeIcon icon={faFilePowerpoint} size="2x" />}
            strip="PowerPoint"
            stripColor="#C43E1C"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList key="PowerPoint" strip="PowerPoint">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="Enter URL"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="Enter URL"
              strip="PowerPoint"
              // id={id}
            />
          </StripInput>
        );
      case "Twitter":
        return (
          <StripInput
            id={id}
            icon={<TwitterIcon size="1.5rem" color="white" />}
            strip="Twitter"
            stripColor="#00ACEE"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500}>
              Enter X/Twitter profile URL
            </Text>
            {profileUrlEntryByStrip ? (
              <UrlInput
                key={profileUrlEntryByStrip.id}
                defaultUrl={profileUrlEntryByStrip.url}
                isProfileUrl={true}
                defaultSaved={true}
                defaultVerified={true}
                placeholder="https://twitter.com/jack" //Ok
                strip={profileUrlEntryByStrip.strip}
                id={profileUrlEntryByStrip.id}
                regexCheck={twitterRegex}
                errorMessage="Insert valid URL (e.g. twitter.com/jack or x.com/jack"
                // provided={provided}
                // snapshot={snapshot}
              />
            ) : (
              <UrlInput
                defaultUrl=""
                isProfileUrl={true}
                defaultSaved={false}
                defaultVerified={false}
                placeholder="https://twitter.com/jack" //Ok
                strip="Twitter"
                regexCheck={twitterRegex}
                errorMessage="Insert valid URL (e.g. twitter.com/jack or x.com/jack"
              />
            )}
            <Flex>
              <Tooltip
                label={
                  profileUrlEntryByStrip?.url
                    ? "Not sure which tweets to include? Start by reviewing your most liked tweets."
                    : "Save your X/Twitter profile URL, and we'll generate a link to your top tweets."
                }
                multiline
                disabled={false}
                w={400}
              >
                <Button
                  mb="md"
                  variant="light"
                  maw={400}
                  fullWidth
                  component="a"
                  href={`https://twitter.com/search?q=%40${twitterUrlToUsername(
                    profileUrlEntryByStrip?.url
                  )}%20min_faves%3A10`}
                  data-disabled={!profileUrlEntryByStrip?.url || undefined}
                  target="_blank"
                  rightIcon={
                    <Flex align="center">
                      <Text fw={400}>[x.com]</Text>
                      <IconExternalLink size="1.05rem" stroke={1.5} />
                    </Flex>
                  }
                  sx={{ "&[data-disabled]": { pointerEvents: "all" } }}
                >
                  Take me to my top tweets
                </Button>
              </Tooltip>
              <div style={{ width: "144px" }}></div>
            </Flex>
            <Text size="sm" weight={500} m="0px 0px 0px 22px">
              Enter tweet URL
            </Text>
            <DndList key="Twitter" strip="Twitter" hideProfileLink>
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://twitter.com/jack/status/20"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://twitter.com/jack/status/20"
              strip="Twitter"
              // id={id}
            />
          </StripInput>
        );
      // case "X":
      //   return (
      //     <StripInput
      //       id={id}
      //       icon={<TwitterIcon size="1.5rem" color="white" />}
      //       strip="X"
      //       stripColor="#000000"
      //       provided={provided}
      //       snapshot={snapshot}
      //     >
      //       <Text size="sm" weight={500} m="0px 0px 0px 22px">
      //         Enter URL
      //       </Text>
      //       <DndList key="X" strip="X">
      //         {({ item, provided, snapshot }) => (
      //           <UrlInput
      //             key={item.id}
      //             defaultUrl={item.url}
      //             defaultSaved={true}
      //             defaultVerified={true}
      //             placeholder="https://x.com/jack/status/20"
      //             strip={item.strip}
      //             id={item.id}
      //             provided={provided}
      //             snapshot={snapshot}
      //           />
      //         )}
      //       </DndList>
      //       <UrlInput
      //         defaultUrl=""
      //         defaultSaved={false}
      //         defaultVerified={false}
      //         placeholder="https://x.com/jack/status/20"
      //         strip="X"
      //         // id={id}
      //       />
      //     </StripInput>
      //   );
      case "Instagram":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/Instagram.png"
                alt="Instagram"
                width={40}
                height={40}
              />
            }
            strip="Instagram"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} m="0px 0px 0px 22px">
              Enter profile or post URL
            </Text>
            <DndList key="Instagram" strip="Instagram">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://www.instagram.com/p/CWT_XMovfwl/"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://www.instagram.com/tpain"
              strip="Instagram"
              // id={id}
            />
            <Text size="xs" color="dimmed" mt="xs">
              <u>Note</u>: Posts must be from public accounts.
            </Text>
            <Flex align="center" mt="xs">
              <Text size="xs" color="dimmed">
                <u>Still not working?</u>: Enable embeds via
              </Text>
              <Anchor
                size="xs"
                href="https://help.instagram.com/252460186989212"
                target="_blank"
              >
                <Flex align="center" ml={4}>
                  <Text fw={400}>[Instagram]</Text>
                  <IconExternalLink size="1.05rem" stroke={1.5} />
                </Flex>
              </Anchor>
            </Flex>
          </StripInput>
        );
      case "Website":
        return (
          <StripInput
            id={id}
            icon={<IconBrowser size={40} stroke={1.5} color="#339AF0" />}
            strip="Website"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500}>
              Enter URL
            </Text>
            {linksDataFilteredByStrip?.length > 0 ? (
              <UrlInput
                key={linksDataFilteredByStrip[0]?.id}
                defaultUrl={linksDataFilteredByStrip[0]?.url}
                defaultSaved={true}
                defaultVerified={true}
                placeholder="https://www.tim.blog/"
                strip={linksDataFilteredByStrip[0]?.strip}
                id={linksDataFilteredByStrip[0]?.id}
                isNotDraggable={true}
              />
            ) : (
              <UrlInput
                defaultUrl=""
                defaultSaved={false}
                defaultVerified={false}
                placeholder="https://www.tim.blog/"
                strip="Website"
                isNotDraggable={true}
              />
            )}
          </StripInput>
        );
      // case "Website-Collection":
      //   return (
      //     <StripInput
      //       id={id}
      //       icon={<IconBrowser size={40} stroke={1.5} color="#339AF0" />}
      //       strip="Website-Collection"
      //       provided={provided}
      //       snapshot={snapshot}
      //     >
      //       <Text size="sm" weight={500}>
      //         Edit Title
      //       </Text>
      //       <UrlInput isNotDraggable={true} />
      //       <Text size="sm" weight={500}>
      //         Edit Icon
      //       </Text>
      //       <UrlInput isNotDraggable={true} />
      //       <Text size="sm" weight={500}>
      //         Enter URL
      //       </Text>
      //       <DndList key="Website-Collection" strip="Website-Collection">
      //         {({ item, provided, snapshot }) => (
      //           <WebsiteSelectionCard
      //             key={item.id}
      //             websiteId={item.id}
      //             strip={item.strip}
      //             url={item.url}
      //             title={item.title}
      //             description={item.description}
      //             coverImageUrl={item.coverImageUrl}
      //             favicon={item.favicon}
      //             provided={provided}
      //             snapshot={snapshot}
      //           />
      //         )}
      //       </DndList>
      //       <UrlInput
      //         defaultUrl=""
      //         defaultSaved={false}
      //         defaultVerified={false}
      //         placeholder="https://mysite.com"
      //         strip="Website-Collection"
      //         isNotDraggable={true}
      //       />
      //     </StripInput>
      //   );
      case "Facebook":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/Facebook-small.png"
                alt="Facebook"
                width={40}
                height={40}
              />
            }
            strip="Facebook"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} m="0px 0px 0px 22px">
              Enter page or post URL
            </Text>
            <DndList key="Facebook" strip="Facebook">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://www.facebook.com/1367277063/posts/10217803020051676/"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://www.facebook.com/1367277063/posts/10217803020051676/"
              strip="Facebook"
              // id={id}
            />
            <Text size="xs" color="dimmed" mt="xs">
              <u>Note</u>: Posts must be from public accounts.
            </Text>
          </StripInput>
        );
      case "TikTok":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/TikTok.png"
                alt="TikTok"
                radius="sm"
                width={40}
                height={40}
              />
            }
            strip="TikTok"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} m="0px 0px 0px 22px">
              Enter URL
            </Text>
            <DndList key="TikTok" strip="TikTok">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://www.tiktok.com/@mrbeast"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://www.tiktok.com/@larecepcion/video/7204840704600722734"
              strip="TikTok"
              // id={id}
            />
            <Text size="xs" color="dimmed" mt="xs">
              <u>Note</u>: Posts must be from public accounts.
            </Text>
          </StripInput>
        );
      case "Strava":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/strava-icon.svg"
                alt="Strava"
                radius="sm"
                width={40}
                height={40}
              />
            }
            strip="Strava"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} m="0px 0px 0px 12px">
              Connect with Strava
            </Text>
            <Text size="sm" m="0px 0px 0px 12px" mb="md">
              Click the button below to authorize Tapistree to display a heatmap
              of your daily Strava activity.
            </Text>
            <Anchor
              ml={8}
              radius="sm"
              href={`http://www.strava.com/oauth/authorize?client_id=120821&response_type=code&redirect_uri=${baseUrl}/auth/strava/exchange_token&scope=activity:read_all,profile:read_all`}
            >
              <img
                src="/btn_strava_connectwith_orange.svg"
                alt="Connect with Strava"
              />
            </Anchor>
            <Button
              disabled={linksDataFilteredByStrip?.length === 0}
              component="a"
              radius={0}
              mt={6}
              href={`https://tapistr.ee/${username}/strava`}
              ml={12}
              style={{ height: "40px" }}
            >
              View full Strava analysis 🏃
            </Button>
            {linksDataFilteredByStrip?.length > 0 && (
              <SegmentedControl
                value={distanceUnit}
                onChange={handleProfileUpdate}
                data={[
                  { label: "Miles", value: "miles" },
                  { label: "Kilometers", value: "kilometers" },
                ]}
                ml={12}
                mt="md"
              />
            )}
          </StripInput>
        );
      case "Books":
        return (
          <>
            <AmazonAffiliateModal
              opened={amazonAffiliateOpened}
              setOpened={setAmazonAffiliateOpened}
            />
            <StripInput
              id={id}
              icon={<FontAwesomeIcon icon={faGoodreads} size="2x" />}
              strip="Books"
              stripColor="#D6D0C4"
              provided={provided}
              snapshot={snapshot}
            >
              <ScrollArea.Autosize mah={470}>
                <DndList key="Books" strip="Books">
                  {({ item, provided, snapshot }) => (
                    <BookSelectionCard
                      key={item.id}
                      id={item.id}
                      image={item.coverImageUrl}
                      title={item.title}
                      authorName={item.authorName}
                      userRating={item.userRating}
                      provided={provided}
                      snapshot={snapshot}
                    />
                  )}
                </DndList>
                {profileData?.amazonAffiliateTag ? (
                  <Flex align="center">
                    <Text size="sm">Collecting affiliate commissions for:</Text>
                    <Code mx={4}>{profileData?.amazonAffiliateTag}</Code>
                    <ActionIcon
                      radius="xl"
                      onClick={() => setAmazonAffiliateOpened(true)}
                    >
                      <IconPencil size="0.9em" stroke={1} />
                    </ActionIcon>
                  </Flex>
                ) : (
                  <Anchor
                    size="xs"
                    ml="xs"
                    onClick={() => setAmazonAffiliateOpened(true)}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <IconPlus size="0.9rem" stroke={1.5} /> {"  "}
                    Add Amazon Affiliate ID
                  </Anchor>
                )}
              </ScrollArea.Autosize>
              <BooksSearchBar />
              {/* <BookRecommendations /> */}
            </StripInput>
          </>
        );
      case "Spotify":
        return (
          <StripInput
            id={id}
            icon={
              <FontAwesomeIcon
                icon={faSpotify}
                size="2x"
                style={{ color: "#45DB74" }}
              />
            }
            strip="Spotify"
            stripColor="black"
            provided={provided}
            snapshot={snapshot}
          >
            <ScrollArea.Autosize mah={470}>
              <DndList key="Spotify" strip="Spotify">
                {({ item, provided, snapshot }) => (
                  <SpotifySelectionCard
                    key={item.id}
                    id={item.id}
                    image={item.coverImageUrl}
                    itemName={item.albumName}
                    description={item.artistName}
                    episodeTitle={item.episodeTitle}
                    provided={provided}
                    snapshot={snapshot}
                  />
                )}
              </DndList>
            </ScrollArea.Autosize>
            <SpotifySearchBar />
          </StripInput>
        );
      case "YouTube":
        return (
          <StripInput
            id={id}
            icon={<FontAwesomeIcon icon={faYoutube} size="2x" />}
            strip="YouTube"
            stripColor="#FF0000"
            provided={provided}
            snapshot={snapshot}
          >
            {(profileData?.youTubeChannelUrl ||
              profileData?.youTubeChannelId) && (
              <div style={{ marginBottom: "24px" }}>
                <Text size="sm" weight={500}>
                  Channel used for Subscribe button
                </Text>
                <ProfileInput
                  defaultUrl={profileData?.youTubeChannelUrl}
                  isProfileUrl={true}
                  defaultSaved={profileData?.youTubeChannelUrl}
                  defaultVerified={true}
                  placeholder="https://www.youtube.com/@MrBeast"
                  strip="YouTube"
                />
                <Checkbox
                  label="Display 'Subscribe' button"
                  size="xs"
                  my={4}
                  checked={showFollowButtonByStrip || false}
                  onChange={(event) => {
                    updateFollowButtonVisibility(event.currentTarget.checked);
                  }}
                />
              </div>
            )}
            <Text size="sm" weight={500} ml="22px">
              Enter URL for a video or a channel
            </Text>
            <DndList key="YouTube" strip="YouTube">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://youtube.com/watch?v=dQw4w9WgXcQ"
                  strip={item.strip}
                  id={item.id}
                  regexCheck={youtubeRegex}
                  errorMessage="Embed a channel (e.g. https://youtube.com/@MrBeast) or a video (e.g. https://youtube.com/watch?v=dQw4w9WgXcQ)"
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://youtube.com/watch?v=dQw4w9WgXcQ"
              strip="YouTube"
              regexCheck={youtubeRegex}
              errorMessage="Embed a channel (e.g. https://youtube.com/@MrBeast) or a video (e.g. https://youtube.com/watch?v=dQw4w9WgXcQ)"
              // id={id}
            />
            <Text size="xs" color="dimmed" mt="xs">
              <u>Note</u>: Enter your channel link (e.g.
              https://youtube.com/@MrBeast) to dynamically shows your latest
              video.
            </Text>
          </StripInput>
        );
      case "Photos":
        return (
          <StripInput
            id={id}
            icon={<span className="add-strip-icon">📸</span>}
            strip="Photos"
            stripColor="#EEE1C3"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList key="Photos" strip="Photos">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="Enter URL"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="Enter URL"
              strip="Photos"
            />
            {/* <Divider my="xs" label="or" labelPosition="center" />
            <PhotoDropzone /> */}
          </StripInput>
        );
      case "Writing":
        return (
          <StripInput
            id={id}
            icon={<span className="add-strip-icon">📝</span>}
            strip="Writing"
            stripColor="#FAF3F0"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} m="0px 0px 0px 22px">
              Enter URL
            </Text>
            <DndList key="Writing" strip="Writing">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="http://www.paulgraham.com/gba.html"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="http://www.paulgraham.com/gba.html"
              strip="Writing"
            />
          </StripInput>
        );
      case "Medium":
        return (
          <StripInput
            id={id}
            icon={<FontAwesomeIcon icon={faMedium} size="2x" />}
            strip="Medium"
            stripColor="#000000"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} m="0px 0px 0px 22px">
              Enter URL
            </Text>
            <DndList key="Medium" strip="Medium">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="http://www.paulgraham.com/gba.html"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="http://www.paulgraham.com/gba.html"
              strip="Medium"
            />
          </StripInput>
        );
      case "About":
        return (
          <StripInput
            id={id}
            icon={<span className="add-strip-icon">👋</span>}
            strip="About"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter a few bullets
            </Text>
            <DndList key="About" strip="About">
              {({ item, provided, snapshot }) => (
                <MyTextArea
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="Financial analyst, guitar player, book worm"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <MyTextArea
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="Financial analyst, guitar player, book worm"
              strip="About"
            />
          </StripInput>
        );
      case "Favorites":
        return (
          <StripInput
            id={id}
            icon={<span className="add-strip-icon">⭐</span>}
            strip="Favorites"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500}>
              Enter links to favorite websites
            </Text>
            <DndList key="Favorites" strip="Favorites">
              {({ item, provided, snapshot }) => (
                <WebsiteSelectionCard
                  key={item.id}
                  websiteId={item.id}
                  strip={item.strip}
                  url={item.url}
                  title={item.title}
                  description={item.description}
                  coverImageUrl={item.coverImageUrl}
                  favicon={item.favicon}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://mysite.com"
              strip="Favorites"
              isNotDraggable={true}
            />
          </StripInput>
        );
      case "Newsletter":
        return (
          <StripInput
            id={id}
            icon={<span className="add-strip-icon">📧</span>}
            strip="Newsletter"
            provided={provided}
            snapshot={snapshot}
          >
            <DndList key="Newsletter" strip="Newsletter">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <NewsletterEmbedInput />
          </StripInput>
        );
      case "Substack":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/substack-icon.png"
                alt="Substack"
                width={40}
                height={40}
              />
            }
            strip="Substack"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500}>
              Enter Substack domain
            </Text>
            {linksDataFilteredByStrip?.length > 0 ? (
              <UrlInput
                key={linksDataFilteredByStrip[0]?.id}
                defaultUrl={linksDataFilteredByStrip[0]?.url}
                isProfileUrl={true}
                defaultSaved={true}
                defaultVerified={true}
                placeholder="https://pmarca.substack.com"
                strip="Substack"
                id={linksDataFilteredByStrip[0]?.id}
                regexCheck={substackRegex}
                errorMessage="Your embed ID will look like: https://pmarca.substack.com"
              />
            ) : (
              <UrlInput
                defaultUrl=""
                isProfileUrl={true}
                defaultSaved={false}
                defaultVerified={false}
                placeholder="https://pmarca.substack.com"
                strip="Substack"
                regexCheck={substackRegex}
                errorMessage="Your embed ID will look like: https://pmarca.substack.com"
              />
            )}
            <ColorInput
              placeholder="Pick color"
              label="Change background color"
              my="xs"
              value={stripColor}
              onChange={(newStripColor) =>
                submitStripColorChange(
                  newStripColor,
                  linksDataFilteredByStrip[0]?.id
                )
              }
            />
            <ColorInput
              placeholder="Pick color"
              label="Change title color"
              my="xs"
              value={titleColor}
              onChange={(newTitleColor) =>
                submitTitleColorChange(
                  newTitleColor,
                  linksDataFilteredByStrip[0]?.id
                )
              }
            />
          </StripInput>
        );
      case "Substack2":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/substack-icon.png"
                alt="Substack2"
                width={40}
                height={40}
              />
            }
            strip="Substack2"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500}>
              Enter Substack domain
            </Text>
            {linksDataFilteredByStrip?.length > 0 ? (
              <UrlInput
                key={linksDataFilteredByStrip[0]?.id}
                defaultUrl={linksDataFilteredByStrip[0]?.url}
                isProfileUrl={true}
                defaultSaved={true}
                defaultVerified={true}
                placeholder="https://pmarca.substack.com"
                strip="Substack2"
                id={linksDataFilteredByStrip[0]?.id}
                regexCheck={substackRegex}
                errorMessage="Your embed ID will look like: https://pmarca.substack.com"
              />
            ) : (
              <UrlInput
                defaultUrl=""
                isProfileUrl={true}
                defaultSaved={false}
                defaultVerified={false}
                placeholder="https://pmarca.substack.com"
                strip="Substack2"
                regexCheck={substackRegex}
                errorMessage="Your embed ID will look like: https://pmarca.substack.com"
              />
            )}
            <ColorInput
              placeholder="Pick color"
              label="Change background color"
              my="xs"
              value={stripColor}
              onChange={(newStripColor) =>
                submitStripColorChange(
                  newStripColor,
                  linksDataFilteredByStrip[0]?.id
                )
              }
            />
            <ColorInput
              placeholder="Pick color"
              label="Change title color"
              my="xs"
              value={titleColor}
              onChange={(newTitleColor) =>
                submitTitleColorChange(
                  newTitleColor,
                  linksDataFilteredByStrip[0]?.id
                )
              }
            />
          </StripInput>
        );
      case "Beehiiv":
        return (
          <>
            <Modal
              opened={opened}
              onClose={() => setOpened(false)}
              closeOnClickOutside
              title={<b>Create a Beehiiv sign-up embed</b>}
            >
              <List type="ordered" size="sm">
                <List.Item>
                  Go to{" "}
                  <Anchor
                    href="https://app.beehiiv.com/settings/publication/subscribe_forms"
                    target="_blank"
                  >
                    https://app.beehiiv.com/settings/publication/subscribe_forms
                  </Anchor>
                  .
                </List.Item>
                <List.Item>
                  Click <b>"Create new form"</b> and save.
                </List.Item>
                <List.Item>
                  Click <b>"View"</b> and you will find the publication id here:
                  <Image src="/beehiiv-embed.png" width={400} mb="sm" />
                </List.Item>
                <List.Item>
                  Finally, paste this into the Tapistree input and save.
                </List.Item>
              </List>
            </Modal>
            <StripInput
              id={id}
              icon={
                <Image
                  src="/beehiiv.png"
                  alt="Beehiiv"
                  width={40}
                  height={40}
                />
              }
              strip="Beehiiv"
              provided={provided}
              snapshot={snapshot}
            >
              <Flex align="center">
                Enter your Beehiiv embed URL
                <Anchor size="xs" ml="xs" onClick={() => setOpened(true)}>
                  {"  "}
                  (What's this?)
                </Anchor>
              </Flex>
              {linksDataFilteredByStrip?.length > 0 ? (
                <UrlInput
                  key={linksDataFilteredByStrip[0]?.id}
                  defaultUrl={linksDataFilteredByStrip[0]?.url}
                  isProfileUrl={true}
                  defaultSaved={true}
                  defaultVerified={true}
                  strip="Beehiiv"
                  id={linksDataFilteredByStrip[0]?.id}
                  // regexCheck={beehiivRegex}
                  placeholder="https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
                  // errorMessage="Your embed URL should look like: https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
                />
              ) : (
                <UrlInput
                  defaultUrl=""
                  isProfileUrl={true}
                  defaultSaved={false}
                  defaultVerified={false}
                  strip="Beehiiv"
                  // regexCheck={beehiivRegex}
                  placeholder="https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
                  // errorMessage="Your embed URL should look like: https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
                />
              )}
            </StripInput>
          </>
        );
      case "Beehiiv2":
        return (
          <>
            <Modal
              opened={opened}
              onClose={() => setOpened(false)}
              closeOnClickOutside
              title={<b>Create a Beehiiv sign-up embed</b>}
            >
              <List type="ordered" size="sm">
                <List.Item>
                  Go to{" "}
                  <Anchor
                    href="https://app.beehiiv.com/settings/publication/subscribe_forms"
                    target="_blank"
                  >
                    https://app.beehiiv.com/settings/publication/subscribe_forms
                  </Anchor>
                  .
                </List.Item>
                <List.Item>
                  Click <b>"Create new form"</b> and save.
                </List.Item>
                <List.Item>
                  Click <b>"View"</b> and you will find the publication id here:
                  <Image src="/beehiiv-embed.png" width={400} mb="sm" laz />
                </List.Item>
                <List.Item>
                  Finally, paste this into the Tapistree input and save.
                </List.Item>
              </List>
            </Modal>
            <StripInput
              id={id}
              icon={
                <Image
                  src="/beehiiv.png"
                  alt="Beehiiv2"
                  width={40}
                  height={40}
                />
              }
              strip="Beehiiv2"
              provided={provided}
              snapshot={snapshot}
            >
              <Flex align="center">
                Enter your Beehiiv embed URL
                <Anchor size="xs" ml="xs" onClick={() => setOpened(true)}>
                  {"  "}
                  (What's this?)
                </Anchor>
              </Flex>
              {linksDataFilteredByStrip?.length > 0 ? (
                <UrlInput
                  key={linksDataFilteredByStrip[0]?.id}
                  defaultUrl={linksDataFilteredByStrip[0]?.url}
                  isProfileUrl={true}
                  defaultSaved={true}
                  defaultVerified={true}
                  strip="Beehiiv2"
                  id={linksDataFilteredByStrip[0]?.id}
                  // regexCheck={beehiivRegex}
                  placeholder="https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
                  // errorMessage="Your embed URL should look like: https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
                />
              ) : (
                <UrlInput
                  defaultUrl=""
                  isProfileUrl={true}
                  defaultSaved={false}
                  defaultVerified={false}
                  strip="Beehiiv2"
                  // regexCheck={beehiivRegex}
                  placeholder="https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
                  // errorMessage="Your embed URL should look like: https://embeds.beehiiv.com/0fad60d0-577f-459d-9a2e-a06b6f40dd00"
                />
              )}
            </StripInput>
          </>
        );
      case "Movies":
        return (
          <StripInput
            id={id}
            icon={<span className="add-strip-icon">🎥</span>}
            strip="Movies"
            stripColor="yellow"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList key="Movies" strip="Movies">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="Enter URL"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="Enter URL"
              strip="Movies"
              // id={id}
            />
          </StripInput>
        );

      // case "Events":
      //   return (
      //     <StripInput
      //       id={id}
      //       icon="📅"
      //       strip="Events"
      //       stripColor="#E3E6E9F2"
      //       provided={provided}
      //       snapshot={snapshot}
      //     >
      //       {/* <Text size="sm" weight={500} ml="22px">
      //             Enter URL
      //           </Text> */}
      //       <DndList key="Events" strip="Events">
      //         {/* {({ item, provided, snapshot }) => (
      //               <UrlInput
      //                 key={item.id}
      //                 defaultUrl={item.url}
      //                 defaultSaved={true}
      //                 defaultVerified={true}
      //                 placeholder="Enter URL"
      //                 strip={item.strip}
      //                 id={item.id}

      //                 provided={provided}
      //                 snapshot={snapshot}
      //               />
      //             )} */}
      //       </DndList>
      //       <EventInput />
      //     </StripInput>
      //   );

      case "Resume":
        return (
          <StripInput
            id={id}
            icon={<span className="add-strip-icon">📝</span>}
            strip="Resume"
            stripColor="white"
            provided={provided}
            snapshot={snapshot}
          >
            <DropzoneButton />
          </StripInput>
        );
      case "Amazon":
        return (
          <StripInput
            id={id}
            icon={<FontAwesomeIcon icon={faAmazon} size="2x" />}
            strip="Amazon"
            stripColor="#444654"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList strip="Amazon" key="Amazon">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="Enter URL"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="Enter URL"
              strip="Amazon"
              // id={id}
            />
          </StripInput>
        );
      case "Airbnb":
        return (
          <StripInput
            id={id}
            icon={<FontAwesomeIcon icon={faAirbnb} size="2x" />}
            strip="Airbnb"
            stripColor="#FF385C"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList strip="Airbnb" key="Airbnb">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://www.airbnb.com/rooms/6647647"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://www.airbnb.com/rooms/6647647"
              strip="Airbnb"
              // id={id}
            />
          </StripInput>
        );

      case "Apple-Music":
        return (
          <StripInput
            id={id}
            icon={
              <FontAwesomeIcon
                icon={faItunesNote}
                size="2x"
                style={{ color: "#F5334D" }}
              />
            }
            strip="Apple-Music"
            stripColor="#FAFAFA"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList strip="Apple-Music" key="Apple-Music">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://music.apple.com/us/album/1989/1440935467"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://music.apple.com/us/album/1989/1440935467"
              strip="Apple-Music"
              // id={id}
            />
          </StripInput>
        );

      case "Apple-Podcasts":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/apple-podcasts.png"
                alt="Podcasts"
                width={40}
                height={40}
              />
            }
            strip="Apple-Podcasts"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList strip="Apple-Podcasts" key="Apple-Podcasts">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="https://podcasts.apple.com/us/podcast/huberman-lab/id1545953110"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="https://podcasts.apple.com/us/podcast/huberman-lab/id1545953110"
              strip="Apple-Podcasts"
              // id={id}
            />
          </StripInput>
        );

      case "Eventbrite":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="/eventbrite-icon.png"
                alt="Eventbrite"
                width={40}
                height={40}
                radius="sm"
              />
            }
            strip="Eventbrite"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} m="0px 0px 0px 12px">
              Connect your Eventbrite Organizer account
            </Text>
            <Text size="sm" m="0px 0px 0px 12px" mb="md">
              Click the button below to authorize Tapistree to automatically
              display your upcoming events.
            </Text>
            <Anchor href={`${baseUrl}/auth/eventbrite`} ml={11}>
              <Button
                leftIcon={
                  <Image
                    src="/eventbrite-organizer-icon.jpeg"
                    height={24}
                    width={24}
                  />
                }
                styles={(theme) => ({
                  root: {
                    backgroundColor: "#1C0A3E",
                    "&:not([data-disabled])": theme.fn.hover({
                      backgroundColor: theme.fn.darken("#1C0A3E", 0.05),
                    }),
                  },
                })}
              >
                <b>Connect with Eventbrite</b>
              </Button>
            </Anchor>
          </StripInput>
        );

      case "Slides":
        return (
          <StripInput
            id={id}
            icon={
              <Image
                src="https://www.gstatic.com/images/branding/product/1x/slides_2020q4_48dp.png"
                alt="Slides"
                width={40}
                height={40}
              />
            }
            strip="Slides"
            stripColor="#FBBC04"
            provided={provided}
            snapshot={snapshot}
          >
            <Text size="sm" weight={500} ml="22px">
              Enter URL
            </Text>
            <DndList key="Slides" strip="Slides">
              {({ item, provided, snapshot }) => (
                <UrlInput
                  key={item.id}
                  defaultUrl={item.url}
                  defaultSaved={true}
                  defaultVerified={true}
                  placeholder="Enter URL"
                  strip={item.strip}
                  id={item.id}
                  provided={provided}
                  snapshot={snapshot}
                />
              )}
            </DndList>
            <UrlInput
              defaultUrl=""
              defaultSaved={false}
              defaultVerified={false}
              placeholder="Enter URL"
              strip="Slides"
              // id={id}
            />
          </StripInput>
        );
      // Add cases for other stripPosition values
      default:
        return (
          <StripInput
            id={id}
            icon={
              linksDataFilteredByStrip?.length > 0 &&
              linksDataFilteredByStrip[0]?.favicon ? (
                <Image
                  src={linksDataFilteredByStrip[0]?.favicon}
                  alt={stripUrl(linksDataFilteredByStrip[0]?.url)}
                  width={40}
                  height={40}
                  radius="sm"
                  withPlaceholder
                />
              ) : (
                <IconBrowser size={40} stroke={1.5} color="#339AF0" />
              )
            }
            strip={strip}
            alternateStripName={
              strip.startsWith("Website") &&
              linksDataFilteredByStrip?.length > 0
                ? stripUrl(linksDataFilteredByStrip[0]?.url)
                : "Website"
            }
            provided={provided}
            snapshot={snapshot}
          >
            {linksDataFilteredByStrip?.length > 0 ? (
              <WebsiteSelectionCard
                key={linksDataFilteredByStrip[0]?.id}
                websiteId={linksDataFilteredByStrip[0]?.id}
                stripId={id}
                strip={linksDataFilteredByStrip[0]?.strip}
                url={linksDataFilteredByStrip[0]?.url}
                title={linksDataFilteredByStrip[0]?.title}
                description={linksDataFilteredByStrip[0]?.description}
                coverImageUrl={linksDataFilteredByStrip[0]?.coverImageUrl}
                favicon={linksDataFilteredByStrip[0]?.favicon}
                iconBackgroundColor={strip.iconBackgroundColor}
                isNotDraggable={true}
              />
            ) : (
              <>
                <Text size="sm" weight={500}>
                  Enter URL
                </Text>
                <UrlInput
                  defaultUrl=""
                  defaultSaved={false}
                  defaultVerified={false}
                  placeholder="https://www.tim.blog/"
                  strip={strip}
                  isNotDraggable={true}
                  // id={id}
                />
              </>
            )}
          </StripInput>
        );
    }
  }
}
