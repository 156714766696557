import { useLocation, useNavigate } from "react-router-dom";
import { exchangeStravaToken } from "../api/links";
import { useAuth } from "@clerk/clerk-react";
import { useEffect, useState } from "react";

export const StravaAuthHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const [codeExchanged, setCodeExchanged] = useState(false);

  useEffect(() => {
    const fetchStravaData = async () => {
      try {
        if (codeExchanged) {
          // Prevent running the effect if the code has already been exchanged
          return;
        }

        const token = await getToken();
        const queryParams = new URLSearchParams(location.search);
        const code = queryParams.get("code");

        if (code && token) {
          await exchangeStravaToken(code, token);
          console.log("called exchangeStravatoken");
          setCodeExchanged(true); // Ensure this effect doesn't run again for the same code
          navigate("/interim");
        } else {
          console.error("Strava code not found in URL parameters.");
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching the token or exchanging the Strava token.",
          error
        );
      }
    };

    fetchStravaData();
    // Adding `codeExchanged` to the dependency array ensures the effect only reruns if this state changes
  }, [codeExchanged, location.search, getToken, navigate]);

  return <div>Loading...</div>;
};
