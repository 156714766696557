import {
  Flex,
  Image,
  Paper,
  ScrollArea,
  UnstyledButton,
  TextInput,
  Group,
  Container,
  Title,
} from "@mantine/core";
import { spacesToDashes } from "../utils/dahesToSpaces";
import { icons, colors } from "../components/MyNavbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGithub,
  faSpotify,
  faYoutube,
  faMedium,
  // faAirbnb,
  faItunesNote,
  faGoodreads,
} from "@fortawesome/free-brands-svg-icons";
import { TwitterIcon } from "@mantine/ds";
import { NavIconStatic } from "../components/NavIconStatic";
// import { DummyAirbnbStrip } from "../components/DummyStrips/DummyAirbnbStrip";
import { useEffect, useState } from "react";
import { DummyBooksStrip } from "../components/DummyStrips/DummyBooksStrip";
import { DummyBeehiivStrip } from "../components/DummyStrips/DummyBeehiivStrip";
import { DummyGitHubStrip } from "../components/DummyStrips/DummyGitHubStrip";
import { DummyFavoritesStrip } from "../components/DummyStrips/DummyFavoritesStrip";
import { DummyLinkedInStrip } from "../components/DummyStrips/DummyLinkedInStrip";
import { DummySpotifyStrip } from "../components/DummyStrips/DummySpotifyStrip";
import { DummyTwitterStrip } from "../components/DummyStrips/DummyTwitterStrip";
import { DummyInstagramStrip } from "../components/DummyStrips/DummyInstagramStrip";
import { DummyFacebookStrip } from "../components/DummyStrips/DummyFacebookStrip";
import { DummyTikTokStrip } from "../components/DummyStrips/DummyTikTokStrip";
import { DummySubstackStrip } from "../components/DummyStrips/DummySubstackStrip";
import { DummyYouTubeStrip } from "../components/DummyStrips/DummyYouTubeStrip";
import { DummyMediumStrip } from "../components/DummyStrips/DummyMediumStrip";
import { DummyAppleMusicStrip } from "./DummyStrips/DummyAppleMusicStrip";
import { DummyApplePodcastsStrip } from "./DummyStrips/DummyApplePodcastsStrip";
import {
  IconChevronLeft,
  IconChevronRight,
  IconReload,
} from "@tabler/icons-react";
import { useMediaQuery } from "@mantine/hooks";

export function SectionEmbeddings() {
  const [selectedStrip, setSelectedStrip] = useState("Twitter");
  const [isAutoCycling, setIsAutoCycling] = useState(true);

  const isLargerThanSm = useMediaQuery("(min-width: 48em)");

  useEffect(() => {
    if (isAutoCycling) {
      let currentIndex = supportedStripOptions.findIndex(
        (option) => option.strip === selectedStrip
      );

      const intervalId = setInterval(() => {
        currentIndex = (currentIndex + 1) % supportedStripOptions.length;
        setSelectedStrip(supportedStripOptions[currentIndex].strip);
      }, 6000);

      return () => clearInterval(intervalId);
    }
  }, [isAutoCycling, selectedStrip]);

  const handleItemClick = (strip) => {
    setSelectedStrip(strip);
    setIsAutoCycling(false);
  };

  const supportedStripOptions = [
    {
      strip: "Twitter",
      icon: <TwitterIcon size="1.5rem" color="white" />,
      iconBackgroundColor: "#00ACEE",
      description: "Add your favorite tweets",
    },
    {
      strip: "Spotify",
      icon: (
        <FontAwesomeIcon
          icon={faSpotify}
          size="2x"
          style={{ color: "#45DB74" }}
        />
      ),
      iconBackgroundColor: "#000000",
      description: "Add podcasts and albums",
    },
    {
      strip: "YouTube",
      icon: (
        <FontAwesomeIcon
          icon={faYoutube}
          size="2x"
          style={{
            color: "#FF0000",
          }}
        />
      ),
      iconBackgroundColor: "white",
      description: "Add your favorite videos",
    },
    {
      strip: "Substack",
      icon: (
        <Image src="/substack-icon.png" alt="Substack" width={40} height={40} />
      ),
      description: "Add Substack sign-up embed",
    },
    {
      strip: "Beehiiv",
      icon: <Image src="/beehiiv.png" alt="Beehiiv" width={40} height={40} />,
      description: "Add Beehiiv sign-up embed",
    },
    {
      strip: "TikTok",
      icon: <Image src="/TikTok.png" alt="TikTok" width={40} height={40} />,
      iconBackgroundColor: "",
      description: "Add your favorite posts",
    },
    {
      strip: "Instagram",
      icon: (
        <Image src="/Instagram.png" alt="Instagram" width={40} height={40} />
      ),
      iconBackgroundColor: "",
      description: "Add your favorite posts",
    },
    {
      strip: "LinkedIn",
      icon: <Image src="/LinkedIn.png" alt="LinkedIn" width={40} height={40} />,
      iconBackgroundColor: "",
      description: "Add education & experience",
    },
    {
      strip: "GitHub",
      icon: <FontAwesomeIcon icon={faGithub} size="2x" />,
      iconBackgroundColor: "#24292F",
      description: "Add skills, commit activity, and more",
    },

    {
      strip: "Favorites",
      icon: <span className="add-strip-icon">⭐</span>,
      iconBackgroundColor: "",
      description: "Add favorite websites",
    },

    {
      strip: "Apple-Music",
      icon: <FontAwesomeIcon icon={faItunesNote} size="2x" />,
      iconBackgroundColor: "#F5334D",
      description: "Add your favorite albums & songs",
    },
    {
      strip: "Apple-Podcasts",
      icon: (
        <Image
          src="/apple-podcasts.png"
          alt="Podcasts"
          width={40}
          height={40}
        />
      ),
      iconBackgroundColor: "",
      description: "Add your favorite podcasts",
    },
    {
      strip: "Books",
      icon: <FontAwesomeIcon icon={faGoodreads} size="2x" />,
      iconBackgroundColor: "#D6D0C4",
      description: "Add your favorite books",
    },
    // {
    //   strip: "Writing",
    //   icon: <span className="add-strip-icon">📝</span>,
    //   iconBackgroundColor: "#FAF3F0",
    //   description: "Add your favorite essays or articles",
    // },
    // {
    //   strip: "Airbnb",
    //   icon: <FontAwesomeIcon icon={faAirbnb} size="2x" />,
    //   iconBackgroundColor: "#FF385C",
    //   description: "Add Airbnbs",
    // },
    {
      strip: "Medium",
      icon: <FontAwesomeIcon icon={faMedium} size="2x" />,
      // iconBackgroundColor: "#FFFFFF",
      description: "Add Medium posts",
    },
    {
      strip: "Facebook",
      icon: (
        <Image
          src="/Facebook-small.png"
          alt="Facebook"
          width={40}
          height={40}
        />
      ),
      iconBackgroundColor: "",
      description: "Add your favorite posts",
    },
  ];

  const dummyStripComponents = {
    "Apple-Music": <DummyAppleMusicStrip />,
    "Apple-Podcasts": <DummyApplePodcastsStrip />,
    Books: <DummyBooksStrip />,
    Favorites: <DummyFavoritesStrip />,
    GitHub: <DummyGitHubStrip />,
    LinkedIn: <DummyLinkedInStrip />,
    Spotify: <DummySpotifyStrip />,
    Twitter: <DummyTwitterStrip />,
    Instagram: <DummyInstagramStrip />,
    Facebook: <DummyFacebookStrip />,
    TikTok: <DummyTikTokStrip />,
    Substack: <DummySubstackStrip />,
    Beehiiv: <DummyBeehiivStrip />,
    YouTube: <DummyYouTubeStrip />,
    // Writing: <DummyWritingStrip />,
    Medium: <DummyMediumStrip />,
    // Airbnb: <DummyAirbnbStrip />,
  };

  return (
    <div
      style={{
        backgroundColor: "#F6F9FC",
        paddingTop: "30px",
      }}
    >
      <Title order={1} color="#444" ta="center">
        <span className="gradient-text">Give it a try</span>👇
      </Title>
      <Container pb={84} pt="xl">
        <Paper
          withBorder
          pb="sm"
          radius="lg"
          maw={1000}
          sx={{ backgroundColor: "#F8FAFC" }}
        >
          <Group
            align="center"
            position="apart"
            spacing="xs"
            noWrap
            sx={{
              backgroundColor: "#EDEFF6",
              borderRadius: "16px 16px 0px 0px",
              borderBottom: "1px solid #DBDDE3",
            }}
            py="sm"
            px="md"
            mb="xs"
          >
            <Flex align="center">
              <div className="base-circle circle-red"></div>
              <div className="base-circle circle-yellow"></div>
              <div className="base-circle circle-green"></div>

              {isLargerThanSm && (
                <Group align="center" spacing={4} ml="sm">
                  <IconChevronLeft size={20} stroke={1.5} color="gray" />
                  <IconChevronRight size={20} stroke={1.5} color="gray" />
                  <IconReload size={20} stroke={1.5} color="gray" />
                </Group>
              )}
            </Flex>
            <TextInput
              value="https://tapistr.ee/aubrey"
              radius="md"
              disabled
              styles={{
                root: {
                  fontSize: "16px",
                  flexGrow: "1 !important",
                },
              }}
            />
            {/* This is needed */}
            <Group></Group>
          </Group>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "50px",
              padding: "0px 10px",
              borderRadius: "24px",
              backgroundColor: "#202123",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              border: "none",
              maxWidth: "850px",
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "15px",
            }}
          >
            <ScrollArea type="never" style={{ borderRadius: "32px" }}>
              <Flex
                gap="lg"
                align="center"
                justify="center"
                px={10}
                style={{
                  flexGrow: 1,
                  minHeight: "32px",
                  overflowY: "hidden",
                }}
              >
                {supportedStripOptions?.map((item) => {
                  const strip = spacesToDashes(item.strip?.toLowerCase());
                  if (!icons[strip]) {
                    console.error(`No icon found for strip: ${strip}`);
                    return null; // or return a default icon
                  }
                  return (
                    <UnstyledButton
                      key={strip}
                      onClick={() => {
                        setSelectedStrip(item.strip);
                        handleItemClick(item.strip);
                      }}
                    >
                      <NavIconStatic
                        key={strip}
                        icon={icons[strip]}
                        iconColor={colors[strip]}
                      />
                    </UnstyledButton>
                  );
                })}
              </Flex>
            </ScrollArea>
          </div>
          <div
            style={{
              maxWidth: "850px",
              margin: "auto",
              height: "600px",
            }}
            onClick={() => setIsAutoCycling(false)}
          >
            {dummyStripComponents[selectedStrip]}
          </div>
        </Paper>
      </Container>
    </div>
  );
}
