import { StripDouble } from "../StripDouble";
import { Image } from "@mantine/core";
import { LinkedInCard } from "../LinkedInCard";
import { LinkedInAddConnectionButton } from "../CTAButtons";
import { cleanUsername } from "../../utils/cleanUsername";

export function DummyLinkedInStrip() {
  const experienceItems = [
    {
      id: "034dd9db-0504-4a51-91da-efd7432aa6d4",
      url: "emojiclue.com",
      strip: "LinkedIn",
      userId: "54ac9943-b132-42eb-8054-19ac3e8b81b4",
      coverImageUrl: "https://logo.clearbit.com/emojiclue.com",
      experienceType: "Experience",
      company: "EmojiClue",
      jobTitle: "Chief Emoji Officer",
      location: "Newport Beach, CA",
    },
    {
      id: "98286cde-a566-40c1-b92b-492b9910cb16",
      url: "pricespider.com",
      strip: "LinkedIn",
      coverImageUrl: "https://logo.clearbit.com/pricespider.com",
      experienceType: "Experience",
      company: "PriceSpider",
      jobTitle: "Director, Strategic Projects",
      location: "Irvine, CA",
    },
    {
      id: "951c65ae-39e8-4458-9293-492a97864261",
      url: "frontiergrowth.com",
      strip: "LinkedIn",
      coverImageUrl: "https://logo.clearbit.com/frontiergrowth.com",
      experienceType: "Experience",
      company: "Frontier Growth",
      jobTitle: "Associate",
      location: "Charlotte, NC",
    },

    {
      id: "a431eab6-9de2-44ac-8d43-5380b29bb193",
      url: "raymondjames.com",
      strip: "LinkedIn",
      userId: "54ac9943-b132-42eb-8054-19ac3e8b81b4",
      coverImageUrl: "https://logo.clearbit.com/raymondjames.com",
      experienceType: "Experience",
      company: "Raymond James",
      jobTitle: "Investment Banking Analyst",
      location: "Atlanta, GA",
      startMonth: "",
      startYear: "",
      endMonth: "",
      endYear: "",
      currentPosition: false,
      jobDescription: "",
    },
  ];
  const educationItems = [
    {
      id: "133504c3-650b-4144-940c-5fb0ddfb0c52",
      url: "ycombinator.com",
      strip: "LinkedIn",
      coverImageUrl: "https://logo.clearbit.com/ycombinator.com",
      experienceType: "Education",
      school: "Y Combinator",
      degree: "S23",
      location: "San Francisco, CA",
    },
    {
      id: "80b394bd-2c15-4e3a-bd2a-f82e1eedeaa0",
      url: "vanderbilt.edu",
      strip: "LinkedIn",
      coverImageUrl: "https://logo.clearbit.com/vanderbilt.edu",
      experienceType: "Education",
      school: "Vanderbilt University",
      degree: "B.A. Economics",
      location: "Nashville, TN",
    },
  ];
  const postItems = [];

  const profileUrl = "https://www.linkedin.com/in/aubreythreadgill/";

  const finalProfileUrl = profileUrl
    ? `https://linkedin.com/in/${cleanUsername(profileUrl)}`
    : null; //Ensures it's a URL whether the person provided url or username.

  return (
    <StripDouble
      id="linkedin"
      nav="scroll"
      gradientValues={["#F5F2ED"]}
      followButton={<LinkedInAddConnectionButton link={finalProfileUrl} />}
      // titleColor="#1A1A1A"
      titleColor="#494949"
      title={experienceItems?.length > 0 && "Experience"}
      title2={educationItems?.length > 0 && "Education"}
      title3={postItems?.length > 0 && "Posts"}
      // icon={{ type: "fa", icon: faLinkedin }}
      icon={{
        type: "image",
        icon: <Image src="/LinkedIn.png" height={31} width={31} />,
      }}
      iconLink={finalProfileUrl}
      iconColor="#0A66C2"
    >
      {experienceItems?.map((item, index) => {
        return (
          <LinkedInCard
            key={`${index}${item.title}`}
            type="Experience"
            image={item.image}
            coverImageUrl={item.coverImageUrl}
            company={item.company}
            title={item.jobTitle}
            location={item.location}
            description={item.jobDescription}
            link={item.link}
            url={item.url}
            startMonth={item.startMonth}
            startYear={item.startYear}
            endMonth={item.endMonth}
            endYear={item.endYear}
          />
        );
      })}
      {educationItems?.map((item, index) => {
        return (
          <LinkedInCard
            key={index}
            type="Education"
            image={item.image}
            coverImageUrl={item.coverImageUrl}
            company={item.degree} //The degree goes where the Company is
            title={item.school} //The school name goes where the Title is
            location={item.location}
            description={item.jobDescription}
            link={item.link}
            url={item.url}
            startMonth={item.startMonth}
            startYear={item.startYear}
            endMonth={item.endMonth}
            endYear={item.endYear}
          />
        );
      })}
    </StripDouble>
  );
}
