import { Stack, Text, Title } from "@mantine/core";
import { ComputerScreen } from "./ComputerScreen";

export function SectionAnalytics() {
  return (
    <Stack
      p="sm"
      sx={{
        backgroundColor: "#EFF6FE",
        // backgroundColor: "#F6F9FC",
      }}
      id="analytics"
      spacing="xl"
      py={70}
    >
      <Stack spacing="xs">
        <Title order={1} color="#444" ta="center">
          <span className="gradient-text">Analytics</span> dashboard
        </Title>
        <Text size="lg" color="#444" ta="center">
          Learn more about your audience and stalk your stalkers :)
        </Text>
      </Stack>
      <ComputerScreen url="https://tapistr.ee/i/analytics">
        <iframe
          title="Plausible Analytics"
          src="https://plausible.io/share/tapistr.ee%2Faubrey?auth=g4LCtiEwI3c8qGgpkehdW&embed=true&theme=light"
          scrolling="yes"
          frameBorder="0"
          loading="lazy"
          style={{ width: "1px", minWidth: "100%", height: "68vh" }}
        ></iframe>
      </ComputerScreen>
    </Stack>
  );
}
