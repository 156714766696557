export function SlidesCard({ url, size }) {
  const width = size === "sm" ? 480 : size === "md" ? 720 : 960;
  const height = size === "sm" ? 299 : size === "md" ? 434 : 569;

  return (
    <iframe
      src={url}
      frameBorder="0"
      width={width}
      height={height}
      allowFullScreen={true}
      mozallowfullscreen="true"
      webkitallowfullscreen="true"
      title="Google Slides"
    ></iframe>
  );
}
