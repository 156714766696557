import {
  Button,
  Container,
  Group,
  Flex,
  Text,
  Title,
  Paper,
  ThemeIcon,
  Modal,
  Switch,
  Stack,
  Center,
} from "@mantine/core";
import { baseUrl } from "../api/links";
import { useLoggedInQuery } from "../queries/queries";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { priceMap } from "../utils/productCatalog";
import { useUser } from "@clerk/clerk-react";
import { useUserContext } from "../contexts/UserContext";

const ANNUAL_DISCOUNT = 0.25;

export function CheckoutPricing({ explainer, buttonText }) {
  const [priceCardModalOpened, setPriceCardModalOpened] = useState(false);
  const [isAnnual, setIsAnnual] = useState(false);
  const starterPrice = isAnnual
    ? priceMap.Starter.annualValue / 100
    : priceMap.Starter.monthlyValue / 100;
  const premiumPrice = isAnnual
    ? priceMap.Premium.annualValue / 100
    : priceMap.Premium.monthlyValue / 100;

  const isMobile = useMediaQuery("(max-width: 50em)");

  function PricingSheet({ productName, feature1, feature2, feature3, price }) {
    // const { clerkUserId } = useUser();

    const { loggedInUserId } = useUserContext();

    const priceId = isAnnual
      ? priceMap[productName].annual
      : priceMap[productName].monthly;

    return (
      <Paper withBorder shadow="sm" p="xs" miw={280}>
        <Title order={2} color="blue">
          <span className={productName === "Premium" ? "gradient-text" : ""}>
            {productName}
          </span>
        </Title>
        <Container p="xs">
          <Group mb={4}>
            <ThemeIcon
              color={feature1 ? "green" : "gray"}
              size="xs"
              radius="xl"
            >
              <IconCheck />
            </ThemeIcon>
            <Text color="#444">Unlimited strips</Text>
          </Group>
          <Group mb={4}>
            <ThemeIcon
              color={feature2 ? "green" : "gray"}
              size="xs"
              radius="xl"
            >
              <IconCheck />
            </ThemeIcon>
            <Text color="#444">Unlimited links</Text>
          </Group>
          <Group mb={4} noWrap>
            <ThemeIcon
              color={feature3 ? "green" : "gray"}
              size="xs"
              radius="xl"
            >
              {feature3 ? <IconCheck /> : <IconX />}
            </ThemeIcon>
            <Text color={feature3 ? "#444" : "dimmed"}>
              Analytics dashboard
            </Text>
          </Group>
        </Container>

        {isAnnual ? (
          <>
            <Flex align="center" wrap="nowrap">
              <Text fw={500} size="xs" color="#444444">
                $
              </Text>
              <Text fw={500} mr={4} color="#444444">
                {price / 12}
              </Text>
              <Text color="dimmed" size="sm">
                per month
              </Text>
            </Flex>
            <Flex align="center" wrap="nowrap">
              <Text fw={500} size={10} color="dimmed" fs="italic">
                $
              </Text>
              <Text color="dimmed" size="xs" fs="italic">
                {price} billed annually
              </Text>
            </Flex>
          </>
        ) : (
          <>
            <Flex align="center" wrap="nowrap">
              <Text fw={500} size="xs" color="#444444">
                $
              </Text>
              <Text fw={500} mr={4} color="#444444">
                {price}
              </Text>
              <Text color="dimmed" size="sm">
                per month
              </Text>
            </Flex>
            <Text size="xs">&nbsp;</Text>
          </>
        )}

        <form action={`${baseUrl}/create-checkout-session`} method="POST">
          <input type="hidden" name="userId" value={loggedInUserId}></input>
          <input type="hidden" name="priceId" value={priceId}></input>
          <Button
            type="submit"
            variant={productName === "Premium" ? "gradient" : "light"}
            fullWidth
            mt="sm"
          >
            Proceed to checkout
          </Button>
        </form>
      </Paper>
    );
  }

  return (
    <>
      <Paper
        p="xs"
        mb={2}
        withBorder
        mr="sm"
        radius="md"
        sx={{ backgroundColor: "#DBE4FF" }}
      >
        <Group position="apart" noWrap>
          <Text size="sm" lineClamp={2}>
            {explainer}
          </Text>
          <Button
            size="sm"
            compact
            variant="gradient"
            onClick={() => setPriceCardModalOpened(true)}
          >
            {buttonText}
          </Button>
        </Group>
      </Paper>
      <Modal
        opened={priceCardModalOpened}
        onClose={() => setPriceCardModalOpened(false)}
        // fullScreen={isMobile}
        size={!isMobile && "auto"}
        closeOnClickOutside
        title="Activate your profile"
      >
        <Center>
          <Group mb="sm">
            <Text size="sm">Pay monthly</Text>
            <Switch
              checked={isAnnual}
              onChange={(event) => {
                setIsAnnual(event.currentTarget.checked);
              }}
              color="green"
            />
            <Stack spacing={2}>
              <Text size="sm">Pay yearly&nbsp;&nbsp;&nbsp;</Text>
              <Text size="sm" fs="italic">
                (Save {ANNUAL_DISCOUNT * 100}%)
              </Text>
            </Stack>
          </Group>
        </Center>
        <Flex justify="center" gap="lg" wrap="wrap" p="md">
          <PricingSheet
            productName="Starter"
            feature1
            feature2
            price={starterPrice}
          />
          <PricingSheet
            productName="Premium"
            feature1
            feature2
            feature3
            price={premiumPrice}
          />
        </Flex>
      </Modal>
    </>
  );
}
