import React from "react";
import { Image, Card, Center, Space } from "@mantine/core";

export function Polaroid({ image, description, emoji }) {
  return (
    <div className="card-polaroid">
      <Card shadow="sm" p="xs" radius={0}>
        <Center mt={8}>
          <Image
            src={image}
            alt={description}
            withPlaceholder
            height={300}
            imageProps={{
              loading: "lazy",
            }}
            style={{
              maxWidth: "300px",
            }}
          />
        </Center>
        <Card.Section>
          <Center m="sm">
            <Space h={32} />
          </Center>
        </Card.Section>
      </Card>
    </div>
  );
}
