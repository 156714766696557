import { Strip } from "../Strip";
import { WebsiteCard } from "../WebsiteCard";

export function DummyFavoritesStrip() {
  const itemArray = [
    {
      id: "d9d080eb-89a9-45e9-99d4-b95b1d68b39b",
      createdAt: "2023-11-17T19:10:04.042Z",
      updatedAt: "2023-11-17T19:10:04.042Z",
      url: "https://jarsofdust.com/shop",
      urlPosition: 12,
      isProfileUrl: null,
      strip: "Favorites",
      linkType: null,
      userId: "20d7b7ae-e5b9-4ac3-b577-c072223e9e71",
      artistName: "Jars of Dust ",
      albumName: null,
      coverImageUrl:
        "http://static1.squarespace.com/static/557cf191e4b0d40fa1a59d08/t/650b0b17f7550b348ab563ef/1695222551476/aurorashowroomlaunch-2.jpg?format=1500w",
      imageFit: null,
      favicon:
        "https://images.squarespace-cdn.com/content/v1/557cf191e4b0d40fa1a59d08/b0a7b739-463e-431f-93da-7e5a445d0228/favicon.ico",
      description:
        "Jars of Dust offers small batch, handmade pottery from our retail shop and studio in Virginia Beach, VA. Our minimalist ceramics are perfect for tableware, kitchenware, and daily use. Shop online or in store.",
      title: "Shop Handmade Pottery — Jars of Dust ",
      ISBN: null,
      episodeTitle: null,
      authorName: null,
      ratingsAverage: null,
      ratingsCount: null,
      userRating: null,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: null,
      jobDescription: null,
    },
    {
      id: "29583560-3a5a-4a35-bb81-c60c963fd16d",
      createdAt: "2023-11-17T19:05:37.386Z",
      updatedAt: "2023-11-17T19:05:37.386Z",
      url: "tim.blog",
      urlPosition: 8,
      isProfileUrl: null,
      strip: "Favorites",
      linkType: null,
      userId: "20d7b7ae-e5b9-4ac3-b577-c072223e9e71",
      artistName: "The Blog of Author Tim Ferriss",
      albumName: null,
      coverImageUrl:
        "https://149346886.v2.pressablecdn.com/wp-content/uploads/2023/07/timmolly_2560.jpg",
      imageFit: null,
      favicon:
        "https://i0.wp.com/tim.blog/wp-content/uploads/2019/12/cropped-site-icon-tim-ferriss-2.png?fit=32%2C32&ssl=1",
      description:
        "Tim Ferriss's 4-Hour Workweek and Lifestyle Design Blog. Tim is an author of 5 #1 NYT/WSJ bestsellers, investor (FB, Uber, Twitter, 50+ more), and host of The Tim Ferriss Show podcast (400M+ downloads)",
      title: "The Blog of Author Tim Ferriss",
      ISBN: null,
      episodeTitle: null,
      authorName: null,
      ratingsAverage: null,
      ratingsCount: null,
      userRating: null,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: null,
      jobDescription: null,
    },
    {
      id: "9e75229d-a4eb-4de3-9030-054654e8a0fd",
      createdAt: "2023-11-17T19:13:04.003Z",
      updatedAt: "2023-11-17T19:13:16.927Z",
      url: "https://andrewpearcebowls.com/collections/wooden-bowls",
      urlPosition: 18,
      isProfileUrl: null,
      strip: "Favorites",
      linkType: null,
      userId: "20d7b7ae-e5b9-4ac3-b577-c072223e9e71",
      artistName: "Andrew Pearce Bowls",
      albumName: null,
      coverImageUrl:
        "https://andrewpearcebowls.com/cdn/shop/collections/image_e25d4a4f-a75e-430f-b6a6-522fb2af8b25-351938_1200x1200.jpg?v=1697657632",
      imageFit: "cover",
      favicon:
        "https://andrewpearcebowls.com//andrewpearcebowls.com/cdn/shop/files/andrew-Pearce-bowls-made-in-vermont_32x32.png?v=1651887461",
      description:
        "You can shop by wooden bowl size or shop by style. All Andrew Pearce wood bowls are hand turned, expertly crafted and built to last.",
      title: "Wooden Bowls Handmade in Vermont | Andrew Pearce Bowls",
      ISBN: null,
      episodeTitle: null,
      authorName: null,
      ratingsAverage: null,
      ratingsCount: null,
      userRating: null,
      experienceType: null,
      school: null,
      degree: null,
      fieldOfStudy: null,
      gradePointAverage: null,
      company: null,
      jobTitle: null,
      location: null,
      startMonth: null,
      startYear: null,
      endMonth: null,
      endYear: null,
      currentPosition: null,
      jobDescription: null,
    },
  ];

  return (
    <Strip
      id="favorites"
      nav="scroll-tall"
      gradientValues={["#516BFA", "#009BFA"]}
      title="Favorites"
      icon={{ type: "emoji", icon: "⭐" }}
      iconColor="white"
    >
      {itemArray.map((item, index) => {
        return (
          <WebsiteCard
            key={index}
            url={item.url}
            coverImageUrl={item.coverImageUrl}
            imageFit={item.imageFit}
            favicon={item.favicon}
            title={item.title}
            description={item.description}
            artistName={item.artistName}
          />
        );
      })}
    </Strip>
  );
}
