import { useState, useEffect } from "react";
import { useDebouncedValue } from "@mantine/hooks";
import { TextInput, Flex, Button, Loader, ThemeIcon } from "@mantine/core";
import { linksApi } from "../api/links";
import { IconCheck, IconX } from "@tabler/icons-react";
import { useProfileMutation } from "../queries/queries";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "@mantine/form";

export function UsernameInput() {
  const form = useForm({
    validateInputOnChange: true,
    initialValues: { username: "" },
    validate: {
      username: (value) => {
        const usernameRegex = /^[a-zA-Z0-9_]+$/;
        const isValid = usernameRegex.test(value);
        return isValid ? null : "Letters, numbers, and underscores only";
      },
    },
  });

  const [isAvailable, setIsAvailable] = useState(null);
  const [isSearching, setIsSearching] = useState(true);
  const [debouncedSearchTerm] = useDebouncedValue(form.values.username, 200, {
    leading: true,
  });
  const navigate = useNavigate();
  const { username } = useParams();

  useEffect(() => {
    const checkUsernameAvailability = async () => {
      setIsSearching(true);
      const normalizedDebouncedSearchTerm = debouncedSearchTerm.toLowerCase();
      try {
        const response = await linksApi.get(
          `check-username?username=${normalizedDebouncedSearchTerm}`
        );
        setIsAvailable(response.data.available); // Assuming the response will have an 'available' boolean field
        setIsSearching(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (debouncedSearchTerm) {
      checkUsernameAvailability();
    } else {
      setIsAvailable(null);
    }
  }, [debouncedSearchTerm]);

  const profileMutation = useProfileMutation();

  function handleSave(inputValue) {
    const normalizedUsername = inputValue.toLowerCase();

    if (isAvailable) {
      profileMutation.mutate(
        {
          username: username,
          updates: {
            username: normalizedUsername,
          },
        }
        //THIS WILL HANG.
        // {
        //   // On successful mutation, navigate to the new URL
        //   onSuccess: () => {
        //     navigate(`/${inputValue}`); // Change the URL to the new username
        //     console.log(`Navigated to ${inputValue}`);
        //   },
        // }
      );
      navigate(`/${inputValue}`); // Change the URL to the new username
    } else {
      alert("Username is already taken");
    }
  }

  return (
    <Flex align="end" py={5}>
      <form
        onSubmit={form.onSubmit(() => handleSave(form.values.username))}
        style={{ width: "100%" }}
      >
        <TextInput
          label="Username"
          required
          placeholder="roaringkitty"
          mb="xs"
          rightSectionWidth={30}
          rightSection={
            form.values.username === "" ? null : isSearching ? (
              <Loader size="xs" />
            ) : isAvailable ? (
              <ThemeIcon color="green" size="xs" radius="xl">
                <IconCheck />
              </ThemeIcon>
            ) : (
              <ThemeIcon color="red" size="xs" radius="xl">
                <IconX />
              </ThemeIcon>
            )
          }
          styles={{
            root: {
              fontSize: "16px",
              flexGrow: "1 !important",
            },
            input: {
              fontSize: "16px",
              height: "20px",
              lineHeight: "20px",
            },
          }}
          {...form.getInputProps("username")}
        />
        <Button
          type="submit"
          color="blue"
          fullWidth
          disabled={
            !isAvailable ||
            profileMutation.isLoading ||
            form.values.username === ""
          }
        >
          Save
        </Button>
      </form>
    </Flex>
  );
}
