import "../App.css";
import { Text } from "@mantine/core";
import { Strip } from "../components/Strip";
import { useLinksQuery } from "../queries/queries";
import { StripSkeleton } from "./StripSkeleton";
import { MediumCard } from "./MediumCard";

export function WritingStrip() {
  const STRIP = "Writing";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="writing"
        nav="scroll-tall"
        gradientValues={["#FAF3F0"]}
        title="Writing"
        titleColor={"#000000"}
        icon={{ type: "emoji", icon: "📝" }}
        iconColor="white"
      >
        {linksDataByStrip?.map((item, index) => {
          return (
            <MediumCard
              key={index}
              url={item.url}
              coverImageUrl={item.coverImageUrl}
              favicon={item.favicon}
              title={item.title}
              description={item.description}
              artistName={item.artistName}
            />
          );
        })}
      </Strip>
    );
  }
}
