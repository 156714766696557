import { BeehiivEmbed } from "../BeehiivEmbed";
import { ErrorBoundary } from "@sentry/react";

export function DummyBeehiivStrip() {
  return (
    <ErrorBoundary>
      <BeehiivEmbed url="https://embeds.beehiiv.com/1c6ba19c-d987-41cf-8097-bb3367863042" />
    </ErrorBoundary>
  );
}
