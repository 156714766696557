import { useEffect } from "react";
import {
  Modal,
  Group,
  Select,
  TextInput,
  Text,
  Textarea,
  Button,
  Checkbox,
  LoadingOverlay,
  Image,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import {
  useCreateLinkMutation,
  useLinksQuery,
  useProfileQuery,
} from "../queries/queries";
import { CompaniesSearchBar } from "./CompaniesSearchBar";
import { yearsArray } from "../utils/yearsArray";

export function AddEducation({
  editing,
  setEditing,
  opened,
  setOpened,
  experienceId,
}) {
  const STRIP = "LinkedIn";

  const createLinkMutation = useCreateLinkMutation(successSubmit); //TBU for error handling
  const { data: linksData } = useLinksQuery();
  const experienceData = linksData?.find((item) => item.id === experienceId);

  const { data: profileData } = useProfileQuery();
  const destinationUserId = profileData?.id;

  const educationForm = useForm({
    initialValues: {
      degree: editing ? experienceData?.degree : "",
      company: editing ? experienceData?.school : "",
      coverImageUrl: editing ? experienceData?.coverImageUrl : "",
      url: editing ? experienceData?.url : "",
      location: editing ? experienceData?.location : "",
      startMonth: editing ? experienceData?.startMonth : "",
      startYear: editing ? experienceData?.startYear : "",
      endMonth: editing ? experienceData?.endMonth : "",
      endYear: editing ? experienceData?.endYear : "",
      description: editing ? experienceData?.description : "",
      currentPosition: editing ? experienceData?.currentPosition : false,
    },
  });

  const editFormValues = {
    degree: experienceData?.degree,
    company: experienceData?.school,
    coverImageUrl:
      experienceData?.coverImageUrl ||
      `https://logo.clearbit.com/${experienceData?.url}`,
    url: experienceData?.url,
    location: experienceData?.location,
    startMonth: experienceData?.startMonth,
    startYear: experienceData?.startYear,
    endMonth: experienceData?.endMonth,
    endYear: experienceData?.endYear,
    description: experienceData?.description,
    currentPosition: experienceData?.currentPosition,
  };

  useEffect(() => {
    if (editing) {
      educationForm.setValues(editFormValues);
    }
    // eslint-disable-next-line
  }, [experienceId]);

  const linksDataFilteredByStripAndExperience = linksData
    ?.filter((item) => item.strip === STRIP)
    ?.filter((item) => item.experienceType === "Education")
    .sort((a, b) => a.urlPosition - b.urlPosition);

  const highestUrlPosition = linksDataFilteredByStripAndExperience?.reduce(
    (max, linkData) => {
      return Math.max(max, linkData.urlPosition);
    },
    0
  );

  const isFirstEntry = linksDataFilteredByStripAndExperience?.length === 0;
  const nextUrlPosition = isFirstEntry ? 0 : highestUrlPosition + 1;

  const handleSubmit = async (e) => {
    e.preventDefault();
    createLinkMutation.mutate({
      userId: destinationUserId,
      id: editing ? experienceId : null,
      strip: "LinkedIn",
      experienceType: "Education",
      school: educationForm.values.company,
      degree: educationForm.values.degree,
      fieldOfStudy: educationForm.values.fieldOfStudy,
      gradePointAverage: educationForm.values.gradePointAverage,
      coverImageUrl: educationForm.values.coverImageUrl,
      url: educationForm.values.url,
      urlPosition: editing ? experienceData?.urlPosition : nextUrlPosition, //If it's an edit, urlPosition should not change.
      location: educationForm.values.location,
      jobDescription: educationForm.values.description,
      startMonth: educationForm.values.startMonth,
      startYear: educationForm.values.startYear,
      endMonth: educationForm.values.endMonth,
      endYear: educationForm.values.endYear,
      currentPosition: educationForm.values.currentPosition,
    });
  };

  function successSubmit() {
    setOpened(false);
    !editing && educationForm.reset(); //Now, any NEW adds will always start blank.
  }

  if (!linksData) {
    return <LoadingOverlay />;
  } else {
    return (
      <>
        <Button
          variant="light"
          fullWidth
          onClick={() => {
            educationForm.reset();
            setOpened((o) => !o);
          }}
        >
          Add Education
        </Button>
        <Modal
          onClose={() => {
            setOpened(false);
            setEditing(false);
            educationForm.setValues(editFormValues);
          }}
          title={<Text>Add education</Text>}
          opened={opened}
        >
          <LoadingOverlay visible={createLinkMutation.isLoading} />
          <form onSubmit={handleSubmit}>
            <TextInput
              data-autofocus
              label="Degree"
              placeholder="E.g. B.A. Economics"
              mb="sm"
              {...educationForm.getInputProps("degree")}
            />
            <CompaniesSearchBar
              form={educationForm}
              experienceType="Education"
            />
            <div
              style={{
                display: "flex",
                alignItems: "flex-end",
                flexGrow: 1,
                marginBottom: "12px",
                gap: "12px",
              }}
            >
              <Image
                width={55}
                height={55}
                radius="sm"
                withPlaceholder
                placeholder={<Image src="/linkedin-not-found.svg" />}
                src={educationForm.values.coverImageUrl}
              />
              <TextInput
                label="Image"
                placeholder="https://vanderbilt.edu/logo.png"
                form={educationForm}
                styles={{
                  root: {
                    fontSize: "16px",
                    flexGrow: "1 !important",
                  },
                }}
                {...educationForm.getInputProps("coverImageUrl")}
              />
            </div>
            <TextInput
              label="URL"
              placeholder="https://vanderbilt.edu"
              mb="sm"
              {...educationForm.getInputProps("url")}
            />
            <TextInput
              label="Location"
              placeholder="Nashville, TN"
              mb="sm"
              {...educationForm.getInputProps("location")}
            />
            <Checkbox
              label="I am currently working in this role"
              mb="sm"
              {...educationForm.getInputProps("currentPosition", {
                type: "checkbox",
              })}
            />
            <Group noWrap>
              <Select
                label="Start date"
                placeholder="Month"
                data={[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ]}
                mb="sm"
                {...educationForm.getInputProps("startMonth")}
              />
              <Select
                label=" "
                placeholder="Year"
                data={yearsArray}
                mb="sm"
                {...educationForm.getInputProps("startYear")}
              />
            </Group>
            {!educationForm.values.currentPosition && (
              <Group noWrap>
                <Select
                  label="End date"
                  placeholder="Month"
                  data={[
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December",
                  ]}
                  mb="sm"
                  {...educationForm.getInputProps("endMonth")}
                />
                <Select
                  label=" "
                  placeholder="Year"
                  data={yearsArray}
                  mb="sm"
                  {...educationForm.getInputProps("endYear")}
                />
              </Group>
            )}
            <Textarea
              autosize
              minRows={2}
              label="Description"
              // placeholder="AI Generated description based on Title & Company"
              mb="sm"
              {...educationForm.getInputProps("description")}
            />
            <Button
              type="submit"
              fullWidth
              disabled={createLinkMutation.isLoading}
            >
              Save
            </Button>
          </form>
        </Modal>
      </>
    );
  }
}
