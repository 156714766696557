import { Divider, Group, Stack, Text, Title } from "@mantine/core";

export function FooterHome() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <Divider size="xs" />
      <Group
        position="apart"
        mih="100px"
        p="sm"
        align="top"
        sx={{
          backgroundColor: "#CBE0F9",
        }}
      >
        <Stack spacing={2}>
          <Title order={3} color="#444">
            <span className="gradient-text">Tapistree&nbsp;🎨</span>
          </Title>
          <Text size="xs" color="#777777">
            Tapistree, LLC © {currentYear}
          </Text>
          <Text size="xs" color="#777777">
            🇺🇸 Newport Beach, California
          </Text>
        </Stack>
        <Group spacing="xl" align="top">
          <Stack spacing={0}>
            <Text weight={600} size="sm">
              Contact
            </Text>
            <Text
              component="a"
              href="mailto:aubrey.threadgill@gmail.com"
              size="sm"
            >
              Email
            </Text>
          </Stack>
          <Stack spacing={0}>
            <Text weight={600} size="sm">
              Legal
            </Text>
            <Text
              component="a"
              href="https://tapistr.ee/privacy-policy"
              size="sm"
            >
              Privacy policy
            </Text>
          </Stack>
        </Group>
      </Group>
    </>
  );
}
