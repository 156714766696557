import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Image,
  TextInput,
  Loader,
  Text,
  Anchor,
  ScrollArea,
  UnstyledButton,
} from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useDebouncedValue } from "@mantine/hooks";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { baseUrl } from "../api/links";

export function UnsplashSearchBar({ handleSave }) {
  const searchTerms = [
    "beach",
    "mountains",
    "los angeles",
    "new york",
    "golden retrievers",
    "golden",
  ];
  const randomSearchTerm =
    searchTerms[Math.floor(Math.random() * searchTerms.length)];
  const [searchTerm, setSearchTerm] = useState(randomSearchTerm);
  const [debouncedSearchTerm] = useDebouncedValue(searchTerm, 200, {
    leading: true,
  });
  const [searchResults, setSearchResults] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const fetchResults = async () => {
      setIsSearching(true);
      try {
        const response = await axios.get(
          `${baseUrl}/unsplash-search?query=${debouncedSearchTerm}`
        );
        setSearchResults(response.data);
        setIsSearching(false);
      } catch (error) {
        console.log(error);
      }
    };

    if (debouncedSearchTerm) {
      fetchResults();
    } else {
      setSearchResults([]);
    }
  }, [debouncedSearchTerm]);

  const handleInputChange = (value) => {
    setSearchTerm(value);
  };

  return (
    <div>
      <TextInput
        label={`Search Unsplash Photos`}
        placeholder="Type to search"
        value={searchTerm}
        onChange={(event) => handleInputChange(event.currentTarget.value)}
        rightSection={isSearching && <Loader size="xs" />}
        my={10}
        styles={{
          input: {
            fontSize: "16px",
            height: "20px",
            lineHeight: "20px",
          },
        }}
      />
      <ScrollArea h={400} type="auto" offsetScrollbars>
        <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
          <Masonry gutter="0.7rem">
            {searchResults?.map((photo, index) => (
              <div key={index} style={{ position: "relative" }}>
                <UnstyledButton
                  onClick={() => {
                    handleSave(photo.urls.regular);
                    showNotification({
                      title: "Success!",
                      message: "Your photo has been updated!",
                      autoClose: 3000,
                    });
                  }}
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    zIndex: 1,
                  }}
                ></UnstyledButton>
                <Image
                  key={photo.id}
                  radius="md"
                  src={photo.urls.regular}
                  alt={
                    photo.description ||
                    photo.alt_description ||
                    "Unsplash Image"
                  }
                />
                <Text
                  size="xs"
                  weight={500}
                  color="white"
                  sx={{
                    opacity: 0.8,
                    position: "absolute",
                    bottom: 5,
                    left: 5,
                    zIndex: 2,
                  }}
                >
                  by{" "}
                  <Anchor href={photo.links.html} target="_blank" color="white">
                    {photo.user.name}
                  </Anchor>
                </Text>
              </div>
            ))}
          </Masonry>
        </ResponsiveMasonry>
      </ScrollArea>
    </div>
  );
}
