import {
  Group,
  ActionIcon,
  Avatar,
  createStyles,
  Flex,
  Image,
  Menu,
  Text,
  Paper,
} from "@mantine/core";
import { useDeleteLinkMutation } from "../queries/queries";
import {
  IconTrash,
  IconGripVertical,
  IconDots,
  IconPencil,
} from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  item: {},

  itemDragging: {
    boxShadow: theme.shadows.sm,
  },

  dragHandle: {
    display: "flex",
    alignItems: "center",
    // paddingRight: theme.spacing.xs,
  },
  hidden: {
    visibility: "hidden",
  },
}));

export const LinkedInSelectionCard = ({
  id,
  url,
  coverImageUrl,
  title,
  company,
  school,
  degree,
  setOpened,
  setEditing,
  setExperienceId,
  provided,
  snapshot,
}) => {
  const deleteLinkMutation = useDeleteLinkMutation();

  async function handleDelete(id) {
    deleteLinkMutation.mutate({
      id: id,
    });
  }

  const { classes } = useStyles();

  return (
    <div
      className={snapshot?.isDragging ? classes.itemDragging : ""}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      <Paper
        radius="md"
        pl={6}
        pr="xs"
        py="xs"
        mb={8}
        withBorder
        style={{
          backgroundColor: "#F7F8F9",
        }}
      >
        <Group position="apart" spacing="xs">
          <Flex align="center">
            <div {...provided?.dragHandleProps} className={classes.dragHandle}>
              <IconGripVertical
                size="1.05rem"
                stroke={1.5}
                color="gray"
                style={{
                  cursor: "grab",
                }}
              />
            </div>
            <Group spacing="sm">
              <Image
                src={
                  coverImageUrl
                    ? coverImageUrl
                    : `https://logo.clearbit.com/${url}`
                }
                ml="xs"
                radius="sm"
                withPlaceholder
                height={38}
                width={38}
                placeholder={<Image src="/linkedin-not-found.svg" />}
              />
              <div>
                <Text
                  size="sm"
                  weight={500}
                  maw={220}
                  lineClamp={1}
                  style={{ lineHeight: "1.2em" }}
                >
                  {title || school}
                </Text>
                <Text size="sm" color="dimmed" maw={220} lineClamp={1}>
                  {company || degree}
                </Text>
              </div>
            </Group>
          </Flex>
          <Group spacing={0} position="right">
            <ActionIcon
              onClick={() => {
                setOpened(true);
                setEditing(true);
                setExperienceId(id);
              }}
            >
              <IconPencil size="1rem" stroke={1.5} />
            </ActionIcon>
            <Menu
              transitionProps={{ transition: "pop" }}
              withArrow
              position="bottom-end"
              withinPortal
            >
              <Menu.Target>
                <ActionIcon>
                  <IconDots size="1rem" stroke={1.5} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconTrash size="1rem" stroke={1.5} />}
                  color="red"
                  onClick={() => handleDelete(id)}
                  disabled={deleteLinkMutation.isLoading}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        </Group>
      </Paper>
    </div>
  );
};
