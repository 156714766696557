import React, { useState } from "react";
import { Card, Skeleton } from "@mantine/core";

export function YouTubeCard({ youTubeUrl, youTubeChannelId }) {
  let url = new URL(youTubeUrl);
  let embedUrl;
  if (url.pathname.includes("/@")) {
    let channelId = youTubeChannelId;
    channelId = "UULF" + channelId?.substring(2);
    embedUrl = `https://www.youtube.com/embed?listType=playlist&list=${channelId}`;
  } else if (url.pathname.includes("/channel/")) {
    // It's a channel URL
    let channelId = url.pathname.split("/").pop();
    // Replace the first two characters 'UC' with 'UU'
    channelId = "UULF" + channelId.substring(2);
    embedUrl = `https://www.youtube.com/embed?listType=playlist&list=${channelId}`;
  } else {
    // It's a standard video URL
    const videoId = url.searchParams.get("v") || url.pathname.split("/").pop();
    const startTime = url.searchParams.get("t");
    const startSlug = startTime ? `?start=${startTime}` : "";
    embedUrl = `https://www.youtube.com/embed/${videoId}${startSlug}`;
  }

  const [loading, setLoading] = useState(true);

  return (
    <div className="card-yt">
      <Card shadow="sm" p={4} radius="md" withBorder>
        <Skeleton visible={loading}>
          <div className="video-responsive">
            <iframe
              onLoad={() => setLoading(false)}
              // width="427"
              // height="240"
              src={embedUrl}
              allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video"
              loading="lazy"
              frameBorder="0"
              style={{ border: "none", borderRadius: "4px" }}
            />
          </div>
        </Skeleton>
      </Card>
    </div>
  );
}
