//Accepts kilometers, returns miles

export function convertDistance(totalDistance, targetDistanceUnit) {
  const MILES_CONVERSION_FACTOR = 1.609344;
  let convertedDistance;

  if (targetDistanceUnit === "miles") {
    convertedDistance = totalDistance / MILES_CONVERSION_FACTOR;
  } else {
    convertedDistance = totalDistance; // No conversion needed
  }

  return convertedDistance;
}
