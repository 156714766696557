import { Card, Image, Text, Badge, Group, Button, Center } from "@mantine/core";
import { Carousel } from "@mantine/carousel";

export function ProjectCard({
  images,
  backgroundColor,
  title,
  link,
  description,
  status,
  badgeText,
  callToAction,
}) {
  function getBadgeColor() {
    switch (status) {
      case "live":
        return "green";
      case "inactive":
        return "red";
      case "pending":
        return "yellow";
      default:
        return "gray";
    }
  }

  // const isLargerThanSm = useMediaQuery("(min-width: 48em)");
  // const TRANSITION_DURATION = 200;

  // const [embla, setEmbla] = useState(null);
  // useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  return (
    <div className="card-lg">
      <Card
        shadow="sm"
        p="xs"
        radius="md"
        // withBorder
        style={
          {
            // maxWidth: isLargerThanSm ? "450px" : "300px",
          }
        }
      >
        <Card.Section
          style={{
            backgroundColor: backgroundColor,
            //   display: "flex",
            //   height: "300px",
            //   width: "100%",
            //   maxWidth: "450px",
          }}
        >
          <Carousel
            mx="auto"
            maw={300}
            slideSize="100%"
            breakpoints={[{ maxWidth: "sm", slideSize: "100%", slideGap: 2 }]}
            slideGap="md"
            align="start"
            controlsOffset={0}
            styles={{
              control: {
                "&[data-inactive]": {
                  opacity: 0,
                  cursor: "default",
                },
              },
            }}
            // height={270}
            //  width="100%"
            //  sx={{ flex: 1 }}
            // breakpoints={[
            //   { maxWidth: "md", slideSize: "50%" },
            //   { maxWidth: "sm", slideSize: "100%", slideGap: 0 },
            // ]}
          >
            {images.map((item) => {
              return (
                <Carousel.Slide>
                  <Center>
                    <Image
                      src={item}
                      fit="contain"
                      width={200}
                      height={300}
                      withPlaceholder
                      alt={title}
                      imageProps={{
                        loading: "lazy",
                      }}
                      style={
                        {
                          // maxWidth: "300px",
                          // maxHeight: "300px",
                        }
                      }
                    />
                  </Center>
                </Carousel.Slide>
              );
            })}
          </Carousel>
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
          <Text weight={500}>{title}</Text>
          <Badge color={getBadgeColor()} size="sm">
            {status}
          </Badge>
        </Group>

        <Text size="sm" color="dimmed" style={{ whiteSpace: "pre-line" }}>
          {description}
        </Text>

        <Button
          variant="light"
          color="blue"
          fullWidth
          mt="md"
          radius="md"
          component="a"
          href={link}
          target="_blank"
        >
          {callToAction}
        </Button>
      </Card>
    </div>
  );
}
