import { useState } from "react";
import {
  ActionIcon,
  Box,
  Button,
  Collapse,
  Flex,
  Group,
  Menu,
  Modal,
  Paper,
  Text,
  ThemeIcon,
  createStyles,
} from "@mantine/core";
import {
  IconGripVertical,
  IconChevronDown,
  IconDots,
  IconTrash,
  IconChevronUp,
} from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { SwitchWithTooltip } from "./SwitchWithTooltip";
import { useDeleteStripMutation } from "../queries/queries";
import { dashesToSpaces } from "../utils/dahesToSpaces";

export function StripInput({
  id,
  icon,
  strip,
  alternateStripName,
  stripColor,
  children,
  provided,
  snapshot,
}) {
  const [
    opened,
    {
      toggle,
      // open
    },
  ] = useDisclosure(false);

  const [deleteModalOpened, setDeleteModalOpened] = useState(false);

  function handleToggle(e) {
    if (e.target.closest(".no-toggle")) {
      e.stopPropagation();
    } else {
      toggle();
    }
  }

  const useStyles = createStyles((theme) => ({
    item: {
      marginBottom: theme.spacing.xs,
    },

    itemDragging: {
      boxShadow: theme.shadows.sm,
    },

    dragHandle: {
      display: "flex",
      alignItems: "center",
      // paddingRight: theme.spacing.xs,
    },
    hidden: {
      visibility: "hidden",
    },
  }));

  const { classes, cx } = useStyles();

  const deleteStripMutation = useDeleteStripMutation();

  async function handleDelete(id) {
    deleteStripMutation.mutate({
      id: id,
    });
  }

  //This will need a database update function that stores the value of the switch checked state.

  return (
    <div
      className={cx(classes.item, {
        [classes.itemDragging]: snapshot?.isDragging,
      })}
      {...provided?.draggableProps}
      ref={provided?.innerRef}
    >
      <Paper
        shadow="xs"
        radius="md"
        pl={6}
        pr="xs"
        withBorder
        sx={{
          "&:hover": {
            backgroundColor: "#eee",
          },
        }}
      >
        <Modal
          title={<b>Delete permanently?</b>}
          opened={deleteModalOpened}
          onClose={() => setDeleteModalOpened(false)}
        >
          <Text size="sm">
            This action will permanently delete{" "}
            <b>all links & info for this strip</b>.
          </Text>
          <Text size="sm">
            You can go back and delete individual links or hide this strip
            instead.
          </Text>
          <Group grow spacing="xs">
            <Button
              variant="light"
              size="sm"
              onClick={() => setDeleteModalOpened(false)}
            >
              Back to safety
            </Button>
            <Button
              color="red"
              size="sm"
              p={0}
              onClick={() => handleDelete(id)}
              disabled={deleteStripMutation.isLoading}
            >
              Permanently delete
            </Button>
            {/* <Button color="blue" size="sm">
              Hide for now
            </Button> */}
          </Group>
        </Modal>
        <Flex align="center">
          <div {...provided?.dragHandleProps} className={classes.dragHandle}>
            <IconGripVertical
              size="1.05rem"
              stroke={1.5}
              color="gray"
              style={{
                cursor: "grab",
              }}
            />
          </div>
          <div style={{ display: "flex", flexGrow: 1 }}>
            <Box
              py="xs"
              onClick={handleToggle}
              style={{ flexGrow: 1, cursor: "pointer" }}
            >
              <Group ml="xs" spacing="sm" style={{ minWidth: "200px" }}>
                <ThemeIcon
                  color={stripColor ? stripColor : "#FFFFFF"}
                  size="xl"
                >
                  {icon}
                </ThemeIcon>
                <Group spacing="xs">
                  <Text size="xl" weight={500} maw={245} lineClamp={1}>
                    {alternateStripName
                      ? alternateStripName
                      : dashesToSpaces(strip)}
                  </Text>
                  <ActionIcon radius="xl" mt={3}>
                    {opened ? (
                      <IconChevronUp size="1.2rem" />
                    ) : (
                      <IconChevronDown size="1.2rem" />
                    )}
                  </ActionIcon>
                  <Menu
                    transitionProps={{ transition: "pop" }}
                    withArrow
                    position="bottom-end"
                    withinPortal
                  >
                    <Menu.Target>
                      <ActionIcon className="no-toggle">
                        <IconDots size="1rem" stroke={1.5} />
                      </ActionIcon>
                    </Menu.Target>
                    <Menu.Dropdown>
                      <Menu.Item
                        icon={<IconTrash size="1rem" stroke={1.5} />}
                        color="red"
                        // onClick={() => handleDelete(id)}
                        onClick={() => setDeleteModalOpened(true)}
                        disabled={deleteStripMutation.isLoading}
                        className="no-toggle"
                      >
                        Delete
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </Group>
              </Group>
            </Box>
          </div>
          <SwitchWithTooltip id={id} className="no-toggle" />
        </Flex>
        <Collapse in={opened} pl="sm" pb="xs" mt="xs">
          {children}
        </Collapse>
      </Paper>
    </div>
  );
}
