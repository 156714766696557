import "../App.css";
import React from "react";
import Plausible from "plausible-tracker";
import { Text } from "@mantine/core";
import { useLinksQuery } from "../queries/queries";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { IconBrandTwitter } from "@tabler/icons-react";
import { extractTweetId } from "../utils/extractTweetId";
import { TwitterFollowButton } from "./CTAButtons";
import { Strip } from "./Strip";
import { TwitterTweetEmbed } from "react-twitter-embed";
import { SkeletonCard } from "./SkeletonCard";
import { StripSkeleton } from "./StripSkeleton";
import { useMediaQuery } from "@mantine/hooks";

const { trackEvent } = Plausible();

export function TwitterStrip() {
  const STRIP = "Twitter";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter(
    (item) => item.strip === STRIP && !item.isProfileUrl
  );

  const isLargerThanSm = useMediaQuery("(min-width: 48em)");

  if (linksIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        id="twitter"
        nav="scroll"
        // gradientValues={["#000000"]}
        // title="Posts"
        // icon={{
        //   type: "image",
        //   icon: <Image src="/x-logo.png" height={28} width={28} mr={2} />,
        // }}
        gradientValues={["#1D9BF0"]}
        followButton={<TwitterFollowButton />}
        title="Tweets"
        icon={{ type: "fa", icon: faTwitter }}
        iconColor="white"
        // iconLink="YOUR TWITTER PROFILE...."TBU
      >
        {linksDataByStrip?.map((link) => {
          const tweetId = extractTweetId(link.url);
          return (
            <div
              key={link.id}
              style={{
                // width: isLargerThanSm ? "350px" : "260px",
                width: "260px",
                marginTop: "-10px",
                marginBottom: "-10px",
              }}
              onClick={() => trackEvent("TweetClick")}
            >
              <TwitterTweetEmbed
                key={link.id}
                placeholder={<SkeletonCard />}
                options={{
                  height: 400,
                }}
                tweetId={tweetId}
              />
            </div>
          );
        })}
      </Strip>
    );
  }
}
