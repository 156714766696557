import "../App.css";
import React, { Suspense } from "react";
import {
  Avatar,
  Card,
  Flex,
  // Group,
  // Image,
  List,
  Skeleton,
  // Stack,
  Text,
  // Title,
} from "@mantine/core";
import { useProfileQuery, useLinksQuery } from "../queries/queries";
import { useParams } from "react-router-dom";

export function AboutStrip() {
  const STRIP = "About";
  const { username } = useParams();

  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useProfileQuery(username);

  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);
  const aboutBullets = linksDataByStrip?.reverse();

  return (
    <div id="about">
      <Card
        p={0}
        radius="md"
        mx="xs"
        mt="sm"
        mb="lg"
        sx={(theme) => ({
          color: theme.white,
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        })}
      >
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            minHeight: "200px",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${profileData?.coverPhoto})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
          ></div>
          <div style={{ position: "relative" }}>
            {profileIsLoading || linksIsLoading ? (
              <Flex align="center">
                <Skeleton visible circle height={144} mx={20} />
                <div style={{ width: "40%" }}>
                  <Skeleton
                    visible
                    height={8}
                    mb={10}
                    width="100%"
                    radius="xl"
                  />
                  <Skeleton
                    visible
                    height={8}
                    mb={10}
                    width="100%"
                    radius="xl"
                  />
                  <Skeleton
                    visible
                    height={8}
                    mb={10}
                    width="100%"
                    radius="xl"
                  />
                  <Skeleton
                    visible
                    height={8}
                    mb={10}
                    width="100%"
                    radius="xl"
                  />
                </div>
              </Flex>
            ) : profileIsError || linksIsError ? (
              <Text>Error loading links</Text>
            ) : (
              <Suspense fallback={<div>Loading...</div>}>
                {/* <Flex align="center" my="sm"> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "10px 0px",
                  }}
                >
                  <div>
                    <Avatar
                      src={profileData?.profPic}
                      width="20%"
                      height="20%"
                      // size={114}
                      mx={20}
                      sx={{
                        border: "2px solid white",
                        width: "10vw", // Adjust the size as needed
                        height: "10vw", // Same as width to maintain aspect ratio
                        borderRadius: "50%",
                        maxWidth: "100px", // Optional: maximum size
                        maxHeight: "100px", // Optional: maximum size
                        minWidth: "70px", // Optional: maximum size
                        minHeight: "70px", // Optional: maximum size
                      }}
                    />
                  </div>
                  <List
                    spacing="xs"
                    pr="sm"
                    size="sm"
                    style={{ color: "#FFFFFF", fontWeight: 600 }}
                  >
                    {aboutBullets?.map((link, index) => {
                      return (
                        <List.Item pr="sm" key={index}>
                          {link.url}
                        </List.Item>
                      );
                    })}
                  </List>
                </div>
                {/* </Flex> */}
              </Suspense>
            )}
          </div>
        </div>
      </Card>
      {/* <Card
        p={0}
        radius="md"
        mx="xs"
        mt="sm"
        mb="md"
        sx={(theme) => ({
          color: theme.white,
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        })}
      >
        <Card.Section>
          <Image height={180} src={profileData?.coverPhoto} />
        </Card.Section>
        <Group align="top" spacing="xs" noWrap mx="xs">
          <Image
            src={profileData?.profPic}
            radius="md"
            withPlaceholder
            my="xs"
            height={80}
            width={80}
          />
          <Stack mt="xs" spacing={2} mb="xs" ml={2}>
            <Flex align="center">
              <Title order={4} color="black">
                {profileData?.displayName}
              </Title>
              <Image
                mt={-2}
                ml={2}
                height={19}
                width={19}
                src="/verified.png"
              />
            </Flex>
            <Text mb={2} color="#444" size="sm">
              {aboutBullets.map((item) => item.url + ". ")}
            </Text>
          </Stack>
        </Group>
      </Card> */}
    </div>
  );
}
