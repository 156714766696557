import "../App.css";
import { Text } from "@mantine/core";
import { ErrorBoundary } from "@sentry/react";
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { GitHubLanguageIcons } from "../components/GitHubLanguageIcon";
import { CommitCalendar } from "../components/CommitCalendar";
import { useLinksQuery, useProfileQuery } from "../queries/queries";
import { cleanUsername } from "../utils/cleanUsername";
import { GitHubFollowButton } from "./CTAButtons";

import { Strip } from "./Strip";
import { StripSkeleton } from "./StripSkeleton";
import { useParams } from "react-router-dom";

export function GitHubStrip() {
  const STRIP = "GitHub";
  const {
    data: linksData,
    isLoading: linksIsLoading,
    isError: linksIsError,
  } = useLinksQuery();

  const { username } = useParams();
  const {
    data: profileData,
    isLoading: profileIsLoading,
    isError: profileIsError,
  } = useProfileQuery(username);
  const linksDataByStrip = linksData?.filter((item) => item.strip === STRIP);
  const profileUrl =
    linksDataByStrip?.find((link) => link.isProfileUrl)?.url ||
    linksDataByStrip?.[0]?.url ||
    "";

  const finalProfileUrl = `https://github.com/${cleanUsername(profileUrl)}`; //Ensures it's a URL whether the person provided url or username.

  const programmingLanguages = profileData?.programmingLanguages;

  if (linksIsLoading || profileIsLoading) {
    return <StripSkeleton />;
  } else if (linksIsError || profileIsError) {
    return <Text>Error loading links</Text>;
  } else {
    return (
      <Strip
        key="GitHub"
        id="github"
        nav="scroll"
        gradientValues={["#24292F"]}
        followButton={
          <GitHubFollowButton link={profileUrl && finalProfileUrl} />
        }
        title="GitHub"
        icon={{ type: "fa", icon: faGithub }}
        iconColor="white"
        iconLink={profileUrl && finalProfileUrl}
        explainer={
          <GitHubLanguageIcons
            languages={programmingLanguages} //Make dynamic!!!!!!!
          />
        }
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            color: "white",
          }}
        >
          <div style={{ marginLeft: "10px" }}>
            <div style={{ height: "20px" }} />
            <ErrorBoundary>
              <CommitCalendar username={cleanUsername(profileUrl)} />
            </ErrorBoundary>
          </div>
        </div>
      </Strip>
    );
  }
}
