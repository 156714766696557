import { Title, Text, Button, Stack } from "@mantine/core";

export function NotFoundPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Text color="blue" size={100} align="center">
        404
      </Text>
      <Title align="center">User not found.</Title>

      <div style={{ height: "180px" }}></div>
      <Stack miw={300}>
        <Button
          variant="outline"
          size="md"
          fullWidth
          component="a"
          href="https://tapistr.ee"
        >
          Go back to the home page
        </Button>
        <Button
          size="md"
          fullWidth
          component="a"
          href="https://tapistr.ee/sign-up"
        >
          Claim this username
        </Button>
      </Stack>
    </div>
  );
}
