export function AppleMusicEmbed(link) {
  const isPodcast = link?.url.includes("/podcast/");
  const getEmbedLink = (url) => {
    const parsedUrl = new URL(url);
    const pathParts = parsedUrl.pathname.split("/").filter(Boolean);

    if (pathParts.length < 4) {
      console.error("Invalid Apple Music URL");
      return null;
    }

    const locale = pathParts[1];
    const type = pathParts[2];
    const id = pathParts[3];

    let embedPath = `${type}/${id}`;

    if (parsedUrl.searchParams.has("i")) {
      // If it's a song, append the song ID as a query param
      embedPath += `?i=${parsedUrl.searchParams.get("i")}`;
    }

    if (isPodcast) {
      return `https://embed.podcasts.apple.com/${locale}/${embedPath}`;
    } else {
      return `https://embed.music.apple.com/${locale}/${embedPath}`;
    }
  };

  const embedLink = getEmbedLink(link.url);

  // Adjust height based on type
  let height = "180"; // default to song
  if (link?.url.includes("?i=")) {
    height = "180"; // song
  } else if (link?.url.includes("/album/")) {
    height = "420"; // medium height for album
  } else if (link?.url.includes("/podcast/")) {
    height = "420"; // tallest height for podcast
  }

  return (
    <iframe
      title="Apple Music Embedded Player"
      allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write"
      frameBorder="0"
      height={height}
      style={{
        width: "100%",
        maxWidth: "660px",
        minWidth: isPodcast ? "495px" : "350px",
        overflow: "hidden",
        borderRadius: "10px",
      }}
      sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
      src={embedLink}
    ></iframe>
  );
}
