import React, { useState, useMemo, Suspense } from "react";
import { Card, Group, ScrollArea, Text } from "@mantine/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Carousel, useAnimationOffsetEffect } from "@mantine/carousel";
import { ErrorBoundary } from "@sentry/react";
import { useStripsQuery } from "../queries/queries";

export function Strip({
  id,
  nav,
  gradientValues,
  backgroundImageUrl,
  title,
  titleColor,
  followButton,
  explainer,
  icon,
  iconColor,
  iconLink,
  children,
}) {
  const isTouch = useMemo(() => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }, []);

  const TRANSITION_DURATION = 200;

  const [embla, setEmbla] = useState(null);
  useAnimationOffsetEffect(embla, TRANSITION_DURATION);

  const {
    data: stripsData,
    // isLoading: stripsIsLoading,
    // isError: stripsIsError,
  } = useStripsQuery();

  const filteredStrip = stripsData?.find(
    (strip) => strip.strip.toLowerCase() === id
  );
  const showFollowButton = filteredStrip?.showFollowButton;

  return (
    <div id={id}>
      <Card
        // shadow="sm"
        p="xs"
        radius="md"
        // withBorder
        mx="xs"
        mt="xs"
        mb="lg"
        sx={(theme) => ({
          backgroundImage: backgroundImageUrl
            ? `url(${backgroundImageUrl})`
            : theme.fn.linearGradient(45, ...gradientValues), //Singular values will intentionally render solid
          backgroundRepeat: backgroundImageUrl ? "no-repeat" : undefined, // If backgroundImageUrl is present, no-repeat, else undefined
          backgroundSize: backgroundImageUrl ? "cover" : undefined, // If backgroundImageUrl is present, cover, else undefined
          color: theme.white,
          // padding: "15px",
          display: "flex",
          flexDirection: "column",
          minHeight: "200px",
          overflowX: "auto", // Making horizontal overflow scrollable
          boxShadow:
            "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 8px 10px -6px rgba(0, 0, 0, 0.2)",
        })}
      >
        <Group position="apart" noWrap>
          <Text
            size={28}
            // color={titleColor ? titleColor : "#191919"}
            color={titleColor ? titleColor : "white"}
            weight={500}
            p={0}
            // style={{ textShadow: "0em 0em 0.2em #FFFFFF" }}
          >
            {title}
          </Text>
          <ErrorBoundary>{showFollowButton && followButton}</ErrorBoundary>
          {/* <div className="icon-container"> */}
          {iconLink ? (
            <a href={iconLink} target="_blank" rel="noreferrer">
              {icon?.type === "fa" && (
                <FontAwesomeIcon icon={icon.icon} color={iconColor} size="2x" />
              )}
              {icon?.type === "emoji" && (
                <span className="emoji-icon">{icon.icon}</span>
              )}
            </a>
          ) : (
            <>
              {icon?.type === "fa" && (
                <FontAwesomeIcon icon={icon.icon} color={iconColor} size="2x" />
              )}
              {icon?.type === "emoji" && (
                <span className="emoji-icon">{icon.icon}</span>
              )}
              {icon?.type === "image" && icon.icon}
            </>
          )}
          {/* </div> */}
        </Group>
        {explainer && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              color: titleColor,
            }}
          >
            {explainer}
          </div>
        )}
        <Suspense fallback={<div>Loading...</div>}>
          {nav === "scroll" && (
            <ScrollArea type="auto" offsetScrollbars>
              <div className="card-container-scroll">{children}</div>
            </ScrollArea>
          )}
          {nav === "no-scroll" && (
            <div className="card-container-scroll-tall">{children}</div>
          )}
          {nav === "scroll-tall" && (
            <ScrollArea type="auto" offsetScrollbars>
              <div className="card-container-scroll-tall">{children}</div>
            </ScrollArea>
          )}
          {nav === "full" && (
            <div style={{ display: "flex", margin: "-12px 0px" }}>
              {children}
            </div>
          )}
          {nav === "carousel" && (
            <div
              className="card-container-carousel"
              transitionDuration={TRANSITION_DURATION}
            >
              <ErrorBoundary>
                <Carousel
                  getEmblaApi={setEmbla}
                  withControls={isTouch ? false : true}
                  slideSize="33.333333%"
                  breakpoints={[
                    { maxWidth: "md", slideSize: "50%", slideGap: 0 },
                    { maxWidth: "sm", slideSize: "97%", slideGap: 0 },
                  ]}
                  align="start"
                  styles={{
                    control: {
                      "&[data-inactive]": {
                        opacity: 0,
                        cursor: "default",
                      },
                    },
                  }}
                >
                  {children}
                </Carousel>
              </ErrorBoundary>
            </div>
          )}
        </Suspense>
        {/* </BackgroundImage> */}
      </Card>
    </div>
  );
}
