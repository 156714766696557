import { Card, Skeleton } from "@mantine/core";

export function LinkedInCardSkeleton() {
  return (
    <div className="card-md">
      <Card shadow="sm" p="xs" radius="md" withBorder>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Skeleton height={72} width={72} radius="md" mr="sm" />
          <div>
            <Skeleton height={9} width={120} radius="xl" />
            <Skeleton height={9} width={160} radius="xl" mt={6} />
            <Skeleton height={9} width={180} radius="xl" mt={6} />
            <Skeleton height={9} width={170} radius="xl" mt={6} />
          </div>
        </div>
      </Card>
    </div>
  );
}
