import { useEffect } from "react";

export function emojiToFavicon(emoji) {
  return `<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 100 100%22>
    <text y=%22.9em%22 font-size=%2290%22>
      ${emoji}
    </text>
  </svg>`;
}

export function useEmojiFavicon(emoji) {
  useEffect(() => {
    const faviconLink =
      document.querySelector('link[rel="icon"]') ||
      document.createElement("link");
    faviconLink.rel = "icon";
    faviconLink.type = "image/svg+xml";

    if (emoji) {
      const faviconSvg = emojiToFavicon(emoji);
      faviconLink.href = `data:image/svg+xml,${faviconSvg}`;
    } else {
      // If no emoji is provided, we won't change the favicon.
      // It will use whatever is set in index.html or previously.
      return;
    }

    document.head.appendChild(faviconLink);
  }, [emoji]);
}
