import React, { useState } from "react";
import { Skeleton } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

export function TikTokProfileEmbed({ url }) {
  const username = url.split("@").pop();
  const [loading, setLoading] = useState(true);

  const isLargerThanXs = useMediaQuery("(min-width: 26em)");

  const height = isLargerThanXs ? "500px" : "400px";

  return (
    <Skeleton visible={loading} width="100%" height={height} my="sm" mx={0}>
      <iframe
        src={`https://www.tiktok.com/embed/@${username}`}
        title={username}
        onLoad={() => setLoading(false)}
        frameBorder="0"
        scrolling="no"
        width="100%"
        height={height}
        style={{
          margin: "10px 0px 0px 0px",
        }}
      />
    </Skeleton>
  );
}
